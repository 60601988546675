import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';
import { getCurrentDateInSec } from '@Stores/DevPanel';

import type { InfoStripeData } from '@Types/Base';

export interface Response {
  ok: boolean;
  data: InfoStripeData;
}

async function getInfoStripe(pathname: string) {
  const currentDateInSec = getCurrentDateInSec();
  const search = `?currentDate=${currentDateInSec}`;
  const url = `/backend/get-info-stripe${search}`;
  const res = await Api.queryProxi<Response>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ url: pathname }),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getInfoStripe;
