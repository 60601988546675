export default (params = {}) => {
  return {
    description: 'Номинация',
    type: 'string',
    maxLength: 255,
    errorMessage: {
      required: 'Это обязательное поле',
      minLength: 'Укажите тип проекта',
    },
    ...params,
  };
};
