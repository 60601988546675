import * as Api from '@Api/index';
import { BackendUnavailableError, IncorrectBackendResponseError } from '@Common/errors';
import { isAbsoluteLink } from '@Utils/isAbsoluteLink';
import { isNode } from '@Utils/checkRuntime';

async function query<T>(path: string, opts?: RequestInit) {
  const request = Api.getRequest();
  const url = isAbsoluteLink(path) ? path : `${request.proxyOrigin}${path}`;
  const queryUrl = new URL(url);
  const options = opts || {};
  const headers = options.headers || {};

  if (isNode) {
    Object.entries(request.headers).forEach(([key, value]) => {
      headers[key] = value;
    });
  } else {
    headers['X-Requested-With'] = 'XMLHttpRequest';
  }

  headers['X-Country'] = request.country;

  const params: RequestInit = {
    ...options,
    credentials: options.credentials || 'include',
    headers,
  };

  const res = await fetch(queryUrl.toString(), params);

  // Отдельный тип ошибки для случая, когда бэкенд недоступен
  if (res.status >= 500) {
    throw new BackendUnavailableError();
  }

  // Пытаемся распарсить ответ от бэкенда как JSON
  let json: T = null;
  try {
    json = await res.json();
  } catch (err) {
    throw new IncorrectBackendResponseError({ url: queryUrl.toString(), status: res.status });
  }

  return json;
}

export default query;
