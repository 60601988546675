import { memo, useCallback, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Boldik, cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import useTranslation from '@Queries/useTranslation';
import useLanguages from '@Hooks/useLanguages';
import LangItem from '../Languages/elems/LangItem';
import LanguagesPopup from './elems/LanguagesPopup';
import styles from './LanguageSwitch.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface LanguageSwitchProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const LanguageSwitch: FC<LanguageSwitchProps> = (props) => {
  const { className, ...restProps } = props;
  const { language } = useLanguages();
  const { fixed } = HeaderStore.useMenu();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const handleClick = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  useEffect(() => {
    if (fixed) handleClose();
  }, [fixed, handleClose]);

  return (
    <div {...restProps} className={cn(styles.container, className)}>
      <div className={cn(styles.langSwitch, { [styles.opened]: opened })} onClick={handleClick}>
        <Boldik className={styles.titleText}>{t('ui.lang.label')}</Boldik>
        <LangItem
          className={styles.title}
          title={language.id.toUpperCase()}
          icon={language.icon}
          selected
        />
        <IconChevronRight className={styles.iconArrow} />
      </div>

      <CSSTransition
        unmountOnExit
        timeout={200}
        in={opened}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
      >
        <LanguagesPopup
          onClose={handleClose}
          className={cn(styles.popup, { [styles.fixed]: fixed })}
        />
      </CSSTransition>
    </div>
  );
};

export default memo(LanguageSwitch);
