import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';

import type { RelatedProductsData } from '@Types/Cart';

export interface Variables {
  productIds: number[];
}

function useLoadRelatedProducts() {
  return useMutation<RelatedProductsData, Error, Variables>({
    mutationFn: ({ productIds }) => {
      return ApiCart.loadRelatedProducts({ productIds });
    },
  });
}

export default useLoadRelatedProducts;
