import hash from 'object-hash';

/**
 * Функция формирования hash
 * @param data - любой тип данных, любой вложенности, на основании которого будет сформирован ключ
 * @param algorithm - вид алгоритма, который будет использоваться при формировании hash
 */

function generateHash(data: any, algorithm: 'md5' | 'sha1' = 'md5') {
  return hash(data, { algorithm, encoding: 'base64', unorderedArrays: true });
}

export default generateHash;
