import { Button, Link, cn } from '@divlab/divanui';

import image from './image.svg';
import useTranslation from '@Queries/useTranslation';
import formatPhone from '@Utils/formatPhone';
import styles from './Cancellation.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { OrderState } from '@Types/DeliveryTracking';

interface CancellationProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
}

const Cancellation: FC<CancellationProps> = (props) => {
  const { className, order, ...restProps } = props;
  const { t } = useTranslation();
  const phone = order?.supportPhone || '';
  const formattedPhone = formatPhone(phone);

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <img className={styles.image} src={image} />
      <div className={styles.title}>{t('cancellation.title')}</div>
      {formattedPhone && (
        <div className={styles.callCenter}>
          {t('cancellation.call-center')} {formattedPhone}
        </div>
      )}

      {phone && (
        <Link className={styles.button} to={`tel:${phone}`}>
          <Button wide>{t('call')}</Button>
        </Link>
      )}
    </div>
  );
};

export default Cancellation;
