import { useYMaps, Placemark } from '@pbe/react-yandex-maps';

import useTranslation from '@Queries/useTranslation';
import styles from './WarehousePlacemark.module.css';
import images from './images';

import type { FC } from 'react';
import type { Point } from '@Types/DeliveryTracking';

interface WarehousePlacemarkProps {
  point: Point;
}

const WarehousePlacemark: FC<WarehousePlacemarkProps> = (props) => {
  const { point } = props;
  const ymaps = useYMaps(['templateLayoutFactory']);
  const { t } = useTranslation();

  const Layout = ymaps?.templateLayoutFactory.createClass(
    `<div class=${styles.wrapper}>
      <div class='${styles.layout}'>$[[options.contentLayout]]</div>
      <img class='${styles.point}' src=${point.completed ? images.blackPoint : images.grayPoint} />
    </div>`,
  );

  const ContentLayout = ymaps?.templateLayoutFactory.createClass(
    `<div class='${styles.content}'>
      <img class='${styles.icon}' src=${images.warehouse} />
      <span>${t('map.warehouse')}</span>
    </div>`,
  );

  return (
    <Placemark
      defaultGeometry={point.coordinates}
      options={{
        iconLayout: Layout,
        iconContentLayout: ContentLayout,
        zIndex: 10000,
        zIndexHover: 10000,
        cursor: 'default',
      }}
    />
  );
};

export default WarehousePlacemark;
