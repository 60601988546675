import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

export interface UserPollData {
  cookie?: CookieData;
  form: FormData;
  name: string;
  endpoint: string;
}

export interface CookieData {
  name: string;
  bestBefore: string;
}

export interface FormData {
  title: string;
  smallRating: string;
  thankYou: string;
}

export interface Response {
  userPolls: UserPollData[];
}

export interface Params {
  category: string;
}

async function userPolls(params: Params) {
  const url = '/backend/data/user-polls';
  const { category } = params;

  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ category }),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default userPolls;
