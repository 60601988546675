const errorMessages = {
  ru: {
    requiredEmail: 'Необходимо указать E-mail',
    wrongEmail: 'E-mail указан неверно',
  },
  uz: {
    requiredEmail: "Siz elektron pochtani ko'rsatishingiz kerak",
    wrongEmail: "Elektron pochta noto'g'ri ko'rsatilgan",
  },
  kk: {
    requiredEmail: 'E-mail көрсету керек',
    wrongEmail: 'E-mail қате көрсетілген',
  },
};

export default (lang: string, params = {}) => {
  return {
    description: 'E-mail',
    type: 'string',
    format: 'email',
    errorMessage: {
      minLength: errorMessages[lang].requiredEmail,
      required: errorMessages[lang].requiredEmail,
      format: errorMessages[lang].wrongEmail,
    },
    ...params,
  };
};
