import { memo, useCallback } from 'react';

import * as ModalWindows from '@Stores/ModalWindows';
import UILink from '@UI/Link';

import type { FC } from 'react';
import type { OfferDocData } from '@Types/Layout';
import type { LinkProps } from '@UI/Link';

export interface FooterOfferDocProps extends LinkProps {
  className?: string;
  document: OfferDocData;
}

const FooterOfferDoc: FC<FooterOfferDocProps> = (props) => {
  const { document, ...restProps } = props;

  const handleClick = useCallback(() => {
    if (document.action !== 'StaticInfo') return;

    ModalWindows.open(document.action, {
      slug: document.slug,
      hash: `#${document.slug}`,
    });
  }, [document]);

  return (
    <UILink
      {...restProps}
      to={document.link}
      target='_blank'
      onClick={handleClick}
      view='secondary'
    >
      {document.text}
    </UILink>
  );
};

export default memo(FooterOfferDoc);
