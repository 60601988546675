import { useEffect, useState } from 'react';
import { Button, Checkbox, Rating, Textarea, cn } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import { images } from './images';
import formatDate from '@Utils/formatDate';
import Api from '@Api/DeliveryTracking';
import useTranslation from '@Queries/useTranslation';
import Form from '@UI/Form';
import Link from '@Navigation/Link';
import styles from './Completion.module.css';

import type { ChangeEvent, FC, FormEvent, HTMLAttributes } from 'react';
import type { OrderState } from '@Types/DeliveryTracking';
import type { SendFeedbackBody } from '@Api/DeliveryTracking/typings';

interface CompletionProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
}

type RatingType = 'unknown' | 'positive' | 'negative';

const Completion: FC<CompletionProps> = (props) => {
  const { className, order, ...restProps } = props;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [submitted, setSubmitted] = useState(null);
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();

  const deliveryDate = formatDate(new Date(order?.deliveryDate));

  const placeholders: Record<RatingType, string> = {
    unknown: t('completion.placeholders.unknown'),
    positive: t('completion.placeholders.positive'),
    negative: t('completion.placeholders.negative'),
  };

  let ratingType = 'unknown';
  let message = t('completion.messages.unknown');
  let image = images.completed;

  if (rating >= 4) ratingType = 'positive';

  if (rating > 0 && rating <= 3) ratingType = 'negative';

  if (submitted && ratingType === 'positive') {
    message = t('completion.messages.positive');
    image = images.positive;
  }

  if (submitted && ratingType === 'negative') {
    message =
      rating && rating < 4
        ? t('completion.messages.negative-with-call')
        : t('completion.messages.negative');
    image = images.negative;
  }

  const handleChangeRating = (_, value: number) => setRating(value);
  const handleChangeComment = (_, value: string) => setComment(value);
  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) =>
    setCheckbox(!!event.target.value);

  const handleError = () => ModalWindows.open('Info', { view: 'error', variant: 'unknown-error' });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!rating || pending) return;

    const body: SendFeedbackBody = {
      orderCode: order.orderCode,
      deliveryCode: order.deliveryCode,
      rating,
      comment,
      needToContact: checkbox,
    };

    try {
      setPending(true);
      const response = await Api.sendFeedback(body);

      if (!response.ok) return handleError();

      setSubmitted(true);
      localStorage.setItem(`delivery-rating-value[${order.orderCode}]`, rating.toString());
    } catch (err) {
      handleError();
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    const prevEstablishedRating = localStorage.getItem(`delivery-rating-value[${order.orderCode}]`);
    if (prevEstablishedRating) {
      setRating(+prevEstablishedRating);
      setSubmitted(true);
    } else {
      setSubmitted(false);
    }
  }, [order.orderCode]);

  if (typeof submitted !== 'boolean') return null;

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <img className={styles.image} src={image} />
      <div className={styles.date}>{deliveryDate}</div>
      <div className={styles.value} dangerouslySetInnerHTML={{ __html: message }} />

      {submitted ? (
        <Link className={styles.link} to='/'>
          <Button theme='transparent' wide>
            {t('to-main-page')}
          </Button>
        </Link>
      ) : (
        <Form
          className={styles.form}
          name='add-delivery-feedback'
          onSubmit={handleSubmit}
          onCriticalError={handleError}
        >
          <Rating
            className={styles.rating}
            size='m'
            editable
            value={rating}
            onChange={handleChangeRating}
          />

          <Textarea
            className={styles.textarea}
            onChange={handleChangeComment}
            value={comment}
            placeholder={placeholders[ratingType]}
          />

          <Checkbox
            className={cn(styles.checkbox, { [styles.hide]: !rating || rating >= 4 })}
            onChange={handleChangeCheckbox}
            text={t('to-contact')}
          />

          <Button className={styles.button} type='submit' wide size='l' waiting={pending}>
            {t('estimate')}
          </Button>
        </Form>
      )}
    </div>
  );
};

export default Completion;
