import { useCallback, useMemo } from 'react';
import { cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import * as Meta from '@Queries/Meta';
import * as Profile from '@Queries/Profile';
import useMedias from '@Hooks/useMedias';
import useRouter from '@Navigation/useRouter';
import useNavigation from '@Navigation/useNavigation';
import Timer from '../Timer';
import styles from './InfoStripe.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { InfoStripeData } from '@Types/Base';

export interface InfoStripeProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  infoStripe: InfoStripeData;
}

const InfoStripe: FC<InfoStripeProps> = (props) => {
  const { className, infoStripe, ...restProps } = props;
  const { isMobileM } = useMedias();
  const navigation = useNavigation();
  const profile = Profile.useProfile();
  const meta = Meta.useMeta();
  const router = useRouter();
  const { region } = meta.data;

  const handleClickTopline = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      switch (infoStripe.view) {
        case 'marketing':
          if (!profile.data) {
            return ModalWindows.open('Authorization');
          }
          navigation.openPage({ url: `${region.url}${router.club}` });
          break;

        case 'showroom':
          navigation.openPage({ url: `${region.url}/site/showroom` });
          break;

        default:
          navigation.openPage({ url: infoStripe.link });
          break;
      }
    },
    [infoStripe.link, infoStripe.view, navigation, profile.data, region.url, router],
  );

  const isValidLengthChar = useMemo((): boolean => {
    const maxCharCount = 94;
    const timerCount = 26;
    const textLength = infoStripe.text?.length;

    if (infoStripe.timer?.title) {
      return textLength + infoStripe.timer.title.length <= maxCharCount - timerCount;
    }

    return textLength <= maxCharCount;
  }, [infoStripe]);

  return (
    <div {...restProps} className={cn(styles.wrapper, className)} onClick={handleClickTopline}>
      <div className={cn(styles.wrapperText, { [styles.inline]: !infoStripe.timer })}>
        {infoStripe.text && (
          <span
            className={cn(styles.textOffset, styles.textStyle, {
              [styles.hideText]: !isMobileM && !isValidLengthChar,
            })}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: infoStripe.text }}
          />
        )}
        {infoStripe.link && !infoStripe.timer && (
          <>
            {' '}
            <span className={cn(styles.wrapperArrow, styles.inline)}>
              <IconChevronRight className={styles.rArrow} />
            </span>
          </>
        )}
      </div>
      {infoStripe.timer && (
        <div className={styles.timerWrapper}>
          <div
            className={cn(styles.timerText, styles.textStyle)}
          >{`${infoStripe.timer?.title}`}</div>
          {` `}
          <Timer className={styles.textStyle} timer={infoStripe.timer} />{' '}
          {infoStripe.link && (
            <span className={styles.wrapperArrow}>
              <IconChevronRight className={styles.rArrow} />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default InfoStripe;
