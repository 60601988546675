import { cn } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import * as Meta from '@Queries/Meta';
import Phone from '@Components/Phone';
import styles from './FeedbackPhone.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

type FeedbackPhoneProps = HTMLAttributes<HTMLSpanElement>;

const FeedbackPhone: FC<FeedbackPhoneProps> = (props) => {
  const { className, ...restProps } = props;
  const meta = Meta.useMeta();

  if (!meta.isSuccess) return null;

  const handleClickPhone = (event: MouseEvent) => {
    event.preventDefault();
    ModalWindows.open('Feedback');
  };

  return (
    <Phone
      className={cn(styles.phone, className)}
      onClick={handleClickPhone}
      phone={meta.data.phones[0]}
      {...restProps}
    />
  );
};

export default FeedbackPhone;
