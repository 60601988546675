import { memo, forwardRef, useRef, useState, useEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import styles from './SearchDropDownWrapper.module.css';

import type { HTMLAttributes, ReactElement } from 'react';

export interface SearchDropDownWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactElement;
  opened: boolean;
}

const SearchDropDownWrapper = forwardRef<HTMLDivElement, SearchDropDownWrapperProps>(
  (props, ref) => {
    const { children, opened, ...restProps } = props;
    const contentRef = useRef<HTMLDivElement>();
    const [state, setState] = useState(false);

    const handleClickOutside = useCallback(() => {
      HeaderStore.closeSearch();
    }, []);

    useEffect(() => {
      setState(opened);
    }, [opened]);

    return (
      <CSSTransition
        classNames={{
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
        in={state}
        timeout={500}
        unmountOnExit
      >
        <div className={cn(styles.dropdown, { [styles.opened]: state })}>
          <div className={styles.content} {...restProps} ref={contentRef}>
            <div className={styles.wrapper} ref={ref}>
              {children}
            </div>
          </div>
          <div className={styles.backdrop} onClick={handleClickOutside} />
        </div>
      </CSSTransition>
    );
  },
);

SearchDropDownWrapper.displayName = 'SearchDropDownWrapper';

export default memo(SearchDropDownWrapper);
