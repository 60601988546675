import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { BodyVOCData } from '@Types/VoiceOfCustomers';

export interface ResponseAddVoc {
  ok: boolean;
}

async function addVoc(body: BodyVOCData, endpoint: string) {
  const url = `/marketing/form/v1/${endpoint}`;
  const res = await Api.queryProxi<ResponseAddVoc>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res;
}

export default addVoc;
