import { cn } from '@divlab/divanui';

import Zoom from './elems/Zoom';
import Geolocation from './elems/Geolocation';
import styles from './Controls.module.css';

import type { HTMLAttributes, FC } from 'react';

interface ControlsProps extends HTMLAttributes<HTMLDivElement> {
  onIncrement?(): void;
  onDecrement?(): void;
  onGetGeolocation?(coords: [number, number]): void;
}

const Controls: FC<ControlsProps> = (props) => {
  const { className, onIncrement, onDecrement, onGetGeolocation, ...restProps } = props;

  return (
    <div className={cn(styles.controls, className)} {...restProps}>
      <Zoom className={styles.increment} type='increment' onClick={onIncrement} />
      <Zoom className={styles.decrement} type='decrement' onClick={onDecrement} />
      <Geolocation onGetGeolocation={onGetGeolocation} />
    </div>
  );
};

export default Controls;
