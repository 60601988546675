import * as Api from '@Api/index';
import {
  prefetchInfoStripe,
  prefetchLayoutData,
  prefetchMetaData,
  prefetchUITranslation,
  prefetchPage,
} from '@Navigation/prefetchers';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request } = args;

  if (typeof document === 'undefined' || !Api.getRequest()) {
    Api.setRequest(query);
  }

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchInfoStripe({ request, ...query }),
    prefetchLayoutData(queryClient),
    prefetchMetaData(queryClient),
    prefetchUITranslation(queryClient),
  ]);
};
