import { cn } from '@divlab/divanui';

import styles from './Zoom.module.css';

import type { ButtonHTMLAttributes, FC } from 'react';

interface ZoomProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type: 'increment' | 'decrement';
}

const Zoom: FC<ZoomProps> = (props) => {
  const { className, type, ...restProps } = props;

  return (
    <button
      className={cn(styles.button, className, {
        [styles.increment]: type === 'increment',
        [styles.decrement]: type === 'decrement',
      })}
      {...restProps}
    />
  );
};

export default Zoom;
