import { memo } from 'react';
import { cn } from '@divlab/divanui';

import FooterNav from '@Layouts/LayoutMain/elems/Footer/common/FooterNav';
import FooterInfoBlock from '@Layouts/LayoutMain/elems/Footer/common/FooterInfoBlock';
import Accordion from '@Layouts/LayoutMain/elems/Footer/common/Accordion';
import FooterRequisites from '../FooterRequisites';
import PaymentSystems from '../PaymentSystems';
import styles from './FooterMobileS.module.css';

import type { FooterData } from '@Types/Layout';
import type { FC } from 'react';

export interface FooterMobileSProps {
  footer: FooterData;
}

const FooterMobileS: FC<FooterMobileSProps> = (props) => {
  const { footer } = props;

  const hasCatalog = footer.catalog?.items.length > 0;

  return (
    <div className={styles.col}>
      {footer.contacts?.items.length > 0 && (
        <div className={styles.wrapperMedium}>
          <Accordion header={footer.contacts.title}>
            <FooterNav items={footer.contacts.items} />
          </Accordion>
        </div>
      )}
      <div className={styles.wrapperBig}>
        {footer.callSchedule?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <FooterInfoBlock title={footer.callSchedule.title}>
              <FooterNav items={footer.callSchedule.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.deliverySchedule?.items.length > 0 && (
          <FooterInfoBlock title={footer.deliverySchedule.title}>
            <FooterNav items={footer.deliverySchedule.items} />
          </FooterInfoBlock>
        )}
      </div>

      {footer.toCustomers?.items.length > 0 && (
        <div
          className={cn({
            [styles.wrapperRegular]: hasCatalog,
            [styles.wrapperBig]: !hasCatalog,
          })}
        >
          <Accordion header={footer.toCustomers.title} defaultCollapsed>
            <FooterNav items={footer.toCustomers.items} />
          </Accordion>
        </div>
      )}

      {hasCatalog && (
        <div className={styles.wrapperBig}>
          <Accordion defaultCollapsed header={footer.catalog.title}>
            <FooterNav items={footer.catalog.items} />
          </Accordion>
        </div>
      )}

      {footer.payments?.items.length > 0 && (
        <div className={styles.wrapperBig}>
          <FooterInfoBlock title={footer.payments.title}>
            <PaymentSystems items={footer.payments.items} />
          </FooterInfoBlock>
        </div>
      )}
      {footer.requisites?.length > 0 && <FooterRequisites requisites={footer.requisites} />}
    </div>
  );
};

export default memo(FooterMobileS);
