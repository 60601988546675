import {
  prefetchCategoryPromotion,
  prefetchPage,
  prefetchTranslation,
} from '@Navigation/prefetchers';
import { prefetchInfiniteCategory } from '@Queries/useInfiniteCategory';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { params, request } = args;
  const { search } = new URL(request.url);
  const { page = '' } = params;
  const pageNumber = parseInt(page.replace(/\D/g, ''), 10) || 1;
  const slug = 'promo-vmeste-vygodnee';

  return Promise.all([
    prefetchCategoryPromotion({ slug }, queryClient),
    prefetchPage(request.url, queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchInfiniteCategory({ slug, pageNumber, search }, queryClient),
  ]);
};
