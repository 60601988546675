import { memo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAppConfig } from '@Contexts/AppConfig';

import type { FC } from 'react';

const FontCeraPro: FC = () => {
  const { сdnUrl } = useAppConfig();

  return (
    <Helmet>
      <link rel='stylesheet' href={`${сdnUrl}/public/fonts/cera-pro/font.v1.css`} />
    </Helmet>
  );
};

export default memo(FontCeraPro);
