import { useStore } from '@kundinos/nanostores/react';

import { authSettingsStore } from './stores';

/**
 * Use this hook for getting the current auth settings
 */
export function useSettings() {
  return useStore(authSettingsStore);
}
