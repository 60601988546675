import { memo, useEffect, useCallback, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import * as SearchStore from '@Stores/Search';
import useLocalStorage from '@Hooks/useLocalStorage';
import useTranslation from '@Queries/useTranslation';
import Highlight from '../Highlight';
import styles from './SearchHistory.module.css';

import type { HTMLAttributes, FC, MouseEvent } from 'react';

export interface SearchHistoryPopupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onSearch?: (e: MouseEvent, item: string) => void;
}

const SearchHistoryPopup: FC<SearchHistoryPopupProps> = (props) => {
  const { className, onSearch, ...restProps } = props;
  const { result } = SearchStore.useSearch();
  const { request } = SearchStore.useSearchHistory();
  const setSearchHistory = SearchStore.setSearchHistory;
  const [valueStorage, setValueStorage] = useLocalStorage<Array<string>>('searchHistory', []);
  const { t } = useTranslation();

  const historyList = useMemo(() => {
    if (!result.request) return valueStorage.slice(0, 3);

    return valueStorage
      .filter((item: string) => item.toLowerCase().includes(result.request.toLowerCase().trim()))
      .slice(0, 3);
  }, [valueStorage, result.request]);

  const handleCleanStorage = useCallback(
    (e) => {
      e.stopPropagation();
      setValueStorage([]);
    },
    [setValueStorage],
  );

  const handleClick = useCallback(
    (e: MouseEvent, name: string) => {
      if (onSearch) onSearch(e, name);
    },
    [onSearch],
  );

  useEffect(() => {
    if (request && result.request && !valueStorage.includes(request.trim())) {
      setValueStorage([request.trim(), ...valueStorage.slice(0, 20)]);
      setSearchHistory('');
    }
  }, [request, setValueStorage, setSearchHistory, valueStorage, result.request]);

  if (historyList.length < 1) return null;

  return (
    <div {...restProps} className={cn(className, styles.main)}>
      <div className={styles.titleWraper}>
        <h2 className={styles.title}>{t('ui.history')}</h2>
        <div className={styles.clear} onClick={handleCleanStorage}>
          {t('ui.clear')}
        </div>
      </div>
      {historyList.map((value: string) => {
        return (
          <div key={value} className={styles.requestTitle} onClick={(e) => handleClick(e, value)}>
            <Highlight search={result.request} text={value} />
          </div>
        );
      })}
    </div>
  );
};

export default memo(SearchHistoryPopup);
