import { createStore, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

interface FormErrorValue {
  [key: string]: string;
}

export const formErrorsStore = createStore<FormErrorValue>(() => {
  formErrorsStore.set({});
});

export const setError = (errors: FormErrorValue) => {
  update(formErrorsStore, (formErrorItem) => ({ ...formErrorItem, ...errors }));
};

export const resetErrors = () => {
  formErrorsStore.set({});
};

export const useFormError = (id: string) => {
  const store = useStore(formErrorsStore);
  return store[id];
};
