import { loader as pageSearchLoader } from '@Pages/PageSearchCommon/PageSearch/loader';
import { loader as pageDigineticaSearchLoader } from '@Pages/PageSearchCommon/PageDigineticaSearch/loader';

import type { CountryCode } from '@Types/Base';

export interface LoaderSearch {
  searchCountries: CountryCode[];
  digineticaCountries: CountryCode[];
}
export const loader = (params, countries: LoaderSearch) => {
  if (countries.searchCountries.includes(params.country)) return pageSearchLoader(params);
  if (countries.digineticaCountries.includes(params.country))
    return pageDigineticaSearchLoader(params);
};

export default loader;
