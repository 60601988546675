import { update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

import { visibleModalsStore, currentModalStore, modalsStore } from './stores';

import type { Modal } from './typings';

export function useModals() {
  return useStore(modalsStore);
}

export function useVisibleModals() {
  return useStore(visibleModalsStore);
}

export function useCurrentModal() {
  return useStore(currentModalStore);
}

/**
 * Add modals also on server-side render. Modals will initialize with state hidden
 * @param ssrModals array with modals, that need render on the server
 * @param condition additional render condition
 */
export function useSSRModals(ssrModals: Modal[], condition = true) {
  if (condition) {
    update(modalsStore, (prevValue) => {
      const newStack = [...prevValue.stack];

      ssrModals.forEach((ssrModal) => {
        const hasInStack = newStack.some((stackModal) => stackModal.id === ssrModal.id);

        if (!hasInStack) {
          newStack.push({
            id: ssrModal.id,
            data: ssrModal.data,
            visible: false,
            hidden: true,
          });
        }
      });

      return { ...prevValue, stack: newStack };
    });
  }
}
