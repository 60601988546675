import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';

function usePriorityRegions() {
  const keys = ['regions'];
  const result = useQuery({
    queryKey: keys,
    queryFn: () => ApiMeta.getRegionPriority(),
    enabled: true,
  });

  return result;
}

export default usePriorityRegions;
