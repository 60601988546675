import { json } from 'react-router-dom';

import Api from '@Api/ClientForms';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = () => async (args) => {
  const { request } = args;

  const url = new URL(request.url);
  const pageData = await Api.getQuizzPage({ pathname: url.pathname });

  return json(pageData) as unknown as unknown[];
};
