import { memo, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import IconMastercard from '@Layouts/LayoutMain/elems/Footer/icons/IconMastercard';
import IconVisa from '@Layouts/LayoutMain/elems/Footer/icons/IconVisa';
import IconVerifiedByVisa from '@Layouts/LayoutMain/elems/Footer/icons/IconVerifiedByVisa';
import IconBelcart from '@Layouts/LayoutMain/elems/Footer/icons/IconBelcart';
import IconBePaid from '@Layouts/LayoutMain/elems/Footer/icons/IconBePaid';
import IconMastercardSecureCode from '@Layouts/LayoutMain/elems/Footer/icons/IconMastercardSecureCode';
import IconMir from '@Layouts/LayoutMain/elems/Footer/icons/IconMir';
import PaymentList from '@Layouts/LayoutMain/elems/Footer/common/PaymentList';
import styles from './PaymentSystems.module.css';

import type { FooterPaymentItemData } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface PaymentSystemsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: FooterPaymentItemData[];
}

const PaymentSystems: FC<PaymentSystemsProps> = ({ items, className }) => {
  const icons = useMemo(() => {
    return items.map((item) => {
      switch (item.code) {
        case 'mastercard':
          return { ...item, icon: <IconMastercard className={styles.icon} /> };

        case 'visa':
          return { ...item, icon: <IconVisa className={styles.icon} /> };

        case 'belcart':
          return { ...item, icon: <IconBelcart className={styles.icon} /> };

        case 'bePaid':
          return { ...item, icon: <IconBePaid className={styles.icon} /> };

        case 'verifiedByVisa':
          return { ...item, icon: <IconVerifiedByVisa className={styles.icon} /> };

        case 'mastercardSecureCode':
          return { ...item, icon: <IconMastercardSecureCode className={styles.icon} /> };

        case 'mir':
          return { ...item, icon: <IconMir className={styles.icon} /> };

        default:
          return {
            ...item,
          };
      }
    });
  }, [items]);

  return <PaymentList className={cn(styles.paymentList, className)} items={icons} />;
};

export default memo(PaymentSystems);
