import { update } from '@kundinos/nanostores';

import { store } from '@Stores/PageLock/stores';

import type { Id } from '@Stores/PageLock/typings';

/**
 * Use this method for removing the variant, that locked page scroll
 * When all variants are removed — page will unlock
 * @param id — identifier of variant
 */
function remove(id: Id) {
  update(store, (value) => {
    const newVariants = value.variants;

    if (!newVariants[id]) return value;

    newVariants[id] = { ...newVariants[id], opened: false };

    return { ...value, variants: newVariants };
  });
}

export default remove;
