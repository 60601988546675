import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './IconBelcart.module.css';
import icon from './belkart.svg';

import type { FC, HTMLAttributes } from 'react';

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const IconBelcart: FC<IconProps> = ({ className }) => {
  return (
    <Img
      src={icon}
      className={cn(styles.icon, className)}
      alt='Принимаем оплату банковскими картами Belcart'
    />
  );
};

export default memo(IconBelcart);
