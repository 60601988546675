import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

export interface ResponseData {
  text: string;
}

export interface Props {
  productId: number;
}

async function getPreorderDate({ productId }: Props): Promise<ResponseData> {
  const searchParams = new URLSearchParams({ id: productId.toString() });
  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : '';
  const url = `/backend/shop-product/preorder-info${qs}`;
  const res = await Api.queryProxi<ApiResponse<ResponseData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getPreorderDate;
