import type { Event } from './types';

export class EventListener {
  private events: Event[] = [];

  dispatch(name: string, data) {
    this.events.forEach((event) => {
      const condition = Array.isArray(event.names)
        ? event.names.includes(name)
        : event.names === name;
      if (condition || event.names === '*') {
        event.callback(data);
      }
    });
  }

  on(eventNames: string | string[] | '*', callback: (data) => void) {
    this.events.push({ names: eventNames, callback });
  }
}
