export interface ProductNameData {
  seoName?: string;
  name: string;
  type: string;
}

/**
 * Функция для получения корректного названия товара
 * @param product - информация о товаре. Обязательны поля name и type.
 * @returns отображаемое название товара.
 */
function getProductName(product: ProductNameData) {
  if (product.seoName) return product.seoName;

  if (product.type) return `${product.type} ${product.name}`;

  return product.name;
}
export default getProductName;
