import { forwardRef, memo, useEffect, useRef, useImperativeHandle } from 'react';
import { Input as UIInput } from '@divlab/divanui';

export type InputRef = HTMLInputElement;
import type { InputProps as UIInputProps } from '@divlab/divanui';
import type { MutableRefObject } from 'react';

export interface InputProps extends UIInputProps {
  mask?: string;
  onBeforePaste?: (pastedValue: string, opts: any) => string;
  onComplete?: () => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref: MutableRefObject<HTMLInputElement>) => {
    const { mask, onBeforePaste, onComplete, ...restProps } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    // Загружаем маску для поля ввода
    useEffect(() => {
      if (!mask) return;
      if (!inputRef.current) return;

      async function loadInputMask() {
        const InputMask = (await require('inputmask')).default;

        if (!mask) return;
        const im = new InputMask(mask, {
          showMaskOnHover: false,
          showMaskOnFocus: false,
          onBeforePaste,
          oncomplete: onComplete,
        });

        if (inputRef.current) {
          im.mask(inputRef.current);
        }
      }

      loadInputMask();
    }, [inputRef, mask, onBeforePaste, onComplete]);

    useImperativeHandle(ref, () => inputRef.current);

    return <UIInput {...restProps} ref={inputRef} />;
  },
);

Input.displayName = 'Input';

export default memo(Input);
