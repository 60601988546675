import { memo, useCallback, useState } from 'react';
import { cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import useTranslation from '@Queries/useTranslation';
import Recommendation from '../Recommendation';
import styles from './Copyright.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { RecommendationTechnologiesData } from '@Types/Layout';

export interface CopyrightProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  textes: string[];
  recommendationTechnologies?: RecommendationTechnologiesData;
}

const Copyright: FC<CopyrightProps> = (props) => {
  const { className, textes, recommendationTechnologies, ...restProps } = props;
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <div {...restProps} className={cn(styles.copyright, className)}>
      <div className={styles.text}>
        {recommendationTechnologies && <Recommendation data={recommendationTechnologies} />}
        {textes[0]}
        {!opened && (
          <>
            {` `}
            <span className={styles.toggle} onClick={handleClick}>
              {t('ui.read-more')} <IconChevronRight className={styles.iconChevron} />
            </span>
          </>
        )}
      </div>
      {opened && <div className={styles.text}>{textes[1]}</div>}
    </div>
  );
};

export default memo(Copyright);
