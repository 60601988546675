import { useMemo } from 'react';

import * as Cart from '@Queries/Cart';
import generateProductHash from '@Utils/generateProductHash';

import type { ProductHashParams } from '@Utils/generateProductHash';

/**
 * Товар уже есть в корзине?
 * @param product — продукт с информацией о товаре
 */

function useHasInCart(product: ProductHashParams) {
  const shortCard = Cart.useCartShortInfo();

  const hash = generateProductHash(product);

  const hasInCart = useMemo<boolean>(() => {
    if (!shortCard.data?.positions) return false;

    return shortCard.data.positions.includes(hash);
  }, [shortCard.data?.positions, hash]);

  return hasInCart;
}

export default useHasInCart;
