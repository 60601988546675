import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { RelatedProductsData } from '@Types/Cart';

export interface Params {
  productIds: number[];
}

async function loadRelatedProducts({ productIds }: Params) {
  const searchParams = new URLSearchParams();
  searchParams.set('productIDs[]', productIds.join());

  const url = `/backend/product/related-products?${searchParams.toString()}`;
  const res = await Api.queryProxi<RelatedProductsData>(url);

  if (res.code) throw new BackendError(url, res);

  return res;
}

export default loadRelatedProducts;
