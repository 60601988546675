import { memo, useCallback, useState } from 'react';
import { Boldik, cn, Rating } from '@divlab/divanui';

import Phone from '@Components/Phone';
import * as ModalWindows from '@Stores/ModalWindows';
import Link from '@Navigation/Link';
import useMedias from '@Hooks/useMedias';
import Dropdown from '../Dropdown';
import styles from './FooterNavItem.module.css';

import type { FooterMenuItemData } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface FooterNavItemProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  item: FooterMenuItemData;
}

const FooterNavItem: FC<FooterNavItemProps> = (props) => {
  const { item, className, ...restProps } = props;
  const [isShowDropdown, setShowDropdown] = useState(false);
  const { isMobileM, isMobile } = useMedias();
  const themes = ['comagic_phone', 'calltouch_phone'];
  const isCalltouch = themes.includes(item.theme);
  const interactive = isCalltouch ? isMobileM : item.link || item.action || item.links;
  const isRating = !isMobile && item.rating;

  const handleClickLink = useCallback(() => {
    if (item.links?.length) {
      return setShowDropdown(true);
    }

    if (!item.action) return;

    switch (item.action) {
      case 'Callback':
        ModalWindows.open('Callback');
        break;
      case 'Question':
        ModalWindows.open('Question');
        break;
      case 'VideoConsultation':
        ModalWindows.open('VideoConsultation');
        break;
      case 'WriteToManagement':
        ModalWindows.open('WriteToManagement');
        break;
    }
  }, [item.links?.length, item.action]);

  const handleMouseEnterItem = useCallback(() => {
    if (isMobileM || !item.links?.length) return;

    setShowDropdown(true);
  }, [isMobileM, item.links?.length]);

  const handleCloseDropdown = useCallback(() => {
    setShowDropdown(false);
  }, [setShowDropdown]);

  return (
    <div
      {...restProps}
      className={cn(
        styles.footerNavItem,
        {
          [styles.phone]: (item.link || '').startsWith('tel:'),
          [styles.hasIcon]: item.icon,
        },
        className,
      )}
      onMouseLeave={handleCloseDropdown}
    >
      {item.icon && <img className={styles.icon} src={item.icon} />}

      <div className={styles.footerNavItemTitle}>
        {isCalltouch && (
          <Phone
            className={cn(styles.footerNavLink, {
              [styles.interactive]: interactive,
            })}
            phone={item.text}
          />
        )}

        {item.link && !isCalltouch && (
          <Link
            className={cn(styles.footerNavLink, {
              [styles.interactive]: interactive,
            })}
            to={item.link}
          >
            <Boldik>{item.text}</Boldik>
          </Link>
        )}

        {!item.link && (
          <Boldik
            onMouseEnter={handleMouseEnterItem}
            className={cn(styles.footerNavLink, { [styles.interactive]: interactive })}
            onClick={handleClickLink}
          >
            {item.text}
          </Boldik>
        )}

        {isRating && <Rating className={styles.rating} value={item.rating} />}
      </div>

      {isShowDropdown && (
        <Dropdown
          items={item.links}
          className={styles.dropdownLinks}
          onCloseDropdown={handleCloseDropdown}
        />
      )}
    </div>
  );
};

export default memo(FooterNavItem);
