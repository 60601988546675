import { BaseCache } from './BaseCache';

import type { Cache } from '../../entities/cache';

/**
 * Заглушка для кэша, имеющая все необходимые методы, но без фактической работы с кэшированием
 * Удобна для использования в режиме разработки или во время написания тестов
 */
export class DummyCache extends BaseCache implements Cache {
  // eslint-disable-next-line autofix/no-unused-vars, @typescript-eslint/no-unused-vars
  async get<T = unknown>(key: string): Promise<T> {
    return null;
  }

  async set(): Promise<void> {
    // do nothing
  }

  async delete(): Promise<void> {
    // do nothing
  }

  async has(): Promise<boolean> {
    return false;
  }
}
