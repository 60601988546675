const errorMessages = {
  ru: {
    requiredType: 'Необходимо указать способ оплаты',
  },
  uz: {
    requiredType: "To'lov usulini ko'rsatish kerak",
  },
  kk: {
    requiredType: 'Төлем әдісін көрсету қажет',
  },
};

export default (lang: string) => {
  return {
    description: 'Способ оплаты',
    type: 'integer',
    errorMessage: { required: errorMessages[lang].requiredType },
  };
};
