import { memo } from 'react';
import { cn, Link as UILink } from '@divlab/divanui';

import Img from '@UI/Img';
import CardInView from '@Components/CardInView';
import Link from '@Navigation/Link';
import styles from './SingleBanner.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { ProductsBannerData, Promotion } from '@Promo/typings';

export interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  promotion: Promotion<ProductsBannerData>;
  onClickLink?: (e: MouseEvent) => void;
}

const SingleBanner: FC<BannerProps> = (props) => {
  const { className, promotion, onClickLink } = props;
  const banner = promotion.materials[0];
  const { title, subtitle, link } = banner.text || {};
  const image = banner.resources.default.image;

  return (
    <CardInView
      className={cn(styles.wrapper, className)}
      card={banner}
      listTitle='menu'
      cardType='promo'
      isSingleCard
      id={banner.id}
      targetPathname={banner.link}
    >
      <Link className={styles.banner} to={banner.link} onClick={onClickLink}>
        {image && <Img className={styles.image} src={image} />}

        <div className={cn(styles.text, { [styles.inversed]: banner.inversed })}>
          <div>
            {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
            {subtitle && (
              <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
            )}
          </div>
          {link && (
            <UILink className={styles.link} animated view={banner.inversed ? 'white' : 'primary'}>
              {link}
            </UILink>
          )}
        </div>
      </Link>
    </CardInView>
  );
};

export default memo(SingleBanner);
