import { ValidationSchemeError } from '../errors/validation-scheme-error';

import type { CountryCode } from '@Types/Base';

interface Params {
  country: CountryCode;
}

export default ({ country }: Params) => {
  switch (country) {
    case 'RUS':
      return '^\\+7 \\([0-9]{3}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$';

    case 'BLR':
      return '^\\+375 \\([0-9]{2}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$';

    case 'KAZ':
      return '^\\+7 \\([0-9]{3}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$';

    case 'UZB':
      return '^\\+998 \\([0-9]{2}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$';

    default:
      throw new ValidationSchemeError('phone', country);
  }
};
