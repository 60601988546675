import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { RouteStateDTO, RouteStateParams, RouteStateResponse } from './typings';

async function getRouteState(params: RouteStateParams): Promise<RouteStateDTO> {
  const searchParams = new URLSearchParams({ id: params.id });
  const searchString = searchParams.toString() ? `?${searchParams.toString()}` : '';

  const url = `/delivery/delivery_tracking/get_route_state${searchString}`;
  const result = await Api.queryProxi<RouteStateResponse>(url);

  if (!result.result) throw new BackendError(url, result);

  return result.data;
}

export default getRouteState;
