import { cn } from '@divlab/divanui';

import PromotionTemplate from './templates/PromotionTemplate';
import NoveltyTemplate from './templates/NoveltyTemplate';
import styles from './PromoGrid.module.css';

import type { FC } from 'react';
import type { PromoGridProps, TemplateSize } from './typings';

const PromoGrid: FC<PromoGridProps> = (props) => {
  const { className, promotions, type, onClickLink, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.wrapper, className)}>
      {promotions.map((promotion, index, array) => {
        let size: TemplateSize = index % 3 === 0 ? 'full' : 'half';

        /* Если в последнем ряду сетки не заполнена вторая колонка
        — растянуть последний элемент на две колонки  */
        if (index % 3 === 1 && index === array.length - 1) {
          size = 'full';
        }

        switch (type) {
          case 'promotions':
            return (
              <PromotionTemplate
                className={styles.itemWrapper}
                index={index}
                cnTemplate={styles.item}
                promotion={promotion}
                size={size}
                key={promotion.id}
                onClickLink={onClickLink}
              />
            );
          case 'novelty':
            return (
              <NoveltyTemplate
                className={styles.itemWrapper}
                index={index}
                cnTemplate={styles.item}
                promotion={promotion}
                size={size}
                key={promotion.id}
                onClickLink={onClickLink}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default PromoGrid;
