import { useEffect, useState } from 'react';
import { cn, useMedias } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import formatDate from './utils/formatDate';
import styles from './Delivery.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { DeliveryStatus, OrderState, Points } from '@Types/DeliveryTracking';

interface DeliveryProps extends HTMLAttributes<HTMLDivElement> {
  status: DeliveryStatus;
  order: OrderState;
  points: Points;
}

const Delivery: FC<DeliveryProps> = (props) => {
  const { className, status, order, points, ...restProps } = props;
  const { t } = useTranslation();
  const [title, setTitle] = useState(t('info.delivery.title.default'));
  const { isMobile } = useMedias();

  const formattedDate = formatDate(
    order?.deliveryDate,
    order?.deliveryTimeFrom,
    order?.deliveryTimeTo,
  );

  const mobileFormattedDate = formattedDate.replace(')', ') <br />');

  useEffect(() => {
    if (status !== 'delivering' || !points.currentRoute?.length) return;

    const courier = points.currentRoute.findIndex((point) => point.type === 'courier');
    const currentClient = points.currentRoute.findIndex((point) => point.type === 'current_client');

    if (courier >= 0 && currentClient >= 0) {
      const newDeliveriesBefore = currentClient - courier - 1;

      if (newDeliveriesBefore < 1) {
        setTitle(t('info.delivery.title.final'));
      } else {
        setTitle(
          `${t('info.delivery.title.process')} ${newDeliveriesBefore} ${t(
            'delivery-declension',
            newDeliveriesBefore,
          )}`,
        );
      }
    }
  }, [status, t, points]);

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <div className={styles.image} />
      <div className={styles.title}>{title}</div>
      {formattedDate && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: isMobile ? mobileFormattedDate : formattedDate }}
        />
      )}
      <div className={styles.value}>{t('info.delivery.additional-info')}</div>
    </div>
  );
};

export default Delivery;
