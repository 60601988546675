const errorMessages = {
  ru: {
    requiredType: 'Необходимо указать способ доставки',
  },
  uz: {
    requiredType: "Yetkazib berish usulini ko'rsatish kerak",
  },
  kk: {
    requiredType: 'Жеткізу әдісін көрсету керек',
  },
};

export default (lang: string) => {
  return {
    description: 'Способ доставки',
    type: 'integer',
    errorMessage: { required: errorMessages[lang].requiredType },
  };
};
