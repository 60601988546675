import { memo, useCallback, useEffect, useState } from 'react';
import { cn } from '@divlab/divanui';
import { CSSTransition } from 'react-transition-group';

import * as HeaderStore from '@Stores/Header';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import Link from '@Navigation/Link';
import Img from '@UI/Img';
import Item from '../Item/Item';
import styles from './ItemWithPopup.module.css';
import { useDeps } from '@Contexts/DI';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface ItemWithPopupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  item: MainMenuItem;
  positionPopup?: 'right' | 'left';
}

const ItemWithPopup: FC<ItemWithPopupProps> = (props) => {
  const { item, positionPopup = 'left', ...restProps } = props;
  const [openedItem, setOpenedItem] = useState<string>(null);
  const { fixed } = HeaderStore.useMenu();
  const { analytics } = useDeps();

  const handlClick = useCallback(
    (menuItem: MainMenuItem) => {
      if (openedItem === menuItem.name) {
        setOpenedItem(null);
      } else {
        setOpenedItem(menuItem.name);
      }
    },
    [openedItem],
  );

  const handleClickLink = useCallback(
    (e: MouseEvent, url: string) => {
      HeaderStore.closeMenu();

      if (url.includes('/site/fabrics')) {
        analytics.dispatchEvent('topline.clickOrderFabrics');
      }
    },
    [analytics],
  );

  const handleClose = useCallback(() => {
    setOpenedItem(null);
  }, []);

  const refPopup = useOnClickOutside<HTMLDivElement>(handleClose, !openedItem);

  useEffect(() => {
    if (fixed) handleClose();
  }, [fixed, handleClose]);

  return (
    <div ref={refPopup} onClick={() => handlClick(item)} {...restProps}>
      <Item item={item} />
      <CSSTransition
        unmountOnExit
        timeout={200}
        in={openedItem === item.name}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
      >
        <div
          className={cn(
            styles.popup,
            { [styles.fixed]: fixed },
            { [styles.positionRight]: positionPopup === 'right' },
            { [styles.positionLeft]: positionPopup === 'left' },
          )}
        >
          {item.children.map((subitem) => {
            return (
              <Link
                className={cn(styles.link, subitem.url && 'MenuLink')}
                to={subitem.url}
                ssr={subitem.render}
                key={subitem.id}
                onClick={(e) => handleClickLink(e, subitem.url)}
              >
                <span className={styles.linkText}>{subitem.name}</span>
                {subitem.icon && <Img className={styles.linkIcon} src={subitem.icon.url} />}
              </Link>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
};

export default memo(ItemWithPopup);
