import { useQuery } from '@tanstack/react-query';

import useSearchParams from '@Hooks/useSearchParams';
import { useDeps } from '@Contexts/DI';
import Api from '@Api/DeliveryTracking';

import type { UseQueryResult } from '@tanstack/react-query';
import type { RouteState } from '@Types/DeliveryTracking';
import type { RouteStateDTO } from '@Api/DeliveryTracking/typings';

const refetchInterval = 60000;

const useRouteState = (): UseQueryResult<RouteState> => {
  const { logger } = useDeps();
  const id = useSearchParams().get('id');

  const keys = ['delivery-route-state', id];

  const getRouteState = async () => {
    try {
      const routeState = await Api.getRouteState({ id });

      return routeState;
    } catch (err) {
      logger.error(err);
    }
  };

  const result = useQuery<RouteStateDTO, Error, RouteState>({
    queryKey: keys,
    queryFn: getRouteState,
    placeholderData: (previousData) => previousData,
    refetchInterval(query) {
      // Ищем в массиве пунктов назначения рейса пункт клиента
      const currentPoint = query.state.data?.find((point) => point.point_type === 'current_client');

      if (!currentPoint?.completed) return refetchInterval;

      return false;
    },
    select(data) {
      if (!data) return [] as RouteState;

      return data.map((item) => ({
        sequence: item.sequence,
        type: item.point_type,
        completed: item.completed,
        coordinates: [item.coordinates.lat, item.coordinates.lon],
      }));
    },
  });

  return result;
};

export default useRouteState;
