import { memo, useMemo } from 'react';

import * as HeaderStore from '@Stores/Header';
import styles from './SSRMenuLinks.module.css';

import type { MainMenuItem } from '@Types/Layout';

function pushItemChildren(allLevelsItems: MainMenuItem[], children: MainMenuItem[]) {
  children.forEach((item) => {
    allLevelsItems.push(item);

    if (item.banners?.length) {
      item.banners.forEach((banner) => {
        allLevelsItems.push({
          id: banner.title,
          name: banner.title,
          url: banner.link,
          render: true,
        });
      });
    }

    if (item.children?.length) pushItemChildren(allLevelsItems, item.children);
  });
}

const SSRMenuLinks = () => {
  const { items } = HeaderStore.useMenu();

  const serverLinks = useMemo(() => {
    const allLevelsItems: MainMenuItem[] = [];

    items.forEach((item) => {
      if (item.children?.length) pushItemChildren(allLevelsItems, item.children);
    });

    return allLevelsItems.filter((item) => item.render && item.url);
  }, [items]);

  return (
    <div className={styles.links}>
      {serverLinks.map((link) => (
        <a key={link.id} href={link.url} className='MenuLink'>
          {link.name}
        </a>
      ))}
    </div>
  );
};

export default memo(SSRMenuLinks);
