import * as Api from '@Api/index';

import type { ApiResponse } from './types/Api';

interface Params {
  body: FormData;
}

async function designerRegister({ body }: Params) {
  const url = `/backend/designer/register`;
  const res = await Api.query<ApiResponse>(url, {
    method: 'POST',
    body,
  });

  return res;
}

export default designerRegister;
