import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

export interface ChangeFabricData {
  fabricId: number;
  visitId: string;
  productHashes: string[];
}

async function setFabric(data: ChangeFabricData) {
  const url = '/web-crm/api/visit/set-fabric';

  const res = await Api.queryProxi<ApiResponse<any>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default setFabric;
