import useCountryBasedData from '@Hooks/useCountryBasedData';

function useRouter() {
  return {
    club: useCountryBasedData({
      KAZ: '/site/mebel-club',
      default: '/site/divan-club',
    }),
  };
}

export default useRouter;
