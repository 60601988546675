import { memo, forwardRef, useRef, useState, useEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn } from '@divlab/divanui';

import useKeyboardEvents from '@Hooks/useKeyboardEvents';
import * as HeaderStore from '@Stores/Header';
import styles from './DropDownWrapper.module.css';

import type { HTMLAttributes, ReactElement } from 'react';

export interface DropDownWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactElement;
  opened: boolean;
}

const DropDownWrapper = forwardRef<HTMLDivElement, DropDownWrapperProps>((props, ref) => {
  const { children, opened, ...restProps } = props;
  const contentRef = useRef<HTMLDivElement>();
  const [state, setState] = useState(false);

  const handleClickBackdrop = useCallback(() => {
    HeaderStore.closeMenu();
  }, []);

  useEffect(() => {
    setState(opened);

    /**
     * The `CardInView` component uses Intersection Observer API to detect and send to analytics
     * the events of viewing some elements (e.g. product or promo cards).
     * Since `DropDownWrapper` can hide some content under it we have to change the `root` parameter
     * of the `IntersectionObserver` object.
     */
    setTimeout(() => {
      if (opened) {
        window.dispatchEvent(
          new CustomEvent('intersectionObserver.changeRoot', {
            detail: { element: contentRef.current },
          }),
        );
      } else {
        window.dispatchEvent(
          new CustomEvent('intersectionObserver.changeRoot', {
            detail: { element: null },
          }),
        );
      }
    });
  }, [opened]);

  useKeyboardEvents({
    onEscape: () => {
      if (!opened) return;
      HeaderStore.closeMenu();
    },
  });

  return (
    <CSSTransition
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      in={state}
      timeout={500}
      unmountOnExit
    >
      <div className={cn(styles.dropdown, { [styles.opened]: state })}>
        <div className={styles.backdrop} onClick={handleClickBackdrop} />
        <div className={styles.content} {...restProps} ref={contentRef}>
          <div className={styles.wrapper} ref={ref}>
            <div className={styles.container}>{children}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
});

DropDownWrapper.displayName = 'DropDownWrapper';

export default memo(DropDownWrapper);
