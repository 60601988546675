import { createDerived, createStore, getValue } from '@kundinos/nanostores';

import type { StoreValue } from '@Stores/PageLock/typings';

export const store = createStore<StoreValue>(() => {
  store.set({ variants: {} });
});

/**
 * True, if there is at least one variants for blocking scrolling
 */
export const isBlockedStore = createDerived(store, (value) => {
  return Object.values(value.variants).some((item) => item.opened);
});

// Отвечает за блокировку скрола на странице
isBlockedStore.listen((blocked) => {
  const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
  const variants = getValue(store).variants;
  const bodyPosition = document.body.style.position;

  if (!blocked && bodyPosition === 'fixed') {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  document.body.style.cssText = blocked
    ? `height: ${window.innerHeight}px;
        width: ${window.innerWidth - scrollBarCompensation}px;
        top: ${variants.modal?.opened ? `-${window.scrollY}px` : ''};
        position: ${variants.modal?.opened ? 'fixed' : 'relative'};
        overflow: hidden;
        overscroll-behavior: none;
        touch-action: none;
        padding-right: ${scrollBarCompensation}px;`
    : '';
});
