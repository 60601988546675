import * as Api from '@Api/index';

export interface Params {
  id: string;
  paymentTypeId?: number;
}

async function getPay({ id, paymentTypeId }: Params) {
  const searchParams = new URLSearchParams({ id });

  if (paymentTypeId) {
    searchParams.set('paymentTypeId', String(paymentTypeId));
  }

  const url = `/backend/order/pay?${searchParams.toString()}`;
  const res = await Api.queryProxi<any>(url);

  return res;
}

export default getPay;
