import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './Subtitle.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface SubtitleProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  text: string;
}

const Subtitle: FC<SubtitleProps> = (props) => {
  const { className, text, ...restProps } = props;
  return (
    <div
      {...restProps}
      className={cn(styles.subtitle, className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default memo(Subtitle);
