import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CartData } from '@Types/Cart';

export interface Result {
  result: 'success' | 'error';
  data: CartData;
}

async function sendCheckerPromocode(params: CartData | unknown) {
  if (!params) return;
  const formData = new FormData();

  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }
  const url = '/cabinet/cart/coupon';

  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    body: formData,
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default sendCheckerPromocode;
