import { cn } from '@divlab/divanui';

import getDaysUntil from './utils/getDaysUntil';
import useTranslation from '@Queries/useTranslation';
import styles from './MapPlaceholder.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { OrderState } from '@Types/DeliveryTracking';

interface MapPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
}

const MapPlaceholder: FC<MapPlaceholderProps> = (props) => {
  const { className, order, ...restProps } = props;
  const { t } = useTranslation();

  let day = t('map.day.default');
  const daysUntil = getDaysUntil(order.deliveryDate);
  if (daysUntil === 0) day = t('map.day.today');
  if (daysUntil === 1) day = t('map.day.tomorrow');

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      <div className={styles.text}>
        {day} {t('map.placeholder')}
      </div>
      <div className={styles.map} />
    </div>
  );
};

export default MapPlaceholder;
