import { createStore, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

export interface FeaturesStoreData {
  isLocalStorageAvailable: boolean;
  isSessionStorageAvailable: boolean;
}

export const featuresStore = createStore<FeaturesStoreData>(() => {
  featuresStore.set({ isLocalStorageAvailable: false, isSessionStorageAvailable: false });
});

const checkLocalStorage = () => {
  update(featuresStore, (prev) => {
    try {
      const key = '__local_storage_test__';

      localStorage.setItem(key, '1');
      localStorage.removeItem(key);

      return { ...prev, isLocalStorageAvailable: true };
    } catch (err) {
      return { ...prev, isLocalStorageAvailable: false };
    }
  });
};

const checkSessionStorage = () => {
  update(featuresStore, (prev) => {
    try {
      const key = '__session_storage_test__';

      sessionStorage.setItem(key, '1');
      sessionStorage.removeItem(key);

      return { ...prev, isSessionStorageAvailable: true };
    } catch (err) {
      return { ...prev, isSessionStorageAvailable: false };
    }
  });
};

const init = () => {
  checkLocalStorage();
  checkSessionStorage();
};

init();

export const useBrowserFeaturesStore = () => {
  return useStore(featuresStore);
};
