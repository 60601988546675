import { memo } from 'react';

import { useRequest } from '@Contexts/Request';

import type { FC, HTMLAttributes, ReactFragment } from 'react';
import type { CountryCode, LanguageCode } from '@Types/Base';

export interface SmartRenderProps extends HTMLAttributes<ReactFragment> {
  countries: CountryCode[];
  languages?: LanguageCode[];
  condition?: boolean;
}

/**
 * Рендерит элементы только на указанных в массиве странах
 * @param countries Массив из кодов стран, например ['RUS','BLR']
 * @param languages Необязательный параметр. Массив из кодов языков, например ['RU','EN']
 * @param condition Необязательный параметр. Элементы будут отрендерены только если condition = true
 * @returns Дочерние элементы либо null, если код текущей страны не находится в списке countries, или если код текущего языка не находится в списке languages
 */
const SmartRender: FC<SmartRenderProps> = (props) => {
  const { countries, languages, condition = true, children } = props;
  const { country, language } = useRequest();

  if (!condition) return null;
  if (!countries.includes(country)) return null;
  if (languages && !languages.includes(language.id.toUpperCase())) return null;

  return <>{children}</>;
};

export default memo(SmartRender);
