import { memo } from 'react';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import logo from './FooterLogoBy.svg';
import styles from './FooterLogo.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const FooterLogo: FC<FooterProps> = () => {
  return (
    <Link className={styles.footerLogo} to='/' aria-label='divan.by'>
      <Img
        className={styles.imageWrapper}
        cnImage={styles.image}
        src={logo}
        alt='ООО «ДИВАН БАЙ»'
        title='ООО «ДИВАН БАЙ»'
      />
    </Link>
  );
};

export default memo(FooterLogo);
