import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface Params {
  productIds: number[];
  isSetConfigurator?: boolean;
}

async function getBonuses(params: Params) {
  const { productIds, isSetConfigurator } = params;
  const searchParams = new URLSearchParams();

  searchParams.set('ids', productIds.toString());

  if (isSetConfigurator) searchParams.set('isSetConfigurator', isSetConfigurator.toString());

  const url = `/backend/order/get-bonus-earned-amount?${searchParams.toString()}`;
  const res = await Api.queryProxi<any>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getBonuses;
