import {
  prefetchPromotions,
  prefetchPage,
  prefetchTranslation,
  prefetchAllRegions,
  prefetchSEOCategories,
  prefetchModalWindowTranslation,
} from '@Navigation/prefetchers';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request } = args;

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchAllRegions(queryClient),
    prefetchSEOCategories(queryClient),
    prefetchModalWindowTranslation('AllRegions', queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchPromotions({ listName: 'main', type: 'main' }, queryClient),
    prefetchPromotions({ listName: 'recommendations', type: 'card' }, queryClient),
  ]);
};
