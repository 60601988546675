import type { CartData, CartPositionData } from '@Types/Cart';

export const findPositionByProductId = (productId: number, cart: CartData): CartPositionData => {
  const positions = [...cart.removedPositions, ...cart.positions];
  let result = null;

  positions.forEach((position) => {
    position.products.forEach((product) => {
      if (product.id === productId) result = position;
    });
  });

  return result;
};

export default findPositionByProductId;
