import { createStore, keepActive } from '@kundinos/nanostores';

import type { ProfileSettingsStore, WelcomePopupStore } from './typings';

export const authSettingsStore = createStore<ProfileSettingsStore>(() => {
  authSettingsStore.set({});
});

export const welcomePopupStore = createStore<WelcomePopupStore>(() => {
  welcomePopupStore.set({ hovered: false, clicked: false });
});

keepActive(authSettingsStore);
