import React from 'react';

import type { SetStateAction, Dispatch } from 'react';
import type { Query } from '@Types/Base';

export type HttpStatusCode = 200 | 302 | 404 | 500 | 503;

export type Status = { code: HttpStatusCode | number; message?: string };

export type Language = {
  id: string;
  slug: string;
};

export type RequestState = Query & {
  status: Status;
  updateRequest?: Dispatch<SetStateAction<RequestState>>;
};

const symbolName = '__REQUEST_CONTEXT__';
const hasSymbol = typeof Symbol === 'function' && Symbol.for;
const contextSymbol = hasSymbol ? Symbol.for(symbolName) : symbolName;
const initialState = {};

export function resetContext(): void {
  Object.defineProperty(React, contextSymbol, {
    value: React.createContext(initialState),
    enumerable: false,
    configurable: true,
    writable: false,
  });
}

export function getContext(): React.Context<RequestState> {
  if (!(React as any)[contextSymbol]) {
    resetContext();
  }

  return (React as any)[contextSymbol];
}

export default getContext();
