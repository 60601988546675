import { memo, forwardRef } from 'react';

import ToplineMenu from './elements/ToplineMenu';
import ToplineMenuWithBanner from './elements/ToplineMenuWithBanner';

import type { MainMenuItem } from '@Types/Layout';
import type { HTMLAttributes } from 'react';

export interface ToplineMenuProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items?: {
    left: MainMenuItem[];
    right: MainMenuItem[];
  };
  needTopline?: boolean;
  needTopLineBanner?: boolean;
}

const ToplineMenuContainer = forwardRef<HTMLDivElement, ToplineMenuProps>((props, ref) => {
  const { needTopline, needTopLineBanner, ...restProps } = props;
  return (
    <>
      {needTopline && <ToplineMenu ref={ref} {...restProps} />}
      {needTopLineBanner && <ToplineMenuWithBanner ref={ref} {...restProps} />}
    </>
  );
});

ToplineMenuContainer.displayName = 'ToplineMenuContainer';

export default memo(ToplineMenuContainer);
