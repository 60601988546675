import useRouteMatch from './useRouteMatch';

function useRouteMatchers() {
  return {
    isIndex: useRouteMatch({ path: '/', end: true }),

    isFavourites: useRouteMatch({ path: '/favorites', end: true }),

    isOrderCheck: useRouteMatch({ path: '/order/check', end: true }),
    isCabinet: useRouteMatch({ path: '/cabinet', end: true }),

    isDivanClub: [
      useRouteMatch({ path: '/site/divan-club', end: true }),
      useRouteMatch({ path: '/site/mebel-club', end: true }),
      useRouteMatch({ path: '/landing-club/:slug', end: true }),
    ].some(Boolean),

    isProductPage: [
      useRouteMatch({ path: '/product/:slug', end: true }),
      useRouteMatch({ path: '/product-preview/:slug', end: true }),
    ].some(Boolean),

    isDeliveryPage: useRouteMatch({ path: '/site/delivery', end: true }),

    isShowroomPage: useRouteMatch({ path: '/site/showroom', end: true }),

    isOrderSuccessPage: useRouteMatch({ path: '/order/check/success', end: true }),
  };
}

export default useRouteMatchers;
