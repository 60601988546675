import { memo, useMemo } from 'react';
import { cn, Link as UILink } from '@divlab/divanui';

import useRenderType from '@Hooks/useRenderType';

import type { LinkProps as UILinkProps } from '@divlab/divanui';
import type { FC } from 'react';

export interface LinkProps extends UILinkProps {
  className?: string;
  ssr?: boolean;
}

const Link: FC<LinkProps> = (props) => {
  const { children, to, ssr = true, className, ...restProps } = props;
  const { isSSR } = useRenderType();
  const isPhone = (to || '').startsWith('tel:');

  // Иногда ссылку не нужно рендерить на сервере (например, это  может быть требованием SEO),
  // тогда для этого случая на сервере мы рендерим ссылку без аттрибута href
  const href = useMemo(() => {
    const hasSearchParams = to?.includes('?');

    return (!ssr || hasSearchParams) && isSSR ? null : to;
  }, [isSSR, ssr, to]);

  return (
    <UILink {...restProps} className={cn({ ['Tel']: isPhone }, className)} to={href || undefined}>
      {children}
    </UILink>
  );
};

export default memo(Link);
