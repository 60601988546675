import {
  cloneElement,
  Children,
  memo,
  useCallback,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useInView } from 'react-intersection-observer';

import * as Meta from '@Queries/Meta';
import * as Api from '@Api/index';
import { useDeps } from '@Contexts/DI';
import { useRequest } from '@Contexts/Request';

import type { default as Ajv, Plugin } from 'ajv';
import type { FormatsPlugin } from 'ajv-formats';
import type { ExtendedFile } from '@divlab/divanui';
import type { JsonSchema } from '@Types/Ajv';
import type { ErrorMessageOptions } from 'ajv-errors';
import type { FormEvent, ReactChild, KeyboardEvent } from 'react';
import type { CountryCode } from '@Types/Base';

export interface ChangeFilesData {
  name: string;
  medias: ExtendedFile[];
}

export interface ServerErrorsOpts {
  name: string;
  message: string;
  field?: string;
}

export interface ValidationSchemaBuilderParams {
  country?: CountryCode;
  lang?: string;
}

interface FormDataObject {
  body: FormData;
  data: FormFields;
}

type FormValue = string | File | File[];

type FormFields = Record<string, FormValue>;

export interface FormProps<CallbackData = unknown> {
  method?: 'POST' | 'GET';
  action?: string;
  className?: string;
  children: ReactChild | ReactChild[];
  validationSchemaBuilder?: (params: ValidationSchemaBuilderParams) => JsonSchema;
  disabled?: boolean;
  triggerChangeKey?: unknown;
  id?: string;
  /**
   * @deprecated Необходимо переносить обработку ошибок формы на сторону бекенда в едином формате
   */
  serverErrors?: ServerErrorsOpts[];
  itemProp?: string;
  itemScope?: boolean;
  itemType?: string;
  transformDataBeforeSubmit?: (data: FormFields) => FormFields;
  onBeforeSubmit?: (e: FormEvent, data: CallbackData) => void;
  onSubmit?: (e: FormEvent, data: CallbackData) => void;
  onChange?: (e: FormEvent, data: CallbackData) => void;
  onResponse?: (response: unknown, data: CallbackData) => void;
  onError?: (e: FormEvent, data: CallbackData) => void;
  onFocus?: (e: FormEvent) => void;
  onCriticalError?: (err?: Error) => void;
  onReset?: (e: FormEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
}

type ResponseError<T = unknown> = {
  name: string;
  message: string;
} & T;

interface ValidationError {
  name: 'ValidationError';
  field: string;
}

interface FormResponse {
  ok: boolean;
  errors?: ResponseError[];
}

function checkIsFile(value: unknown): value is File {
  return typeof value === 'object' && 'lastModified' in value;
}

function transformFormDataToObject(formData: FormData) {
  const result = {};

  formData.forEach((value, key) => {
    // схлопываем дублирующиеся поля в массив
    const prev = result[key];
    if (prev !== undefined) {
      result[key] = Array.isArray(prev) ? [...prev, value] : [prev, value];
      return;
    }

    result[key] = value;
  });

  return result;
}

const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  const {
    method = 'POST',
    action,
    children,
    validationSchemaBuilder,
    disabled,
    triggerChangeKey,
    serverErrors = [],
    transformDataBeforeSubmit,
    onBeforeSubmit,
    onSubmit,
    onChange,
    onResponse,
    onError,
    onCriticalError,
    onReset,
    ...restProps
  } = props;
  const meta = Meta.useMeta();
  const formRef = useRef<HTMLFormElement>(null);
  const ajv = useRef<Ajv>();
  const ajvFormats = useRef<FormatsPlugin>();
  const ajvErrors = useRef<Plugin<ErrorMessageOptions>>();
  const [errors, setErrors] = useState<any[]>([]);
  const { country, language } = useRequest();
  const { logger } = useDeps();
  const [files, setFiles] = useState({});
  const inputErrorRef = useRef<HTMLInputElement>(null);
  const { ref: inViewRef, inView } = useInView();

  const validationSchema = useMemo(() => {
    if (!validationSchemaBuilder) return;

    try {
      const schema = validationSchemaBuilder({ country, lang: language.id });

      return schema;
    } catch (e) {
      logger.error(e);
      return null;
    }
  }, [country, language.id, logger, validationSchemaBuilder]);

  const handleScroll = useCallback(
    (error) => {
      if (!error?.length) return null;
      if (!formRef.current) return null;

      const nameFieldWithError: string = error[0].name;
      inputErrorRef.current = formRef.current.querySelector(`[name='${nameFieldWithError}']`);

      if (!inputErrorRef.current) return;
      inViewRef(inputErrorRef.current);

      if (!inView) {
        inputErrorRef.current.scrollIntoView({ block: 'center' });
      }
    },
    [inViewRef, inView],
  );

  const handleChangeFiles = useCallback((value: ChangeFilesData) => {
    setFiles((prevState) => {
      return {
        ...prevState,
        [value.name]: value.medias,
      };
    });
  }, []);

  // TODO: Kundin нужно избавиться от этого предупреждения
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fields: any = {};
  let countShowedErrors = 0;

  // Transforms form data depending on provided additional data
  const transformFormData = useCallback((formData: FormDataObject, additionalData: FormFields) => {
    const { data, body } = formData;
    Object.entries(additionalData).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.map((value) => body.append(key, value));
      } else {
        body.append(key, values as string | File);
      }
      data[key] = values;
    });
  }, []);

  const getFormValues = useCallback(
    (target: HTMLFormElement) => {
      let body = new FormData(target);
      let data: FormFields = transformFormDataToObject(body);

      // Преобразуем в строку все поля, кроме файлов
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const isFile = checkIsFile(value);
        const isArrayOfFiles = Array.isArray(value) && value.every((item) => checkIsFile(item));

        data[key] = isFile || isArrayOfFiles ? value : String(value).trim();
      });

      // Добавляем файлы
      transformFormData({ data, body }, files);

      // Трансформируем данные перед отправкой
      if (transformDataBeforeSubmit) {
        data = transformDataBeforeSubmit(data);

        body = new FormData();

        transformFormData({ data, body }, data);
      }

      return { body, data };
    },
    [transformDataBeforeSubmit, transformFormData, files],
  );

  // Load the AJV and scheme for validation
  const loadValidator = useCallback(async () => {
    const loadAjv = async () => {
      if (ajv.current) return ajv.current;

      const AjvLib = (await import('ajv')).default;

      return new AjvLib({ allErrors: true });
    };

    const loadAjvErrors = async () => {
      if (ajvErrors.current) return ajvErrors.current;

      const AjvErrorsLib = (await import('ajv-errors')).default;

      return AjvErrorsLib;
    };

    const loadAjvFormats = async () => {
      if (ajvFormats.current) return ajvFormats.current;

      const AjvFormatsLib = (await import('ajv-formats')).default;

      return AjvFormatsLib;
    };

    try {
      const result = await Promise.all([loadAjv(), loadAjvFormats(), loadAjvErrors()]);

      ajv.current = result[0];
      ajvFormats.current = result[1];
      ajvErrors.current = result[2];
      ajvErrors.current(ajv.current);
      ajvFormats.current(ajv.current);
    } catch (err) {
      logger.log(err);
    }
  }, [logger]);

  const reset = useCallback((htmlElement) => {
    for (let i = 0; i < htmlElement.children.length; i += 1) {
      const child = htmlElement.children[i];
      const isInputFile = child.tagName === 'INPUT' && child.getAttribute('type') === 'file';

      if (isInputFile) {
        child.dispatchEvent(new CustomEvent('onCustomReset'));
      }

      reset(child);
    }
  }, []);

  const handleReset = useCallback(
    (e) => {
      formRef.current.reset();
      reset(formRef.current);

      if (onReset) {
        onReset(e);
      }
    },
    [onReset, reset],
  );

  // Send the form to action
  const submit = useCallback(
    async (e) => {
      const { body, data } = getFormValues(e.target);

      try {
        const opts: RequestInit = { method, body };
        const response = await Api.queryProxi<FormResponse>(action, opts);

        if (response.errors && response.errors.length > 0) {
          const er = response.errors.map((error: ResponseError) => ({
            ...error,
            name:
              error.name === 'ValidationError'
                ? (error as ResponseError<ValidationError>).field
                : error.name,
            message: error.message || 'Неизвестная ошибка',
          }));
          setErrors(er);
          handleScroll(er);

          response.errors
            .filter((error) => error.name !== 'ValidationError')
            .forEach((error) => {
              logger.log(error);
            });
        }

        if (onResponse) onResponse(response, data);
      } catch (err) {
        logger.log(err);

        if (onCriticalError) onCriticalError(err);
      }
    },
    [action, getFormValues, method, onResponse, handleScroll, logger, onCriticalError],
  );

  const handleChange = useCallback(
    (e?: FormEvent) => {
      const { data } = getFormValues(formRef.current);

      if (onChange) onChange(e, data);
    },
    [getFormValues, onChange],
  );

  const handleError = useCallback(
    (e: FormEvent) => {
      const er = (ajv.current.errors || []).map((ajvError) => {
        const name = ajvError.instancePath.split('/')[1];
        const message = ajvError.message;

        return {
          name,
          message,
          hidden: false,
        };
      });
      setErrors(er);
      handleScroll(er);

      if (onError) {
        onError(e, ajv.current.errors);
      }
    },
    [handleScroll, onError],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // Очищаем список ошибок перед обработкой формы
      setErrors([]);

      // Форма выключена, ничего делать не нужно
      if (disabled) return;

      const { data } = getFormValues(e.target);
      // Если была указана схема валидации, то нужно пройти валидацию
      if (validationSchema) {
        // Дожидаемся загрузки инструментов для валидации
        if (!ajv.current) {
          await loadValidator();
        }

        const isValid = ajv.current.validate(validationSchema, data);

        if (!isValid) {
          handleError(e);
          return;
        }
      }

      const canSubmit = onBeforeSubmit ? onBeforeSubmit(e, data) : true;

      // Отправляем данные только если был передан адрес
      if (canSubmit && !!action) submit(e);
      if (onSubmit) onSubmit(e, data);
    },
    [
      disabled,
      getFormValues,
      validationSchema,
      onBeforeSubmit,
      action,
      submit,
      onSubmit,
      loadValidator,
      handleError,
    ],
  );

  const handleFocusControl = useCallback((_e, { name }) => {
    setErrors((prevErrors) => {
      return prevErrors.map((error) => ({
        ...error,
        hidden: error.hidden ? error.hidden : error.name === name,
      }));
    });
  }, []);

  // Рекурсивно находим все поля формы и ошибки к ним
  const findFields = useCallback(
    (_children) => {
      Children.forEach(_children, (_child) => {
        if (!_child || !_child.props) {
          return null;
        }

        const { name } = _child.props;
        const isUpload = _child.type?.type?.displayName === 'Upload';

        if (name) {
          const error = errors.find((e) => e.name === name);

          if (error) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            countShowedErrors += 1;
          }

          const defaultProps = {
            ..._child.props,
            error: countShowedErrors < 2 && error && !error.hidden ? error.message : null,
            onFocus: (e: MouseEvent) => {
              handleFocusControl(e, { name });
              if (_child.props.onFocus) _child.props.onFocus(e);
            },
          };

          fields[name] = cloneElement(
            _child,
            isUpload
              ? { ...defaultProps, onChangeFiles: (medias) => handleChangeFiles({ name, medias }) }
              : defaultProps,
          );
        }

        if (_child.props.children) {
          return findFields(_child.props.children);
        }

        return null;
      });
    },
    [fields],
  );
  findFields(children);

  const renderChild: any = useCallback(
    (_child: any) => {
      if (!_child || !_child.props) {
        return _child;
      }

      if (_child.props.name) {
        return fields[_child.props.name];
      }

      if (_child.props.children) {
        return cloneElement(
          _child,
          _child.props,
          Children.map(_child.props.children, (_c) => renderChild(_c)),
        );
      }

      return _child;
    },
    [fields],
  );

  // TODO: стоит пересмотреть подход к работе с элементами формами и либо иметь весь стейт формы, либо делать с помощью нативных элементов
  // Принудительно тригерим событие об изменении формы
  useEffect(() => {
    if (!triggerChangeKey) return;

    handleChange();
  }, [handleChange, triggerChangeKey]);

  // Обновляем внутрений массив ошибок при изменении серверных ошибок
  useEffect(() => {
    if (!serverErrors.length) return;

    setErrors(() => {
      const newErrors = serverErrors.map((serverError) => {
        return { ...serverError, hidden: false };
      });

      return newErrors;
    });
  }, [serverErrors]);

  useImperativeHandle(ref, () => formRef.current);

  return (
    <form
      {...restProps}
      ref={formRef}
      method={method}
      action={action && `${meta.data.region.url}${action}`}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onChange={handleChange}
    >
      {Children.map(children, (child) => renderChild(child))}
    </form>
  );
});

Form.displayName = 'Form';

export default memo(Form);
