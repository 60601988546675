import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';
import generateHash from '@Utils/generateHash';

import type { CartModalInputProduct, UpdatedCartInfo } from '@Types/Cart';

export interface Params {
  products: CartModalInputProduct[];
  oldHash?: string;
}

export interface Result {
  result: 'success' | 'error';
  data: UpdatedCartInfo;
}

async function updatePosition({ products, oldHash }: Params) {
  const searchParams = new URLSearchParams();

  if (oldHash) searchParams.append('hash', oldHash);

  const url = `/cabinet/cart/update-position?${searchParams.toString()}`;

  const hashedProducts = products.map((product) => {
    const hash = generateHash({
      id: product.shopProductId,
      parameters: product.isModifiedParameters ? product.parameterValues : [],
      quantity: null,
      shopProducts: product.shopProducts?.length > 0 ? product.shopProducts : [],
    });
    product.hash = hash;
    return product;
  });

  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(hashedProducts),
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default updatePosition;
