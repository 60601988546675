import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './PaymentList.module.css';

import type { FooterPaymentItemData } from '@Types/Layout';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

export interface PaymentListProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: FooterPaymentItemData[];
}

export interface ContainerProps {
  className?: string;
  link?: string;
}

const Container: FC<PropsWithChildren<ContainerProps>> = (props) => {
  const { children, link, ...restProps } = props;

  return link ? (
    <a {...restProps} href={link} rel='nofollow noreferrer noopener' target='_blank'>
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

const PaymentList: FC<PaymentListProps> = ({ items, className }) => {
  return (
    <ul className={cn(styles.paymentList, className)}>
      {items.map((item) => {
        return (
          <li key={item.code}>
            <Container link={item.link}>{item.icon}</Container>
          </li>
        );
      })}
    </ul>
  );
};

export default memo(PaymentList);
