import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { OrderStateParams, OrderStateResponse, OrderStateDTO } from './typings';

async function getOrderState(params: OrderStateParams): Promise<OrderStateDTO> {
  const searchParams = new URLSearchParams({ id: params.id });
  const searchString = searchParams.toString() ? `?${searchParams.toString()}` : '';

  const url = `/delivery/delivery_tracking/get_order_state${searchString}`;
  const result = await Api.queryProxi<OrderStateResponse>(url);

  if (!result.result) throw new BackendError(url, result);

  return result.data;
}

export default getOrderState;
