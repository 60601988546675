import { getValue } from '@kundinos/nanostores';

import { modalsStore, visibleModalsStore } from './stores';

/**
 * Closes all opened modal windows
 */
function closeAll(): Promise<void> {
  const modals = getValue(modalsStore);
  const visibleModals = getValue(visibleModalsStore);
  const timeout = visibleModals.length > 0 ? modals.timeout : 0;

  return new Promise((resolve) => {
    modalsStore.set({
      ...modals,
      stack: modals.stack.map((modal) => ({
        ...modal,
        visible: false,
      })),
    });

    setTimeout(() => {
      modalsStore.set({ ...modals, stack: [] });
      resolve();
    }, timeout);
  });
}

export default closeAll;
