import { useMemo } from 'react';

import useNavigation from '@Navigation/useNavigation';

/**
 * Упрощает получение search-параметров с помощью react-router
 * Работает как на сервере, так и в браузере
 * @param search — строка с параметрами, если не указано используется search-часть URL текущей страницы
 */
function useSearchParams(search?: string) {
  const location = useNavigation();

  const searchParams = useMemo(() => {
    return new URLSearchParams(search || location.search);
  }, [location.search, search]);

  return searchParams;
}

export default useSearchParams;
