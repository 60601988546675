import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { CartData } from '@Types/Cart';

interface Params {
  signal?: AbortSignal;
}

async function getCartInfo({ signal = undefined }: Params = {}) {
  const url = '/cabinet/cart/get-info';
  const res = await Api.queryProxi<ApiResponse<CartData>>(url, { signal });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getCartInfo;
