import * as Api from '@Api/index';

interface Params {
  pathname: string;
  search?: string;
}

async function getPage<T>(params: Params) {
  const { pathname, search = '' } = params;
  const url = `/backend${pathname}${search}`;
  const response = await Api.query<any>(url);
  const data = response.body.data;

  return data as T;
}

export default getPage;
