import { useMemo } from 'react';
import { cn } from '@divlab/divanui';

import { useFeatureFlags } from '@Contexts/FeatureFlags';
import IconSocial from './elems/IconSocial';
import styles from './SocialList.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface SocialItem {
  link?: string;
  code?: string;
}

export interface SocialListProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: SocialItem[];
  size?: 'large' | 'small';
}

const SocialList: FC<SocialListProps> = (props) => {
  const { className, items, size = 'small' } = props;
  const featureFlags = useFeatureFlags();

  const socials = useMemo(() => {
    if (featureFlags.instagramAvailable) return items;

    return items.filter((item) => item.code !== 'instagram');
  }, [featureFlags.instagramAvailable, items]);

  return (
    <div
      className={cn(
        styles.socialList,
        {
          [styles.sizeLarge]: size === 'large',
          [styles.sizeSmall]: size === 'small',
        },
        className,
      )}
    >
      {socials.map((item) => (
        <div key={item.link}>
          <IconSocial type={item.code} link={item.link} className={styles.iconSocial} />
        </div>
      ))}
    </div>
  );
};

export default SocialList;
