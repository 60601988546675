import * as Api from '@Api/index';

interface Params {
  url: string;
}

interface Response<T> {
  body: {
    data: T;
  };
  page: string;
}

async function fetchPage<T>(params: Params): Promise<T> {
  const url = `/backend${params.url}`;

  const res = await Api.queryProxi<Response<T>>(url, { headers: { 'X-Request-Type': 'page' } });

  return { ...res.body?.data, page: res.page };
}

export default fetchPage;
