const errorMessages = {
  ru: {
    longText: 'Фамилия не может быть длиннее 85 символов',
  },
  uz: {
    longText: 'Familiya 85 belgidan oshmasligi kerak',
  },
  kk: {
    longText: 'Тегі 85 таңбадан аспауы керек',
  },
};

export default (lang: string, params = {}) => {
  return {
    description: 'Фамилия',
    type: 'string',
    maxLength: 85,
    errorMessage: { maxLength: errorMessages[lang].longText },
    ...params,
  };
};
