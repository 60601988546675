import { memo, useCallback } from 'react';
import { Boldik, cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import Link from '@Navigation/Link';
import styles from './Item.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface ItemProps extends HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  item: MainMenuItem;
}

const Item: FC<ItemProps> = (props) => {
  const { item, ...restProps } = props;

  const handleClickLink = useCallback(() => {
    HeaderStore.closeMenu();
  }, []);

  return (
    <Link
      className={cn(styles.link, item.url && 'MenuLink')}
      to={item.url}
      ssr={item.render}
      onClick={handleClickLink}
      {...restProps}
    >
      <Boldik className={styles.menuName}>{item.name}</Boldik>
      {item.children.length > 0 && <IconChevronRight className={styles.arrow} />}
    </Link>
  );
};

export default memo(Item);
