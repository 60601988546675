import CommonError from './common-error';

interface Params {
  url: string;
  status: number;
}

export class IncorrectBackendResponseError extends CommonError {
  name = 'IncorrectBackendResponseError';

  message = '';

  url = '';

  status = 0;

  constructor({ url, status }: Params) {
    const message = `Incorrect response from backend`;

    super(`${message} by URL: ${url}, status: ${status}`);
    this.message = message;
    this.url = url;
    this.status = status;
  }
}
