import { memo, useCallback, forwardRef, useRef } from 'react';
import { cn, Button } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import Line from './Line';
import { useDeps } from '@Contexts/DI';
import Link from '@Navigation/Link';
import styles from './FirstLevelNav.module.css';

import type { HTMLAttributes, MouseEvent } from 'react';

export interface FirstLevelNavProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  onClickItem?: (e: MouseEvent) => void;
}

const FirstLevelNav = forwardRef<HTMLElement, FirstLevelNavProps>((props, ref) => {
  const { className, onClickItem, ...restProps } = props;
  const { items, isOpenedMenu } = HeaderStore.useMenu();
  const { analytics } = useDeps();
  const scrollY = useRef(0);
  const lineStrokeColor = 'black';

  const handleClick = useCallback(
    (e: MouseEvent, title: string) => {
      if (isOpenedMenu) {
        HeaderStore.closeMenu();
        window.scrollTo({ top: scrollY.current });
      } else {
        scrollY.current = window.scrollY;
        HeaderStore.openMenu();
        window.scrollTo({ top: 0 });
      }

      analytics.dispatchEvent('header.clickFirstLvlItem', { title });
    },
    [analytics, isOpenedMenu],
  );

  const handleClickItem = useCallback(
    (e: MouseEvent, title: string) => {
      if (onClickItem) onClickItem(e);

      analytics.dispatchEvent('header.clickFirstLvlItem', { title });
    },
    [analytics, onClickItem],
  );

  return (
    <nav {...restProps} className={cn(styles.nav, className)} ref={ref}>
      {items.map((item) =>
        item.parameters?.button === 'burger' ? (
          <Button
            key={item.id}
            className={cn(styles.menuButton, { [styles.burgerButton]: true })}
            theme='transparent'
            onClick={(e) => handleClick(e, item.name)}
          >
            <div className={cn(styles.menuIcon, { [styles.opened]: isOpenedMenu })}>
              <Line stroke={lineStrokeColor} className={styles.menuIconItem} />
              <Line stroke={lineStrokeColor} className={styles.menuIconItem} />
              <Line stroke={lineStrokeColor} className={styles.menuIconItem} />
            </div>
            {item.name}
          </Button>
        ) : (
          <Link
            className={cn(styles.linkWrapper, item.url && 'MenuLink')}
            key={item.id}
            to={item.url}
            ssr={item.render}
            onClick={(e) => handleClickItem(e, item.name)}
          >
            <span className={styles.link}>{item.name}</span>
          </Link>
        ),
      )}
    </nav>
  );
});

FirstLevelNav.displayName = 'FirstLevelNav';

export default memo(FirstLevelNav);
