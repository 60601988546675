import {
  prefetchDirectCreditStatus,
  prefetchPage,
  prefetchTranslation,
} from '@Navigation/prefetchers';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request, params } = args;
  const { orderId } = params;

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchDirectCreditStatus({ uuid: orderId, queryClient }),
  ]);
};
