import { matchRoutes } from 'react-router-dom';

import { createRoutes } from './routes';

function findPathPattern(url: string) {
  const [pathname] = url.split('?');
  const routes = createRoutes(null);
  const matchedRoutes = matchRoutes(routes, { pathname });
  const pattern = matchedRoutes
    .map(({ route }) => route.path)
    .join('/')
    .replace(/\/\//g, '/');

  return pattern;
}

export default findPathPattern;
