import { memo } from 'react';

import useTranslation from '@Queries/useTranslation';
import { useRequest } from '@Contexts/Request';
import Phone from '@Components/Phone';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import images from './icons';
import styles from './DeliveryInfo.module.css';

import type { MetaData } from '@Types/Meta';
import type { CountryCode } from '@Types/Base';
import type { FC, HTMLAttributes } from 'react';

export interface DeliveryInfoProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  country: CountryCode;
  phones: MetaData['phones'];
}

const DeliveryInfo: FC<DeliveryInfoProps> = (props) => {
  const { country, phones } = props;
  const { language } = useRequest();
  const { t } = useTranslation();

  const image = images[country][language.id];

  return (
    <div className={styles.delivery}>
      <div className={styles.inner}>
        <div>
          <p className={styles.title}> {t('ui.delivery-info.title')}</p>
          <p className={styles.text}>
            {t('ui.delivery-info.subtitle')} <Phone className={styles.link} phone={phones[0]} />
          </p>
          <Link className={styles.more} view='primary' to='/site/delivery' underlined>
            {t('ui.more')}
          </Link>
        </div>

        <Img className={styles.image} src={image} />
      </div>
    </div>
  );
};

export default memo(DeliveryInfo);
