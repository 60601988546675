import { memo, forwardRef } from 'react';
import { Button as UIButton } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import Link from '@Navigation/Link';

import type { ButtonProps as UIButtonProps } from '@divlab/divanui';

export interface ButtonProps extends Omit<UIButtonProps, 'waitingText'> {
  preventDefault?: boolean;
  to?: string;
  cnButton?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { to, preventDefault, className, cnButton, ...restProps } = props;
  const { t } = useTranslation();

  return to ? (
    <Link preventDefault={preventDefault} to={to} className={className}>
      <UIButton
        {...restProps}
        waitingText={t('ui.wait-a-second')}
        className={cnButton}
        ref={ref}
        wide
      />
    </Link>
  ) : (
    <UIButton {...restProps} className={className} waitingText={t('ui.wait-a-second')} ref={ref} />
  );
});

Button.displayName = 'Button';

export default memo(Button);
