import { memo, useCallback } from 'react';

import * as SearchStore from '@Stores/Search';
import Highlight from '../Highlight';
import styles from './List.module.css';

import type { SearchCategoryData } from '@Types/Search';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface ListProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  title?: string;
  list?: SearchCategoryData[];
  onSearch?: (e: MouseEvent, item: string) => void;
}

const List: FC<ListProps> = (props) => {
  const { title, className, list = [], onSearch } = props;
  const { result } = SearchStore.useSearch();

  const handleClick = useCallback(
    (e: MouseEvent, name: string) => {
      if (onSearch) onSearch(e, name);
    },
    [onSearch],
  );

  return (
    <div className={className}>
      {title && <div className={styles.title}>{title}</div>}
      {list.length > 0 && (
        <div className={styles.list}>
          {list.map((item) => (
            <div key={item.name} className={styles.item}>
              <div className={styles.subTitle} onClick={(e) => handleClick(e, item.name)}>
                <Highlight search={result.request} text={item.name} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(List);
