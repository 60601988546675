import { memo } from 'react';

import LogoWillFindYours from './elems/LogoWillFindYours';
import LogotypeRedesign from './elems/LogotypeRedesign';

import type { FC } from 'react';
import type { CountryCode } from '@Types/Base';

export interface LogotypeProps {
  className?: string;
  country?: CountryCode;
}

const redesignedCountries = ['RUS', 'BLR', 'KAZ', 'UZB'];

const Logotype: FC<LogotypeProps> = (props) => {
  const { country = 'RUS', ...restProps } = props;
  const isRedesigned = redesignedCountries.includes(country);

  const logo = isRedesigned ? (
    <LogotypeRedesign country={country} />
  ) : (
    <LogoWillFindYours country={country} />
  );

  return <div {...restProps}>{logo}</div>;
};

export default memo(Logotype);
