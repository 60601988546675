import type { ValidationSchemaBuilderParams as Params } from '@UI/Form';

const errorMessages = {
  ru: {
    requiredNumber: 'Укажите номер заказа',
  },
  uz: {
    requiredNumber: 'Buyurtma raqamini kiriting',
  },
  kk: {
    requiredNumber: 'Тапсырыс нөмірін енгізіңіз',
  },
  en: {
    requiredNumber: 'Enter the order number',
  },
};

export default ({ lang = 'ru' }: Params) => {
  return {
    description: 'Номер заказа',
    type: 'string',
    minLength: 1,
    errorMessage: {
      required: errorMessages[lang].requiredNumber,
      minLength: errorMessages[lang].requiredNumber,
    },
  };
};
