import { useCallback } from 'react';

const useCombinedRef = (...refs) => {
  const combinedRef = useCallback(
    (element: Element) => {
      refs.forEach((ref) => {
        if (!ref) return;
        if (typeof ref === 'function') {
          ref(element);
        } else {
          ref.current = element;
        }
      });
    },
    [refs],
  );

  return combinedRef;
};

export default useCombinedRef;
