import { useQuery } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
function useCartShortInfo() {
  const keys = useCartKeys();

  const query = useQuery({
    queryKey: ['cartShortInfo', ...keys],
    queryFn: async () => ApiCart.cart(),
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  return query;
}

export default useCartShortInfo;
