import { memo } from 'react';
import { cn } from '@divlab/divanui';

import * as Meta from '@Queries/Meta';
import useTelephony from '@Queries/useTelephony';
import PhoneNumber from '@UI/PhoneNumber';

import type { FC } from 'react';
import type { LinkProps } from '@Navigation/Link';
import type { MetaPhoneData } from '@Types/Meta';

interface PhoneProps extends LinkProps {
  className?: string;
  phone: string | MetaPhoneData;
}

const Phone: FC<PhoneProps> = (props) => {
  const { className, phone = '', ...restProps } = props;
  const meta = Meta.useMeta();
  const ctPhone = useTelephony();

  const phoneMask = meta.data?.phones[0]
    ? `+${meta.data.phones[0].country.replace(/\d/g, 'X')} (${meta.data.phones[0].code.replace(
        /\d/g,
        'X',
      )}) ${meta.data.phones[0].phone.replace(/\d/g, 'X')}`
    : '';

  return (
    <PhoneNumber
      className={cn(className, 'calltouch_phone')}
      phone={ctPhone || phone}
      mask={phoneMask}
      {...restProps}
    />
  );
};

export default memo(Phone);
