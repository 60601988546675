export class ValidationSchemeError extends Error {
  constructor(pattern: 'order' | 'phone', country: string) {
    super();

    this.name = 'ValidationSchemeError';

    switch (pattern) {
      case 'order':
        this.message = `Схемы валидации, форма оформления заказа: нет схемы для страны "${country}"`;
        break;
      case 'phone':
        this.message = `Схемы валидации, паттерн номера телефона: нет паттерна для страны "${country}"`;
        break;
      default:
        this.message = 'Ошибка в схемах валидации';
    }
  }
}

export default ValidationSchemeError;
