import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useRenderType from '@Hooks/useRenderType';
import Link from '@UI/Link';
import styles from './DeveloperLink.module.css';

import type { FC } from 'react';
import type { LinkProps } from '@UI/Link';

const DeveloperLink: FC<LinkProps> = (props) => {
  const { className, ...restProps } = props;
  const { isSSR } = useRenderType();

  if (isSSR) return null;

  return (
    <Link
      {...restProps}
      className={cn(styles.link, className)}
      view='secondary'
      to='https://suprematika.ru'
      target='_blank'
      rel='noopener noreferrer'
      ssr={false}
    >
      При участии Супрематики
    </Link>
  );
};

export default memo(DeveloperLink);
