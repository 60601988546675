export default (params = {}) => {
  return {
    description: 'ФИО',
    type: 'string',
    maxLength: 85,
    errorMessage: {
      required: 'Это обязательное поле',
      maxLength: 'ФИО не может быть длиннее 85 символов',
    },
    ...params,
  };
};
