import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { RegionHintData } from '@Types/Region';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  term: string;
  country: string;
}

async function getRegionHints(params: Params, opts?: RequestInit) {
  const { term, country } = params;
  const searchParams = new URLSearchParams({ term: term.toLowerCase().trim() });

  if (country) searchParams.append('country', country.trim());

  const url = `/backend/region?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<RegionHintData[]>>(url, opts);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getRegionHints;
