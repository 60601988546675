import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CartData } from '@Types/Cart';

export interface ParamsData {
  [key: string]: string | number;
}

export interface Result {
  result: 'success' | 'error';
  data: CartData;
}

async function sendBonus(params: ParamsData, opts?: RequestInit) {
  if (!params) return;
  const formData = new FormData();
  const options = opts || {};

  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value.toString());
  }

  const url = '/cabinet/cart/set-amount-points-bonus';

  const res = await Api.queryProxi<Result>(url, {
    ...options,
    method: 'POST',
    body: formData,
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default sendBonus;
