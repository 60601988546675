import { useState, useCallback, useEffect } from 'react';
import loadable from '@loadable/component';

import * as PageLock from '@Stores/PageLock';
import ErrorBoundary from '@Components/ErrorBoundary';
import Popups from '@Stores/Popups';
import useMedias from '@Hooks/useMedias/useMedias';

import type { PopupData } from '@Stores/Popups';
import type { FC } from 'react';

export interface Props {
  className?: string;
  popup: PopupData;
}

function handleOpenByEvent(event: Event) {
  const popupInfo = (event as CustomEvent<PopupData>).detail;
  Popups.open(popupInfo);
}

const Loader = loadable((props: Props) => import(`@Popups/${props.popup.id}Popup/index`));

const PopupManager: FC = () => {
  const popups = Popups.useVisiblePopups();
  const { isMobile } = useMedias();
  const [key, setKey] = useState(1);

  const handleError = useCallback(() => {
    Popups.clear();

    setKey((prev) => prev + 1);
  }, []);

  useEffect(() => {
    window.addEventListener('popups.open', handleOpenByEvent);

    return () => {
      window.removeEventListener('popups.open', handleOpenByEvent);
    };
  }, []);

  useEffect(() => {
    const isVisiblePopup = popups.length > 0;

    if (isMobile && isVisiblePopup) {
      PageLock.add('modal');
    } else {
      PageLock.remove('modal');
    }
    return () => {
      PageLock.remove('modal');
    };
  }, [popups, isMobile]);

  return (
    <ErrorBoundary key={key} onError={handleError}>
      {popups.map((popup) => (
        <Loader
          key={popup.id}
          popup={popup}
          onClose={() => {
            Popups.close(popup.id);
          }}
        />
      ))}
    </ErrorBoundary>
  );
};

export default PopupManager;
