import { json } from 'react-router-dom';

import type { LoaderFn } from '@Types/Router';
export const loader: LoaderFn = () => async (args) => {
  const url = new URL(args.request.url);
  const step = url.searchParams.get('step');

  if (step === '1') {
    return json({
      step: +step,
    }) as unknown as unknown[];
  }
  return null;
};

export default loader;
