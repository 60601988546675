import type { Query } from '@Types/Base';
import type { LoaderSearch } from './loader';

export const lazy = async (query: Query, countries: LoaderSearch) => {
  const { country } = query;

  let LazyComponent;
  if (countries.digineticaCountries.includes(country))
    LazyComponent = await import('@Pages/PageSearchCommon/PageDigineticaSearch');
  if (countries.searchCountries.includes(country))
    LazyComponent = await import('@Pages/PageSearchCommon/PageSearch');

  return LazyComponent.default;
};

export default lazy;
