import getPage from './get-page';
import saveFiles from './save-files';
import sendAnswers from './send-answers';
import sendFabrics from './send-fabrics';
import designerRegister from './designer-register';
import getAllRegions from './getAllRegions';
import customerCheck from './customer-check';
import phoneCheck from './phone-check';
import getQuizzPage from './get-quizz-page';
import sendQuizzLead from './send-quizz-lead';
import promocodeExists from './promocode-exists';
import procomodeValidation from './promocode-validation';

export default {
  getPage,
  saveFiles,
  sendAnswers,
  sendFabrics,
  phoneCheck,
  designerRegister,
  getAllRegions,
  customerCheck,
  getQuizzPage,
  sendQuizzLead,
  promocodeExists,
  procomodeValidation,
};
