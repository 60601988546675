import { memo } from 'react';
import { Boldik, cn } from '@divlab/divanui';
import { IconBoldCross } from '@divlab/divanui/icons';

import useMedias from '@Hooks/useMedias';
import Link from '@Navigation/Link';
import styles from './Dropdown.module.css';

import type { FooterMenuAwardLinks } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface WidgetLinksProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: FooterMenuAwardLinks[];
  onCloseDropdown?: () => void;
}

const DropdownLinks: FC<WidgetLinksProps> = (props) => {
  const { items, onCloseDropdown, className, ...restProps } = props;
  const { isMobileM } = useMedias();

  return (
    <div {...restProps} className={cn(styles.wrapperDropdown, className)}>
      <div className={styles.dropdown}>
        {isMobileM && <IconBoldCross className={styles.icon} onClick={onCloseDropdown} />}
        {items.map((item) => {
          return (
            <div className={styles.dropdownItem} key={item.title}>
              <Link to={item.link}>
                <Boldik className={styles.dropdownText}>{item.title}</Boldik>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(DropdownLinks);
