import { useMemo } from 'react';

function useMessengers() {
  const messengers = useMemo(() => {
    return [
      { id: 'whatsapp', title: 'WhatsApp' },
      { id: 'viber', title: 'Viber' },
      { id: 'telegram', title: 'Telegram' },
      { id: 'any', title: 'Любой' },
    ].map((item) => ({ ...item, name: item.title }));
  }, []);

  return messengers;
}

export default useMessengers;
