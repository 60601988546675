import * as Api from '@Api/index';

import type { ApiResponse } from './types/Api';
interface Params {
  phone: string;
}

export type CheckStatus = 'registered' | 'processing' | 'unregistered';

interface Result {
  isRegistered: boolean;
}

async function phoneCheck(body: Params) {
  const url = '/backend/designer/is-registered';
  const response = await Api.query<ApiResponse<Result>>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return response;
}

export default phoneCheck;
