import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { SearchInfoData } from '@Types/Search';
import type { ApiResponse } from '@Types/Api';

async function searchInfo() {
  const url = `/backend/search/info`;
  const res = await Api.queryProxi<ApiResponse<SearchInfoData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default searchInfo;
