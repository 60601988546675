import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

async function getVisit({ url }) {
  const crmUrl = `/web-crm/api${url}`;

  const res = await Api.queryProxi<ApiResponse<void>>(crmUrl);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getVisit;
