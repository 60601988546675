import { useEffect, useRef, useCallback } from 'react';

import type { RefObject } from 'react';

type OnClickOutsideCallback = (e: MouseEvent) => void;

/**
 * Helps to track click outside specified HTML Element
 * @param onClickOutside - Function that call when click outside
 * @param disabled - If true, that the callback won't trigger
 * @returns React ref object, that contains HTML Element to track
 */
function useOnClickOutside<N extends HTMLElement>(
  onClickOutside: OnClickOutsideCallback,
  disabled?: boolean,
): RefObject<N> {
  const ref = useRef<N>(null);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as Node;

      if (!ref.current || ref.current.contains(target)) return;
      onClickOutside(e);
    },
    [onClickOutside],
  );

  useEffect(() => {
    function cleanup() {
      window.removeEventListener('mousedown', handleClick);
    }

    if (!disabled) {
      window.addEventListener('mousedown', handleClick);
    }

    return cleanup;
  }, [disabled, handleClick]);

  return ref;
}

export default useOnClickOutside;
