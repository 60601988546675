import { prefetchPage } from '@Navigation/prefetchers';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request } = args;

  return Promise.all([prefetchPage(request.url, queryClient)]);
};
