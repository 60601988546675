import { useRequest } from '@Contexts/Request';

// Возвращает ключи кэширования для корзины
function useCartKeys() {
  const { region, language } = useRequest();

  return ['cart', { region, lang: language.id }];
}

export default useCartKeys;
