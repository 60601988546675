// TODO:
// Это временный метод, получение товаров для ВСЕХ категорий должно происходить с помощью getProducts
import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CatalogData } from '@Types/Catalog';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  slug: string;
  page: number | string;
  search?: string;
  isInit?: boolean;
}

async function getPromoProducts({ slug, page, search = '', isInit = true }: Params) {
  const searchParams = new URLSearchParams(search);

  searchParams.set('translite', slug);
  searchParams.set('page', (page || 1).toString());
  if (isInit) searchParams.set('isInit', 'true');

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/promo/get-products${qs}`;
  const res = await Api.queryProxi<ApiResponse<CatalogData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getPromoProducts;
