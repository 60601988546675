import useCompareList from './useCompareList';
import useInCompare from './useInCompare';
import { useDeps } from '@Contexts/DI';

import type { ConstructorValueData } from '@Pages/PageProduct/pages/PageProductConstructor';
import type { AddEventData, RemoveEventData } from '@Events/listeners/compare-listener';
import type { CartProductData } from '@Types/Cart';
import type { ProductData } from '@Types/Product';

export type ComparePayload = {
  analytics?: {
    product: ProductData | CartProductData;
    label?: string;
  };
  hash?: string;
  parameterValues?: ConstructorValueData[];
};

const useCompare = (product_id?: ProductData['id'], hash?: string) => {
  const { eventManager } = useDeps();
  const compareList = useCompareList();
  const isInCompare = useInCompare(product_id, hash);
  const compareCount = compareList?.data?.count || 0;

  const add = ({ productId, payload, onSuccess }: AddEventData) => {
    eventManager.dispatch('compare.add', { productId, payload, onSuccess });
  };

  const remove = ({ productId, payload, onSuccess }: RemoveEventData) => {
    eventManager.dispatch('compare.remove', { productId, payload, onSuccess });
  };

  return {
    compareList,
    isInCompare: isInCompare.data,
    compareCount,
    add,
    remove,
  };
};

export default useCompare;
