import { useEffect, useState } from 'react';
import { cn } from '@divlab/divanui';

import Page from '@Components/Page';
import Title from '../Title';
import Map from '../Map';
import Cancellation from '../Cancellation';
import Completion from '../Completion';
import MapPlaceholder from '../MapPlaceholder';
import Footer from '../Footer';
import Header from '../Header';
import Info from '../Info';
import styles from './DeliveryPageDesktop.module.css';

import type { FC } from 'react';
import type { DeliveryPageProps } from '@Pages/PageDeliveryTracking/typings';

const DeliveryPageDesktop: FC<DeliveryPageProps> = (props) => {
  const { className, order, isDeliveryToday, status, points, isLoadingMapError, ...restProps } =
    props;
  const [needsMap, setNeedsMap] = useState(null);
  const mainContent = status === 'pending' || status === 'delivering';
  const canceledContent = status === 'canceled';
  const completedContent = status === 'completed';

  useEffect(() => {
    if (
      points.currentRoute?.length &&
      (status === 'delivering' || (status === 'pending' && isDeliveryToday))
    ) {
      setNeedsMap(true);
    } else {
      setNeedsMap(false);
    }
  }, [isDeliveryToday, points, status]);

  return (
    <Page>
      <div className={cn(styles.page, className)} {...restProps}>
        <Header />

        <div className={styles.container}>
          <Title status={status} />

          {mainContent && (
            <>
              <Info status={status} order={order} points={points} />

              {typeof needsMap === 'boolean' && (
                <>
                  {needsMap ? (
                    <Map
                      className={cn(styles.map)}
                      order={order}
                      points={points}
                      isLoadingMapError={isLoadingMapError}
                    />
                  ) : (
                    <MapPlaceholder className={cn(styles.mapPlaceholder)} order={order} />
                  )}
                </>
              )}
            </>
          )}

          {canceledContent && <Cancellation order={order} />}

          {completedContent && <Completion order={order} />}
        </div>

        <Footer className={styles.footer} />
      </div>
    </Page>
  );
};

export default DeliveryPageDesktop;
