import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useLanguages from '@Hooks/useLanguages';
import useNavigation from '@Navigation/useNavigation';
import Link from '@Navigation/Link';
import LangItem from './elems/LangItem';
import styles from './Languages.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface LanguagesProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onClose?: (e: MouseEvent) => void;
}

const Languages: FC<LanguagesProps> = (props) => {
  const { className, onClose, ...restProps } = props;
  const { pathname, search } = useNavigation();
  const { language, supportedLanguages } = useLanguages();

  return (
    <div {...restProps} className={cn(styles.container, className)}>
      {supportedLanguages.map((lang) => (
        <Link key={lang.id} to={`${pathname}${search}`} language={lang.id} onClick={onClose}>
          <LangItem
            className={styles.item}
            title={`(${lang.code}) ${lang.name}`}
            icon={lang.icon}
            selected={lang.id === language.id}
          />
        </Link>
      ))}
    </div>
  );
};

export default memo(Languages);
