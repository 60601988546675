import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ProductData } from '@Types/Product';
import type { ApiResponse } from '@Types/Api';

export interface Range {
  from: number;
  to: number;
}

export type Parameter = Array<string | number | Range>;

export interface Params {
  parameters: Record<string, Parameter>;
  prices?: Range;
}

export interface MattressesQuizLists {
  categoryList: ProductData[];
  personalList: ProductData[];
}

export async function getMattressesProducts(parameters: Params) {
  const url = '/backend/promo/get-mattresses-products';
  const res = await Api.queryProxi<ApiResponse<MattressesQuizLists>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(parameters),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}
