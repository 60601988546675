import { useStore } from '@kundinos/nanostores/react';

import { welcomePopupStore } from './stores';

/**
 * Use this hook for getting the current properties of welcome popup
 */
export function useWelcomePopup() {
  return useStore(welcomePopupStore);
}
