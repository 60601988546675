import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  // eslint-disable-next-line no-restricted-imports
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import type { Logger } from '@Infrastructure/entities/logger';

interface Settings {
  dsn: string;
  environment: string;
  release: string;
}

export class SentryBrowserLogger implements Logger {
  private _queue: unknown[] = [];

  private _Sentry;

  private _isReady = false;

  private _settings: Settings;

  constructor(settings: Settings) {
    this._settings = settings;
    this.init();
  }

  async init() {
    if (this._isReady) return;
    try {
      this._Sentry = await import('@sentry/react');
      this._Sentry.init({
        ...this._settings,
        integrations: [
          this._Sentry.browserTracingIntegration({
            enableInp: true,
          }),
          this._Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        tracesSampler: (context) => {
          if (context?.request?.url) {
            const url = new URL(context.request.url);

            // Don't sent trace info for health-requests
            if (url.pathname.match(/\/health\/?$/)) return 0;
          }

          return 0.5;
        },
        ignoreErrors: [
          //
          'AbortError: The operation was aborted.',

          // Analytics
          /yaCounter([0-9]{1,}) is not defined/,
          'google_tag_manager',
          'VK is not defined',
          `undefined is not an object (evaluating '__ybro.readability.runDetection')`,
          `null is not an object (evaluating 'google_tag_manager`,
          `Can't find variable: digitalData`,
          `digitalData is not defined`,

          // Errors of loading chunks
          `{"code":"CSS_CHUNK_LOAD_FAILED"`,
          /Loading CSS chunk ([0-9]{1,}) failed/,
          /Loading chunk ([0-9]{1,}) failed/,
        ],
      });

      this._isReady = true;
      while (this._queue.length > 0) {
        const error = this._queue.shift();
        this._Sentry.captureException(error);
      }
    } catch (error) {
      // eslint-disable-next-line autofix/no-console
      console.log('SentryLogger init error', error);
    }

    return this;
  }

  log(error: unknown) {
    if (!this._isReady) return this._queue.push(error);
    this._Sentry.captureException(error);
  }

  error(error: unknown, data?: unknown) {
    if (!this._isReady) return this._queue.push(error);
    this._Sentry.withScope((scope) => {
      scope.setExtras({
        additionalData: data,
      });
      this._Sentry.captureException(error);
    });
  }
}
