import { forwardRef, memo, useCallback } from 'react';

import Form from './Form';

import type { FormProps } from './Form';
import type { FormEvent } from 'react';

export interface FormWithMetricsProps extends FormProps {
  name: string;
}

const FormWithMetrics = forwardRef<HTMLFormElement, FormWithMetricsProps>((props, ref) => {
  const { onFocus, onSubmit, onResponse, onCriticalError, ...restProps } = props;

  const handleFocus = useCallback(
    (e: FormEvent) => {
      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const handleSubmit = useCallback(
    (e: FormEvent, data: unknown) => {
      if (onSubmit) onSubmit(e, data);
    },
    [onSubmit],
  );

  const handleResponse = useCallback(
    (res: unknown, data: unknown) => {
      if (onResponse) onResponse(res, data);
    },
    [onResponse],
  );

  const handleCriticalError = useCallback(
    (err: Error) => {
      if (onCriticalError) onCriticalError(err);
    },
    [onCriticalError],
  );

  return (
    <Form
      {...restProps}
      ref={ref}
      onFocus={handleFocus}
      onSubmit={handleSubmit}
      onResponse={handleResponse}
      onCriticalError={handleCriticalError}
    />
  );
});

FormWithMetrics.displayName = 'FormWithMetrics';

export default memo(FormWithMetrics);
