import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { Comment } from '@Types/Visits';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  visitGuid: string;
  products: Comment[];
}

async function saveComments(params: Params) {
  const url = '/web-crm/api/visit/save-comments';

  const res = await Api.queryProxi<ApiResponse<void>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default saveComments;
