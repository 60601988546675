import carRus from './car.svg';
import carKz from './carKz.svg';
import carKK from './carKK.svg';

import type { CountryCode, LanguageCode } from '@Types/Base';

export const images: Partial<Record<CountryCode, Record<LanguageCode, string>>> = {
  RUS: {
    ru: carRus,
  },
  KAZ: {
    ru: carKz,
    kk: carKK,
  },
};

export default images;
