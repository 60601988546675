import * as Api from '@Api/index';

interface Params {
  body: any;
}

async function sendAnswers({ body }: Params) {
  const url = `/backend/clients/questionnaire-answers`;
  const res = await Api.query<any>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return res;
}

export default sendAnswers;
