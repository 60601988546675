import { memo, useState } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import styles from './IconSocial.module.css';
import zen_default_30 from './icons/zen_default_30.svg';
import zen_hover_30 from './icons/zen_hover_30.svg';
import tiktok_default_30 from './icons/tiktok_default_30.svg';
import tiktok_hover_30 from './icons/tiktok_hover_30.svg';
import vk_default_30 from './icons/vk_default_30.svg';
import vk_hover_30 from './icons/vk_hover_30.svg';
import facebook_default_30 from './icons/facebook_default_30.svg';
import facebook_hover_30 from './icons/facebook_hover_30.svg';
import youtube_default_30 from './icons/youtube_default_30.svg';
import youtube_hover_30 from './icons/youtube_hover_30.svg';
import instagram_default_30 from './icons/instagram_default_30.svg';
import instagram_hover_30 from './icons/instagram_hover_30.svg';
import pinterest_default_30 from './icons/pinterest_default_30.svg';
import pinterest_hover_30 from './icons/pinterest_hover_30.svg';
import telegram_default_30 from './icons/telegram_default_30.svg';
import telegram_hover_30 from './icons/telegram_hover_30.svg';

import type { FC, HTMLAttributes } from 'react';

const icons = {
  zen: { hover: zen_hover_30, default: zen_default_30 },
  tiktok: { hover: tiktok_hover_30, default: tiktok_default_30 },
  vk: { hover: vk_hover_30, default: vk_default_30 },
  facebook: { hover: facebook_hover_30, default: facebook_default_30 },
  youtube: { hover: youtube_hover_30, default: youtube_default_30 },
  instagram: { hover: instagram_hover_30, default: instagram_default_30 },
  pinterest: { hover: pinterest_hover_30, default: pinterest_default_30 },
  telegram: { hover: telegram_hover_30, default: telegram_default_30 },
};

export interface IconSocialProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  type: string;
  link: string;
}

const IconSocial: FC<IconSocialProps> = (props) => {
  const { className, type, link } = props;
  const [isHover, setHover] = useState<boolean>(false);

  return (
    <>
      <Link to={link} className={cn(styles.icon, className)} target='_blank'>
        <Img
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          src={icons[type][isHover ? 'hover' : 'default']}
          className={styles.img}
        />
        {type}
      </Link>
    </>
  );
};

export default memo(IconSocial);
