import * as Api from '@Api/index';

import type { PaymentTypeData } from '@Types/Cart';

export interface Response {
  paymentHint?: string;
  paymentTypes: PaymentTypeData[];
}

async function getPaymentTypes() {
  const res = await Api.queryProxi<Response>('/backend/order/get-payment-types');

  return res;
}

export default getPaymentTypes;
