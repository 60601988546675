import { useRef, useCallback, useState, memo } from 'react';
import { cn } from '@divlab/divanui';
import { IconReset } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import useTranslation from '@Queries/useTranslation';
import Button from '@UI/Button/Button';
import Input from '@UI/Input';
import styles from './Search.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface SearchData extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Search: FC<SearchData> = (props) => {
  const { className } = props;
  const inputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState('');
  const goToSearchResults = SearchStore.useGoToSearchResults();
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    const val = e.target.value;
    setValue(val);
  }, []);

  const handleReset = useCallback(() => {
    setTimeout(() => setValue(''));
    setValue('');
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const term = value || '';

      await SearchStore.search({ term });

      await goToSearchResults(term);

      const input = inputRef.current;
      if (input) {
        input.blur();
      }

      HeaderStore.closeSearch();
    },
    [goToSearchResults, value],
  );

  return (
    <form className={cn(styles.search, className)} action='/' onSubmit={handleSubmit}>
      <Input
        className={cn(styles.input, {
          [styles.hasValue]: !!value,
          [styles.overrider]: true,
        })}
        type='input'
        placeholder={t('ui.404.search')}
        ref={inputRef}
        defaultValue={value}
        clear={false}
        after={
          <div className={styles.inputActions}>
            <div className={styles.icon}>
              <IconReset className={styles.closeIcon} onClick={handleReset} />
            </div>

            <Button className={styles.searchButton} view='rounded' type='submit'>
              {t('ui.find')}
            </Button>
          </div>
        }
        onChange={handleChange}
      />
    </form>
  );
};

export default memo(Search);
