import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { SEOCategories } from '@Types/Category';

async function getSEOCategories() {
  const url = `/backend/data/seo-categories`;
  const res = await Api.queryProxi<ApiResponse<SEOCategories>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getSEOCategories;
