import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CountryWithRegions } from '@Types/Region';
import type { ApiResponse } from '@Types/Api';

async function getRegionPriority() {
  const url = `/backend/region-priority`;
  const opts = { method: 'POST' };
  const res = await Api.queryProxi<ApiResponse<CountryWithRegions[]>>(url, opts);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getRegionPriority;
