import { memo } from 'react';
import { cn, useMedias } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import Img from '@UI/Img';
import logoRus from './images/logoRus.svg';
import logoKaz from './images/logoKaz.svg';
import logoKzMini from './images/logoKzMini.svg';
import logoRuMini from './images/logoRuMini.svg';
import styles from './LogotypeDivanClub.module.css';

import type { HTMLAttributes, FC } from 'react';

export interface LogotypeDivanClubProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const LogotypeDivanClub: FC<LogotypeDivanClubProps> = (props) => {
  const { className, ...restProps } = props;
  const { isRus, isKaz } = useRequest();
  const { isMobileXS } = useMedias();
  const logotype = isRus ? logoRus : logoKaz;
  const logotypeMini = isRus ? logoRuMini : logoKzMini;
  const imgSrc = isMobileXS ? logotypeMini : logotype;

  return (
    <Img
      {...restProps}
      className={cn(
        styles.logotype,
        {
          [styles.logoKaz]: isKaz,
        },
        className,
      )}
      src={imgSrc}
      loading='eager'
      cnImage={styles.logotypeImg}
    />
  );
};

export default memo(LogotypeDivanClub);
