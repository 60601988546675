import { memo } from 'react';
import { Boldik, cn } from '@divlab/divanui';
import { IconChevronRight, IconLocation } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import * as ModalWindows from '@Stores/ModalWindows';
import * as Meta from '@Queries/Meta';
import UILink from '@UI/Link';
import Phone from '@Components/Phone';
import styles from './MenuLeft.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface MenuLeftProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const MenuLeft: FC<MenuLeftProps> = (props) => {
  const { className, ...oldProps } = props;
  const meta = Meta.useMeta();

  const handleClickLocation = () => {
    HeaderStore.closeMenu();
    ModalWindows.open('RegionSelector');
  };

  const handleClickPhone = (e: MouseEvent) => {
    e.preventDefault();
    HeaderStore.closeMenu();
    ModalWindows.open('Feedback');
  };

  return (
    <div {...oldProps} className={cn(styles.mainWrapper, className)}>
      <UILink
        className={styles.location}
        underlined
        onClick={handleClickLocation}
        data-testid='region-name-link'
      >
        <div className={styles.locationIconWrapper}>
          <IconLocation className={styles.locationIcon} />
        </div>

        <Boldik className={styles.locationText}>{meta.data.region.name}</Boldik>
        <IconChevronRight className={styles.locationArrow} />
      </UILink>

      <div onClick={handleClickPhone} className={styles.callback} data-testid='callback'>
        <Phone className={styles.callbackText} phone={meta.data.phones[0]} />
        <IconChevronRight className={styles.callbackArrow} />
      </div>
    </div>
  );
};

export default memo(MenuLeft);
