import { memo } from 'react';
import { cn } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import Link from '@Navigation/Link';
import Img from '@UI/Img';
import logoRus from './logos/FooterLogoRUS.svg';
import logoKaz from './logos/FooterLogoKAZ.svg';
import logoUzb from './logos/FooterLogoUZB.svg';
import styles from './FooterLogo.module.css';

import type { CountryCode } from '@Types/Base';
import type { FC, HTMLAttributes } from 'react';

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}
export interface Branding {
  siteName: string;
  logo: string;
}

const siteData: Partial<Record<CountryCode, Branding>> = {
  RUS: {
    siteName: 'divan.ru',
    logo: logoRus,
  },
  KAZ: {
    siteName: 'mebel.kz',
    logo: logoKaz,
  },
  UZB: {
    siteName: 'divan.uz',
    logo: logoUzb,
  },
};

const FooterLogo: FC<FooterProps> = () => {
  const { country } = useRequest();
  const data = siteData[country];

  return (
    <Link
      className={cn(styles.footerLogo, {
        [styles.logoRus]: country === 'RUS',
        [styles.logoKaz]: country === 'KAZ',
        [styles.logoUzb]: country === 'UZB',
      })}
      to='/'
      aria-label={data.siteName}
    >
      <Img className={styles.image} src={data.logo} />
    </Link>
  );
};

export default memo(FooterLogo);
