import { createDerived, createStore, getValue, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

interface PopupMethods {
  open: (props: PopupData) => void;
  close: (id: string) => void;
  usePopups: () => PopupStore;
  useVisiblePopups: () => PopupData[];
  clear: () => void;
}

export interface PopupData<T = unknown> {
  id: string;
  data?: T;
  visible?: boolean;
}

export interface PopupStore {
  stack: PopupData[];
  timeout: number;
}

const defaultValue: PopupStore = { stack: [], timeout: 400 };

export const popupsStore = createStore<PopupStore>(() => {
  popupsStore.set(defaultValue);
});

export const visiblePopupStore = createDerived(popupsStore, ({ stack }) => {
  return stack.filter((popup) => popup.visible);
});

const methods: PopupMethods = {
  open: (params: PopupData) => {
    update(popupsStore, (prev) => {
      const newStack = prev.stack.filter((popup) => popup.id !== params.id);
      return { ...prev, stack: [...newStack, { ...params, visible: true }] };
    });
  },
  close: async (id: string) => {
    const popups = getValue(popupsStore);

    popupsStore.set({
      ...popups,
      stack: popups.stack.map((popup) => ({
        ...popup,
        visible: popup.id === id ? false : popup.visible,
      })),
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        update(popupsStore, (prev) => ({
          ...prev,
          stack: prev.stack.filter((popup) => popup.id !== id),
        }));

        resolve();
      }, popups.timeout);
    });
  },
  usePopups: (): Readonly<PopupStore> => useStore(popupsStore),
  useVisiblePopups: (): PopupData[] => useStore(visiblePopupStore),
  clear: () => {
    popupsStore.set(defaultValue);
  },
};

export default methods;
