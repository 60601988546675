import type { Query } from '@Types/Base';

export interface Store {
  current: Query;
}

const store: Store = { current: null };

export function setRequest(request: Query): void {
  store.current = {
    ...store.current,
    ...request,
  };
}

export function getRequest(): Query {
  return store.current;
}
