import { useQuery } from '@tanstack/react-query';

import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';

function useTelephony() {
  const { telephony } = useDeps();
  const request = useRequest();
  const region = request.region || request.mainRegion;
  const keys = ['ctPhone', region];

  const { data } = useQuery({
    queryKey: keys,
    queryFn: async () => {
      if (!telephony) return '';

      const phone = await telephony.getPhone(region);

      return phone;
    },
    placeholderData: (previousData) => previousData,
  });

  return data;
}

export default useTelephony;
