import { memo, createElement } from 'react';
import { Helmet } from 'react-helmet-async';

import type { FC } from 'react';
import type { PageMeta } from '@Types/Base';

export interface MetaTagsProps {
  className?: string;
  pageMeta: PageMeta;
}

const MetaTags: FC<MetaTagsProps> = ({ pageMeta }) => {
  return (
    <Helmet
      htmlAttributes={{
        prefix: 'og: https://ogp.me/ns#',
      }}
    >
      {pageMeta?.title && <title>{pageMeta.title}</title>}
      {(pageMeta?.tags || []).map((metaTag, index) => {
        const { textContent, ...attributes } = metaTag.props;
        return createElement(
          metaTag.tag,
          {
            ...attributes,
            key: index,
          },
          textContent,
        );
      })}
    </Helmet>
  );
};

export default memo(MetaTags);
