import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

interface Body {
  'OrderForm[first_name]': string;
  'OrderForm[mobile]': string;
  'OrderForm[email]': string;
  'OrderForm[delivery_type_id]': number;
  'OrderForm[address]': string;
  'OrderForm[payment_type_id]': number;
  'OrderForm[payment_variant]': string;
  'OrderForm[credit]': boolean;
}

interface Response {
  save: boolean;
}

async function saveUserData(body: Body) {
  const url = '/cabinet/cart/save-user-data';
  const formData = new FormData();
  for (const [key, value] of Object.entries(body)) {
    formData.append(key, value);
  }

  const res = await Api.queryProxi<Response>(url, { method: 'POST', body: formData });

  if (!res.save) throw new BackendError(url, res);

  return res;
}

export default saveUserData;
