import { cn } from '@divlab/divanui';

import styles from './Text.module.css';

import type { FC, HtmlHTMLAttributes } from 'react';

interface TextProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  desktop: string;
  mobile?: string;
  isMobile?: boolean;
  isH1?: boolean;
}

const Text: FC<TextProps> = (props) => {
  const { className, desktop, mobile, isMobile, isH1, ...restProps } = props;
  const text = isMobile && typeof mobile === 'string' ? mobile : desktop;
  const tag = isH1 ? (
    <h1
      className={cn(styles.resetMargin, className)}
      dangerouslySetInnerHTML={{ __html: text }}
      {...restProps}
    />
  ) : (
    <div className={className} dangerouslySetInnerHTML={{ __html: text }} {...restProps} />
  );

  return text ? tag : null;
};

export default Text;
