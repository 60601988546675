import * as Api from '@Api/index';

/**
 * Cuts out region slug from a pathname
 */
function cutOutRegionSlug(pathname: string) {
  const { allRegions } = Api.getRequest();

  return (
    pathname
      .split('/')
      .filter((chunk) => !allRegions.includes(chunk))
      .join('/') || '/'
  );
}

export default cutOutRegionSlug;
