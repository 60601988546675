import { useContext } from 'react';

import { FeatureFlagsContext } from '@Contexts/FeatureFlags';

/**
 * Returns the feature-flags that specified available feature or not
 * For example, in Russia we can order textile samples, but in Belarus this feature unavailable
 * and all components for this feature should be hide or disable
 */
export default function useFeatureFlags() {
  const featureFlags = useContext(FeatureFlagsContext);

  return featureFlags;
}
