import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { BannerStructureData } from '@Types/Catalog';
import type { ApiResponse } from '@Types/Api';
import type { InlineBannerData } from '@Promo/typings';

export interface Params {
  slug: string;
  search?: string;
}

export interface InlineBannersResponse {
  banners: InlineBannerData[];
  structure: BannerStructureData;
}

const getInlineBanners = async ({ slug, search = '' }: Params) => {
  const searchParams = new URLSearchParams(search);

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/promotion-banners/get-by-category/${slug}${qs}`;
  const res = await Api.queryProxi<ApiResponse<InlineBannersResponse>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
};

export default getInlineBanners;
