import * as Api from '@Api/index';

export interface RegionItem {
  id: number;
  name: string;
  parent_id: number;
  url: string;
}
export interface Result {
  ok: boolean;
  data: RegionItem[];
}

async function getAllRegions() {
  const url = `/backend/data/regions`;
  const res = await Api.query<Result>(url);

  return res.data;
}

export default getAllRegions;
