import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CountryCode } from '@Types/Base';
import type { ApiResponse, CabinetApiMethods } from '@Types/Api';
import type { GetOrdersResponse } from '@Types/Order';
import type {
  AuthorizationData,
  DesignerRegion,
  LoginParams,
  ProfileBonusHistory,
  ProfileData,
} from '@Types/Profile';
import type { ComplaintData } from '@Types/Complaint';
import type { VisitShoroomData, VisitData } from '@Types/Visits';

interface CabinetApiParameters {
  country: CountryCode;
}

class CabinetApi implements CabinetApiMethods {
  country: CountryCode;

  constructor(params: CabinetApiParameters) {
    const { country } = params;
    this.country = country;
  }

  query = async <T>(method: string, opts?: RequestInit) => {
    const [path, search = ''] = method.split('?');
    const newSearch = new URLSearchParams(search);
    const url = `${path}?${newSearch.toString()}`;

    const res = await Api.queryProxi<ApiResponse<T>>(url, { ...opts });

    if (!res.ok) throw new BackendError(url.toString(), res);

    return res.data;
  };

  fetchBonusHistory = async () => {
    return this.query<ProfileBonusHistory>(`/cabinet/get-bonus-history`);
  };

  fetchOrders = async (params?: { page: number | string; search?: string }) => {
    const { page, search = '' } = params || {};
    const pageWithDefault = Number(page || 1);
    const searchParams = new URLSearchParams(search);

    if (page) {
      searchParams.set('page', pageWithDefault.toString());
    }

    const searchString = searchParams.toString();
    const qs = searchString ? `?${searchString}` : searchString;
    const url = `/cabinet/get-orders${qs}`;

    return this.query<GetOrdersResponse>(url);
  };

  fetchComplaints = async () => {
    return this.query<ComplaintData[]>('/cabinet/get-complaints');
  };

  fetchVisit(params: { visitId: string }) {
    const searchParams = new URLSearchParams({ visitId: params.visitId });
    const searchString = searchParams.toString();
    const qs = searchString ? `?${searchString}` : searchString;

    return this.query<ComplaintData[]>(`/cabinet/get-visit${qs}`);
  }

  fetchVisits() {
    return this.query<ComplaintData[]>('/cabinet/get-visits');
  }

  fetchProfile = async () => {
    return this.query<ProfileData>(`/cabinet/get-current-profile`);
  };

  logout = async () => {
    return this.query<boolean>('/cabinet/logout', { method: 'POST' });
  };

  login = async (params?: LoginParams) => {
    const { type = 'default', token } = params || {};
    let body: BodyInit;

    try {
      body = JSON.stringify({ type, token });
    } catch {
      body = null;
    }

    return this.query<AuthorizationData>('/cabinet/login', {
      method: 'POST',
      body,
    });
  };

  resendPhoneChangeCode = async () => {
    return this.query<boolean>('/cabinet/resend-phone-change-code');
  };

  fetchDesignerRegions = async () => {
    return this.query<DesignerRegion[]>('/cabinet/get-regions');
  };

  fetchCRMVisits = async (phone: string) => {
    return this.query<VisitData[]>(`/web-crm/api/visits/${phone}`);
  };

  fetchCRMVisit = async (slug: string) => {
    return this.query<VisitShoroomData>(`/web-crm/api/visit/share/${slug}`);
  };
}

export default CabinetApi;
