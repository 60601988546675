import { useEffect, useState } from 'react';

export type UseRenderType = () => {
  /** Is Server Side Render? */
  isSSR: boolean;

  /** Is Client Side Render? */
  isCSR: boolean;
};

/**
 * Returns the type of render (Server Side or Client Side)
 */
const useRenderType: UseRenderType = () => {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  return { isSSR, isCSR: !isSSR };
};

export default useRenderType;
