import { useRequest } from '@Contexts/Request';

export const useUpdateProfileData = () => {
  const { queryClient } = useRequest();

  const updateData = async () => {
    await queryClient.invalidateQueries({ queryKey: ['profile'], refetchType: 'all', type: 'all' });
    queryClient.invalidateQueries({
      queryKey: ['infiniteFavorites'],
      refetchType: 'all',
      type: 'all',
    });
    queryClient.invalidateQueries({ queryKey: ['compare'], refetchType: 'all', type: 'all' });
    queryClient.invalidateQueries({
      queryKey: ['cartShortInfo'],
      refetchType: 'all',
      type: 'all',
    });
    queryClient.invalidateQueries({
      queryKey: ['cart'],
      refetchType: 'all',
      type: 'all',
    });
  };

  return updateData;
};

export default useUpdateProfileData;
