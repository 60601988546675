import { cn } from '@divlab/divanui';
import { IconCircleChevronLeft } from '@divlab/divanui/icons';

import useTranslation from '@Queries/useTranslation';
import Link from '@Navigation/Link';
import styles from './Back.module.css';

import type { FC } from 'react';
import type { LinkProps } from '@Navigation/Link';

const Back: FC<LinkProps> = (props) => {
  const { className, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <Link className={cn(styles.back, className)} to='/' {...restProps}>
      <IconCircleChevronLeft className={styles.icon} />
      {t('back')}
    </Link>
  );
};

export default Back;
