import { useInfiniteQuery } from '@tanstack/react-query';

import { useDeps } from '@Contexts/DI';
import * as Profile from '@Queries/Profile';
import { useRequest } from '@Contexts/Request';
import useNavigation from '@Navigation/useNavigation';

import type { GetOrdersResponse } from '@Types/Order';

interface InfiniteOrdersParams {
  page: number;
}

const defaultFilterOptions = [
  ['status', 'active'],
  ['agentOrder', 'all'],
];

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
export const useInfiniteOrders = (params?: InfiniteOrdersParams) => {
  const { page = 1 } = params || {};
  const profile = Profile.useProfile();
  const { queryClient } = useRequest();
  const { search } = useNavigation();
  const { cabinetApi } = useDeps();

  const filterOptions = new URLSearchParams(
    search
      ? Array.from(new URLSearchParams(search).entries()).filter((param) =>
          ['status', 'agentOrder'].includes(param[0]),
        )
      : defaultFilterOptions,
  ).toString();

  const keys = ['infiniteOrders', page, filterOptions, { mindboxId: profile.data?.mindboxId }];

  const query = useInfiniteQuery({
    queryKey: keys,
    queryFn: ({ pageParam }) => {
      const cache = queryClient.getQueryData<Record<string, GetOrdersResponse[]>>(keys);

      if (cache && !pageParam) return Promise.resolve(cache?.pages[0] as GetOrdersResponse);

      return cabinetApi.fetchOrders({
        page: pageParam || page,
        search: filterOptions,
      });
    },
    initialPageParam: 0,
    enabled: true,
    placeholderData: (previousData) => previousData,
    getNextPageParam: (lastPage) => {
      if (!lastPage) return null;

      const nextPageParam = lastPage.page + 1;

      return nextPageParam > lastPage.pageTotal ? null : nextPageParam;
    },
  });

  return query;
};
