import { memo } from 'react';

import FooterNav from '@Layouts/LayoutMain/elems/Footer/common/FooterNav';
import Accordion from '@Layouts/LayoutMain/elems/Footer/common/Accordion';
import FooterInfoBlock from '@Layouts/LayoutMain/elems/Footer/common/FooterInfoBlock';
import FooterRequisites from '../FooterRequisites';
import PaymentSystems from '../PaymentSystems';
import styles from './FooterDesktop.module.css';

import type { FooterData } from '@Types/Layout';
import type { FC } from 'react';

export interface FooterDesktopProps {
  footer: FooterData;
}

const FooterDesktop: FC<FooterDesktopProps> = (props) => {
  const { footer } = props;

  return (
    <div className={styles.grid}>
      <div>
        {footer.contacts?.items.length > 0 && (
          <Accordion header={footer.contacts.title}>
            <FooterNav items={footer.contacts.items} />
          </Accordion>
        )}
      </div>

      <div>
        {footer.callSchedule?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <FooterInfoBlock title={footer.callSchedule.title}>
              <FooterNav items={footer.callSchedule.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.deliverySchedule?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <FooterInfoBlock title={footer.deliverySchedule.title}>
              <FooterNav items={footer.deliverySchedule.items} />
            </FooterInfoBlock>
          </div>
        )}
      </div>

      <div>
        {footer.toCustomers?.items.length > 0 && (
          <Accordion header={footer.toCustomers.title}>
            <FooterNav items={footer.toCustomers.items} />
          </Accordion>
        )}
      </div>

      <div>
        {footer.catalog?.items.length > 0 && (
          <Accordion defaultCollapsed header={footer.catalog.title}>
            <FooterNav items={footer.catalog.items} />
          </Accordion>
        )}
      </div>

      <div>
        {footer.payments?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <FooterInfoBlock title={footer.payments.title}>
              <PaymentSystems items={footer.payments.items} />
            </FooterInfoBlock>
          </div>
        )}
        {footer.requisites?.length > 0 && <FooterRequisites requisites={footer.requisites} />}
      </div>
    </div>
  );
};

export default memo(FooterDesktop);
