import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import { useRequest } from '@Contexts/Request';

import type { MetaData } from '@Types/Meta';
import type { UseQueryResult } from '@tanstack/react-query';

function useMeta(): UseQueryResult<MetaData> {
  const { region, secondaryRegion, language } = useRequest();

  const keys = [
    'meta',
    {
      region,
      secondaryRegion,
      lang: language.id,
    },
  ];
  const localPlaceholderData: MetaData = {
    currency: null,
    currencyRate: null,
    instagram: {
      name: '',
      link: '',
    },
    phones: [],
    region: {
      code: '',
      id: null,
      url: '',
      name: '',
      isPec: null,
      isFranchise: null,
      autoName: '',
      autoId: null,
      autoUrl: '',
    },
  };

  const query = useQuery({
    queryKey: keys,
    queryFn: () => {
      return ApiMeta.fetch();
    },
    enabled: true,
    placeholderData: (prev) => prev || localPlaceholderData,
  });

  return query;
}

export default useMeta;
