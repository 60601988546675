import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { RegionHintData } from '@Types/Region';

export interface Result {
  ok: boolean;
  data: RegionHintData[];
}

async function getAllRegions(): Promise<RegionHintData[]> {
  const url = `/backend/data/regions`;
  const res = await Api.queryProxi<Result>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getAllRegions;
