import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { FavoritesData } from '@Types/Compare';

export interface ResultData {
  products: FavoritesData[];
}

export interface Result {
  ok: 'success' | 'error';
  data: ResultData;
}

async function getCompareProducts() {
  //TODO: перевести на единый механизм после переезда Кабинета на php
  const { language, defaultLanguage } = Api.getRequest();
  const lang = language.id === defaultLanguage ? '' : `/${language.slug}`;
  const url = `/cabinet${lang}/compare/v2`;
  const res = await Api.queryProxi<Result>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getCompareProducts;
