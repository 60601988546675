import * as Api from '@Api/index';

interface Params {
  body: any;
}

function buildFormData(formData: FormData, data: any, parentKey?: string) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else if (parentKey) {
    if (data instanceof File) {
      return formData.append(parentKey, data);
    }
    const value = data === null ? '' : String(data);

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data: unknown) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

async function saveFiles({ body }: Params) {
  const url = `/backend/clients/save-files`;
  const data = jsonToFormData(body);
  const res = await Api.query<any>(url, {
    method: 'POST',
    body: data,
  });

  return res;
}

export default saveFiles;
