import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface Params {
  productId: number;
  searchParams?: string;
}

async function getInstallmentVariants(params: Params) {
  const { productId, searchParams } = params;
  const newSearchParams = new URLSearchParams();

  newSearchParams.set('id', productId.toString());
  const qs = searchParams
    ? `${newSearchParams.toString()}&${searchParams}`
    : newSearchParams.toString();

  const url = `/backend/order/installment-available?${qs}`;
  const res = await Api.queryProxi<any>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getInstallmentVariants;
