export default (params = {}) => {
  return {
    description: 'Ссылка на соц. сеть',
    type: 'string',
    maxLength: 255,
    errorMessage: {
      required: 'Это обязательное поле',
      minLength: 'Заполните одно из полей',
      maxLength: 'Ссылка на соц. сеть не может быть длиннее 255 символов',
    },
    ...params,
  };
};
