import { update } from '@kundinos/nanostores';

import { authSettingsStore } from '@Queries/Profile/stores';

import type { ProfileSettingsStore } from '@Queries/Profile/typings';

/**
 * Use this method for updating any settings
 * @param settings — the object with parameters, that need to update
 */
export function updateSettings(settings?: Partial<ProfileSettingsStore>): void {
  update(authSettingsStore, (prevValue) => ({ ...prevValue, ...settings }));
}
