import { cn } from '@divlab/divanui';

import styles from './Geolocation.module.css';
import icon from './icon.svg';

import type { ButtonHTMLAttributes, FC } from 'react';

interface GeolocationProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onGetGeolocation?(coords: [number, number]): void;
}

const Geolocation: FC<GeolocationProps> = (props) => {
  const { className, onGetGeolocation, ...restProps } = props;

  const handleGetGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        onGetGeolocation([position.coords.latitude, position.coords.longitude]);
      });
    }
  };

  return (
    <button className={cn(styles.button, className)} onClick={handleGetGeolocation} {...restProps}>
      <img className={styles.icon} src={icon} />
    </button>
  );
};

export default Geolocation;
