const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

const weekDays = [
  'воскресение',
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота',
];

// 24 марта 2024 (воскресенье) с 13:00 до 17:00
const formatDate = (newDate: string, from: number, to: number) => {
  const date = new Date(newDate);

  if (isNaN(date.getDate())) return '';

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()} (${
    weekDays[date.getDay()]
  }) с ${from}:00 до ${to}:00`;
};

export default formatDate;
