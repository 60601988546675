import { memo, useEffect } from 'react';
import { cn } from '@divlab/divanui';

import ErrorInfo from '@Components/ErrorInfo';
import { useDeps } from '@Contexts/DI';
import { useRequest } from '@Contexts/Request';
import styles from './PageError.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onlyInfo?: boolean;
  message?: string;
}

const PageError: FC<Props> = (props) => {
  const { className, message, ...restProps } = props;
  const { status } = useRequest();
  const { analytics, logger } = useDeps();

  logger.error(status.message || message || 'Unknown error');

  useEffect(() => {
    setTimeout(() => {
      analytics.dispatchEvent('page.error500');
    });
  }, [analytics]);

  return (
    <div {...restProps} className={cn(styles.pageError, className)} data-testid='page-error'>
      <ErrorInfo code={500} />
    </div>
  );
};

export default memo(PageError);
