import { memo, useEffect, useMemo, useRef, useState } from 'react';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import styles from './Section.module.css';

import type { CSSProperties, FC, MouseEvent } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface SectionProps {
  className?: string;
  section: MainMenuItem;
  isMain: boolean;
  onClickLink?: (e: MouseEvent, title: string) => void;
}

const Section: FC<SectionProps> = (props) => {
  const { section, isMain, onClickLink, ...restProps } = props;
  const refList = useRef<HTMLDivElement>();
  const [containerHeight, setHeight] = useState<number>(null);

  const style = useMemo<CSSProperties>(() => {
    if (!containerHeight) return null;

    return {
      height: `${containerHeight}px`,
      columnFill: isMain ? 'balance' : 'auto',
    };
  }, [containerHeight, isMain]);

  useEffect(() => {
    if (!refList.current) return;

    const { height } = refList.current.getBoundingClientRect();
    setHeight(height);

    return () => setHeight(null);
  }, []);

  return (
    <div {...restProps} className={styles.section}>
      <Link
        className={styles.sectionTitle}
        to={section.url}
        style={{ backgroundColor: section.parameters?.backgroundColor }}
      >
        <Img className={styles.sectionImage} src={section.icon?.url} />
        <Link
          view='primary'
          className={styles.title}
          animated={!!section.url}
          style={{ backgroundColor: section.parameters?.backgroundColor }}
          onClick={(e) => onClickLink(e, section.name)}
        >
          {section.name}
        </Link>
      </Link>
      <div className={styles.sectionSublist} ref={refList} style={style}>
        {section.children.map((link) => {
          return (
            <Link
              key={link.id}
              className={styles.link}
              to={link.url}
              onClick={(e) => onClickLink(e, `${section.name}/${link.name}`)}
            >
              {link.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Section);
