import { memo, useMemo } from 'react';

import styles from './Highlight.module.css';

import type { FC } from 'react';

export interface HighlightProps {
  search: string;
  text: string;
}

const Highlight: FC<HighlightProps> = (props) => {
  const { search, text } = props;

  const startIndex = useMemo(() => text.indexOf(search), [text, search]);
  const before = useMemo(() => text.slice(0, startIndex), [text, startIndex]);
  const after = useMemo(() => text.slice(startIndex + search.length), [text, search, startIndex]);

  return startIndex === -1 ? (
    <>{text}</>
  ) : (
    <>
      {before}
      <span className={styles.highlight}>{search}</span>
      {after}
    </>
  );
};

export default memo(Highlight);
