import { memo, useCallback } from 'react';
import { cn, Gallery } from '@divlab/divanui';

import * as SearchStore from '@Stores/Search';
import styles from './TipList.module.css';

import type { TipData } from '@Types/Search';
import type { MouseEvent, FC, HTMLAttributes } from 'react';

export interface TipsListProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  tips: TipData[];
  onTapClick?: (e: MouseEvent, value: TipData) => void;
}

const TipList: FC<TipsListProps> = (props) => {
  const { className, tips, onTapClick, ...restProps } = props;

  const handleChangeRequest = useCallback(
    (e: MouseEvent, value: TipData) => {
      if (onTapClick) {
        onTapClick(e, value);
      }
      SearchStore.search({ term: value.relatedSearch });
    },
    [onTapClick],
  );

  return (
    <div {...restProps} className={cn(styles.tips, className)}>
      <Gallery className={styles.tipsGallery}>
        {tips.map((tip) => (
          <div key={tip.tap} className={styles.tipsBlock}>
            <div className={styles.tipsName} onClick={(e) => handleChangeRequest(e, tip)}>
              {tip.tap}
            </div>
          </div>
        ))}
      </Gallery>
    </div>
  );
};

export default memo(TipList);
