import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { WikiArticleData } from '@Types/Wiki';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  page: number;
  categoryId?: number | string;
  tagId?: number | string;
}

export interface Response {
  page: number;
  articles: WikiArticleData[];
  countLeft: number;
}

async function getWikiArticles({ page, categoryId, tagId }: Params) {
  const searchParams = new URLSearchParams({ page: String(page) });

  if (categoryId) searchParams.set('categoryId', categoryId.toString());
  if (tagId) searchParams.set('tagId', tagId.toString());

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/idei-i-trendy/get-articles${qs}`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getWikiArticles;
