import { useState } from 'react';

import RequestContext from './RequestContext';

import type { FC, PropsWithChildren } from 'react';
import type { RequestState } from './RequestContext';

export interface RequestProviderProps {
  value: RequestState;
}

const RequestProvider: FC<PropsWithChildren<RequestProviderProps>> = (props) => {
  const { value, children } = props;
  const [innerValue, updateRequest] = useState(value);

  return (
    <RequestContext.Provider value={{ ...innerValue, updateRequest }}>
      {children}
    </RequestContext.Provider>
  );
};

export default RequestProvider;
