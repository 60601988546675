import { useCallback } from 'react';

import useMedias from '@Hooks/useMedias';
import FooterDesktop from './elems/FooterDesktop';
import FooterTablet from './elems/FooterTablet';
import FooterMobileS from './elems/FooterMobileS';
import FooterMobile from './elems/FooterMobile';
import Copyright from './elems/Copyright';
import styles from './FooterRus.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { FooterData } from '@Types/Layout';

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  footer: FooterData;
}

const FooterRus: FC<FooterProps> = (props) => {
  const { footer } = props;
  const { isDesktop, isMobile, isMobileS } = useMedias();

  const renderContent = useCallback(() => {
    if (isMobileS) {
      return <FooterMobileS footer={footer} />;
    }

    if (isMobile) {
      return <FooterMobile footer={footer} />;
    }

    if (isDesktop) {
      return <FooterTablet footer={footer} />;
    }

    return <FooterDesktop footer={footer} />;
  }, [footer, isMobileS, isMobile, isDesktop]);

  return (
    <div className={styles.footer}>
      <div className={styles.content}>{renderContent()}</div>
      {footer.copyright && (
        <div className={styles.container}>
          <Copyright
            className={styles.copyright}
            textes={footer.copyright}
            recommendationTechnologies={footer.recommendationTechnologies}
          />
        </div>
      )}
    </div>
  );
};

export default FooterRus;
