import { useState } from 'react';
import { cn, useMedias } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import Logotype from './elements/Logotype';
import styles from './LogoWillFindYours.module.css';

import type { HTMLAttributes, FC } from 'react';
import type { CountryCode } from '@Types/Base';

export interface LogoWillFindYoursProps extends HTMLAttributes<HTMLDivElement> {
  country: CountryCode;
}

const LogoWillFindYours: FC<LogoWillFindYoursProps> = (props) => {
  const { country, className, ...restProps } = props;
  const [hovered, setHovered] = useState(false);
  const isBlr = country === 'BLR';
  const { isMobileM } = useMedias();
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleAnimationEnd = () => {
    setHovered(false);
  };

  return (
    <div
      {...restProps}
      className={cn(styles.mainContainer, { [styles.blrContainer]: isBlr }, className)}
      onMouseEnter={handleMouseEnter}
    >
      <div
        className={cn(styles.logoContainer, {
          [styles.logoBlrContainer]: isBlr,
          [styles.opacityAnimate]: hovered,
        })}
      >
        <Logotype country={country} className={styles.logo} />
        <div
          onAnimationEnd={handleAnimationEnd}
          className={cn(styles.background, {
            [styles.backgroundBlr]: isBlr,
            [styles.animate]: hovered,
          })}
        ></div>
      </div>
      {!isMobileM && (
        <div className={styles.descriptor}>
          {t('ui.logo.descriptor.1')}
          <br />
          {t('ui.logo.descriptor.2')}
        </div>
      )}
    </div>
  );
};

export default LogoWillFindYours;
