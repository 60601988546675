import { useCallback } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import SocialList from '@Components/SocialList';
import FooterMobile from './elems/FooterMobile';
import FooterDesktop from './elems/FooterDesktop';
import FooterTablet from './elems/FooterTablet';
import FooterMobileS from './elems/FooterMobileS';
import FooterLogo from './elems/FooterLogo';
import styles from './FooterBlr.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { FooterData } from '@Types/Layout';

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  footer: FooterData;
}

const FooterBlr: FC<FooterProps> = (props) => {
  const { footer } = props;
  const { isDesktop, isMobile, isMobileS } = useMedias();

  const renderContent = useCallback(() => {
    if (isMobileS) {
      return <FooterMobileS footer={footer} />;
    }

    if (isMobile) {
      return <FooterMobile footer={footer} />;
    }

    if (isDesktop) {
      return <FooterTablet footer={footer} />;
    }

    return <FooterDesktop footer={footer} />;
  }, [isMobileS, isMobile, isDesktop, footer]);

  return (
    <div className={styles.footer}>
      <div className={styles.content}>{renderContent()}</div>
      <div className={styles.container}>
        <FooterLogo className={styles.logo} />
        {footer.socials?.items.length > 0 && (
          <SocialList className={styles.socials} items={footer.socials.items} />
        )}
        <div
          className={cn(styles.copyright, {
            [styles.wideСopyright]: footer.socials?.items.length < 1,
          })}
        >
          {footer.copyright.map((p) => p)}
        </div>
      </div>
    </div>
  );
};

export default FooterBlr;
