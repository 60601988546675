import DefaultPlacemark from './elems/DefaultPlacemark';
import ClientPlacemark from './elems/ClientPlacemark';
import WarehousePlacemark from './elems/WarehousePlacemark';
import CourierPlacemark from './elems/CourierPlacemark';

import type { FC } from 'react';
import type { Point } from '@Types/DeliveryTracking';

type CustomPlacemarkProps = Default | Client | Warehouse | Courier;

type Default = {
  type: 'default';
  point: Point;
};

type Client = {
  type: 'client';
  point: Point;
  timeFrom: number;
  timeTo: number;
};

type Warehouse = {
  type: 'warehouse';
  point: Point;
};

type Courier = {
  type: 'courier';
  point: Point;
};

const CustomPlacemark: FC<CustomPlacemarkProps> = (props) => {
  const { type } = props;

  if (type === 'default') return <DefaultPlacemark point={props.point} />;

  if (type === 'client')
    return <ClientPlacemark point={props.point} timeFrom={props.timeFrom} timeTo={props.timeTo} />;

  if (type === 'warehouse') return <WarehousePlacemark point={props.point} />;

  if (type === 'courier') return <CourierPlacemark point={props.point} />;

  return null;
};

export default CustomPlacemark;
