import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { OrderFabricsData } from '@Stores/OrderFabrics';

export interface Params {
  tag?: 'smart';
}

export interface Result {
  ok: boolean;
  data: OrderFabricsData;
}

async function getFabricSamples({ tag }: Params): Promise<OrderFabricsData> {
  const searchParams = new URLSearchParams();

  if (tag) searchParams.set('tag', tag);

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/site/fabric-samples${qs}`;
  const res = await Api.queryProxi<Result>(url);

  if (!res.ok) throw new BackendError(url, res);
  return res.data;
}

export default getFabricSamples;
