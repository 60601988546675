import useTranslation from '@Queries/useTranslation';
import Page from '@Components/Page';
import Header from '../Header';
import Footer from '../Footer';
import Title from '../Title';
import styles from './PageError.module.css';
import FeedbackPhone from './elems/FeedbackPhone';

import type { FC } from 'react';

const PageError: FC = () => {
  const { t } = useTranslation();
  const info = t('error.info');
  const callCenter = t('error.call-center');

  return (
    <Page>
      <div className={styles.page}>
        <Header />

        <div className={styles.content}>
          <Title status='pending' />
          <div className={styles.image} />
          <div className={styles.info}>{info}</div>
          <div className={styles.callCenter}>
            {callCenter} <FeedbackPhone />
          </div>
        </div>

        <Footer className={styles.footer} />
      </div>
    </Page>
  );
};

export default PageError;
