import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import styles from './EmptyResponse.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface EmptyResponseProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  request: string | number;
}

const EmptyResponse: FC<EmptyResponseProps> = (props) => {
  const { className, request, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <div {...restProps} className={cn(styles.title, className)}>
      {`${t('ui.search-popup.empty-results.1')} «${request}» ${t(
        'ui.search-popup.empty-results.2',
      )}`}
    </div>
  );
};

export default memo(EmptyResponse);
