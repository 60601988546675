export default (params = {}) => {
  return {
    description: 'Образование в области дизайна',
    type: 'string',
    maxLength: 255,
    errorMessage: {
      required: 'Это обязательное поле',
      maxLength: 'Поле не может быть длиннее 255 символов',
    },
    ...params,
  };
};
