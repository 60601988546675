import type { EventListener } from './EventListener';
import type { Logger } from '@Infrastructure/entities/logger';

export class Manager {
  private listeners: EventListener[] = [];

  private logger: Logger;

  constructor(params: { listeners: EventListener[]; logger: Logger }) {
    this.logger = params.logger;
    this.listeners = params.listeners;
  }

  dispatch = (eventName, data) =>
    ((eName, eData) => {
      this.listeners.forEach((listener) => {
        try {
          listener.dispatch(eName, eData);
        } catch (e) {
          this.logger.error(e);
        }
      });
    })(eventName, data);
}
