import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { DirectCredtiStatus } from '@Pages/PageOrderStatus';

export interface DirectCreditStatusData {
  status: DirectCredtiStatus;
}

async function getDirectCreditStatus(id: string) {
  const searchParams = new URLSearchParams();

  searchParams.set('uuid', id.toString());

  const url = `/backend/directcredit/default/status?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<DirectCreditStatusData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getDirectCreditStatus;
