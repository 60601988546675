import { prefetchPage, prefetchTranslation } from '@Navigation/prefetchers';
import * as Api from '@Api/index';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request } = args;
  const { secondaryRegion, region } = Api.getRequest();
  const key = region && region === 'other' && secondaryRegion ? secondaryRegion : '';

  return Promise.all([
    prefetchPage(request.url, queryClient, [key]),
    prefetchTranslation(request.url, queryClient),
  ]);
};
