import { useEffect } from 'react';
import { createStore, getValue } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';
import equal from 'fast-deep-equal';

import type { QueryVariant, Matches } from './typings';

const matchesStore = createStore<Matches>(() => {
  matchesStore.set({});
});

export function updateMedias(newMatches: Matches) {
  const prevMatches = getValue(matchesStore);

  if (equal(prevMatches, newMatches)) return;

  matchesStore.set(newMatches);
}

export function matchMedia(query: QueryVariant): MediaQueryList {
  const List = {
    '--desktop-l': '(min-width: 1901px)',
    '--desktop-m': '(max-width: 1900px)',
    '--desktop': '(max-width: 1365px)',
    '--mobile-m': '(max-width: 1023px)',
    '--mobile': '(max-width: 767px)',
    '--mobile-s': '(max-width: 639px)',
    '--mobile-xs': '(max-width: 519px)',
    '--mobile-xxs': '(max-width: 359px)',
    '--only-desktop': '(min-width: 1366px)',
    '--only-mobile': '(max-width: 1365px)',
  };

  return window.matchMedia(List[query] || query);
}

export function useMatches() {
  return useStore(matchesStore);
}

export default function useMedias() {
  const matches = useMatches();

  useEffect(() => {
    function handleResize() {
      updateMedias({
        isDesktopL: matchMedia('--desktop-l').matches,
        isDesktopM: matchMedia('--desktop-m').matches,
        isDesktop: matchMedia('--desktop').matches,
        isMobileM: matchMedia('--mobile-m').matches,
        isMobile: matchMedia('--mobile').matches,
        isMobileS: matchMedia('--mobile-s').matches,
        isMobileXS: matchMedia('--mobile-xs').matches,
        isMobileXXS: matchMedia('--mobile-xxs').matches,
        isOnlyDesktop: matchMedia('--only-desktop').matches,
        isOnlyMobile: matchMedia('--only-mobile').matches,
      });
    }

    function cleanup() {
      window.removeEventListener('resize', handleResize);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return cleanup;
  }, []);

  return matches;
}

export function getMedias() {
  return getValue(matchesStore);
}
