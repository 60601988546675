import { useCallback, useEffect, useRef } from 'react';

import { useVisibleModals } from '@Stores/ModalWindows';

export interface Position {
  x: number;
  y: number;
}

export interface Positions {
  previous: Position;
  current: Position;
}

export type Callback = (positions: Positions) => void;

const useScrollPosition = (callback: Callback): void => {
  const previous = useRef({ x: 0, y: 0 });
  const visibleModals = useVisibleModals();

  const handleScroll = useCallback(() => {
    //если открыта модалка тогда не меняем позицию скролла.
    if (visibleModals.length) {
      return;
    }

    const current = { x: window.scrollX, y: window.scrollY };
    callback({ previous: previous.current, current });

    previous.current = current;
  }, [callback, visibleModals]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });
};

export default useScrollPosition;
