import { useYMaps, Placemark } from '@pbe/react-yandex-maps';

import styles from './DefaultPlacemark.module.css';
import images from './images';

import type { FC } from 'react';
import type { Point } from '@Types/DeliveryTracking';

interface DefaultPlacemarkProps {
  point: Point;
}

const DefaultPlacemark: FC<DefaultPlacemarkProps> = (props) => {
  const { point } = props;
  const ymaps = useYMaps(['templateLayoutFactory']);

  const Layout = ymaps?.templateLayoutFactory.createClass(
    `<img class='${styles.placemark}' src=${
      point.completed ? images.blackPoint : images.grayPoint
    } />`,
  );

  return (
    <Placemark
      defaultGeometry={point.coordinates}
      options={{
        iconLayout: Layout,
        zIndex: 10000,
        zIndexHover: 10000,
        cursor: 'default',
      }}
    />
  );
};

export default DefaultPlacemark;
