import { loader as pageOrderCheckOldLoader } from '@Pages/PageOrderCheck/PageOrderCheckOld/loader';
import { loader as pageOrderCheckRedesignLoader } from '@Pages/PageOrderCheck/PageOrderCheckRedesign/loader';

export const loader = (params) => {
  if (params.country === 'RUS') {
    return pageOrderCheckRedesignLoader(params);
  }
  return pageOrderCheckOldLoader(params);
};

export default loader;
