import { createDerived, createStore, getValue, keepActive, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';
import equal from 'fast-deep-equal';
import { useEffect } from 'react';

import { useCart } from '@Queries/Cart';

import type { DeliveryTypeData, PaymentTypeData, PaymentVariantData, CartData } from '@Types/Cart';

export interface SelectedIds {
  delivery: DeliveryTypeData['id'];
  paymentType: PaymentTypeData['id'];
  paymentVariant: PaymentVariantData['id'];
  discountVariant: string;
  assembly: boolean;
  disposal: boolean;
  privacyAgree: boolean;
  wantBonuses: boolean;
  promocodeValue: string;
}

const orderFormStore = createStore<CartData>();
const selectedStore = createStore<SelectedIds>();

const selectedDeliveryStore = createDerived([orderFormStore, selectedStore], (form, selected) => {
  return form.deliveryTypes.find((dt) => dt.id === selected.delivery);
});

const visiblePaymentTypesStore = createDerived(
  [orderFormStore, selectedDeliveryStore],
  (form, selectedDelivery) => {
    return form.paymentTypes
      .filter((paymentType) => {
        return Boolean(
          selectedDelivery.paymentTypesIds.find((objId) => objId.id === paymentType.id),
        );
      })
      .filter((paymentType) => paymentType.visible);
  },
);

const formLoadingStore = createDerived([orderFormStore], (form) => {
  return form.isLoading;
});

const selectedPaymentTypeStore = createDerived(
  [orderFormStore, selectedStore],
  (form, selected) => {
    return form.paymentTypes.find((pt) => pt.id === selected.paymentType);
  },
);

const selectedAssemblyStore = createDerived(selectedStore, (selected) => {
  return !!selected.assembly;
});

const selectedDisposalStore = createDerived(selectedStore, (selected) => {
  return !!selected.disposal;
});

const selectedWantBonusesStore = createDerived(selectedStore, (selected) => {
  return selected.wantBonuses ?? true;
});

const selectedPrivacyStore = createDerived(selectedStore, (selected) => {
  return !!selected.privacyAgree;
});

const promocodeValueStore = createDerived(selectedStore, (selected) => {
  return selected.promocodeValue;
});

const availablePaymentVariantsStore = createDerived(
  [orderFormStore, selectedDeliveryStore, selectedPaymentTypeStore],
  (form, selectedDelivery, selectedPaymentType) => {
    if (!selectedPaymentType) return [];

    return form.paymentVariants.filter((paymentVariant) => {
      const founded = selectedDelivery.paymentTypesIds.find(
        (pt) => pt.id === selectedPaymentType.id,
      );

      return founded && founded.variants.includes(paymentVariant.id);
    });
  },
);

const selectedPaymentVariantStore = createDerived(
  [availablePaymentVariantsStore, selectedStore],
  (paymentVariants, selected) => {
    return paymentVariants.find((pv) => pv.id === selected?.paymentVariant);
  },
);

const updateDelivery = (id: DeliveryTypeData['id'], newData: Partial<DeliveryTypeData>): void => {
  const form = getValue(orderFormStore);

  orderFormStore.set({
    ...form,
    deliveryTypes: form.deliveryTypes.map((dt) => {
      if (dt.id !== id) return dt;

      return { ...dt, ...newData };
    }),
  });
};

const select = (ids: Partial<SelectedIds>): void => {
  update(selectedStore, (prevValue) => ({ ...prevValue, ...ids }));
};

const setFormLoading = (isLoading: boolean): void => {
  update(formLoadingStore, () => {
    const isFormLoading = isLoading;
    return isFormLoading;
  });
};

const init = (initialValue: CartData): void => {
  const value = getValue(orderFormStore);

  if (initialValue && !equal(initialValue, value)) {
    orderFormStore.set(initialValue);

    // Способ доставки
    select({ delivery: initialValue.deliveryTypes[0].id });

    // Тип оплаты
    update(selectedStore, (prevValue) => {
      const includesCurrentPaymentType = initialValue.paymentTypes.find(
        (pt) => pt.id === prevValue.paymentType,
      );

      if (prevValue.paymentType && includesCurrentPaymentType) {
        return prevValue;
      }
      const visiblePaymentTypes = getValue(visiblePaymentTypesStore);
      const firstVisible = visiblePaymentTypes[0];
      const preferedPaymentType = visiblePaymentTypes.find((pt) => pt.prefered);
      const preselectedPaymentType = initialValue.paymentTypes.find(
        (pt) => pt.preselectedName === initialValue.preselectedPaymentType,
      );
      const paymentType =
        preferedPaymentType ||
        preselectedPaymentType ||
        firstVisible ||
        initialValue.paymentTypes[0];

      return { ...prevValue, paymentType: paymentType.id };
    });

    // Способ оплаты
    update(selectedStore, (prevValue) => {
      const paymentVariants = getValue(availablePaymentVariantsStore);

      return { ...prevValue, paymentVariant: paymentVariants[0].id };
    });
  }
};

export const useOrderForm = () => {
  const cart = useCart();

  // обновляем информацию в orderFormStore при изменении корзины
  useEffect(() => {
    const value = getValue(orderFormStore);

    if (!cart.data || equal(value, cart.data)) return;

    update(orderFormStore, (orderForm) => {
      return { ...orderForm, ...cart.data };
    });
  }, [cart.data]);

  return {
    ...useStore(orderFormStore),
    visiblePaymentTypes: useStore(visiblePaymentTypesStore),
    availablePaymentVariants: useStore(availablePaymentVariantsStore),
    selectedDelivery: useStore(selectedDeliveryStore),
    selectedPaymentType: useStore(selectedPaymentTypeStore),
    selectedPaymentVariant: useStore(selectedPaymentVariantStore),
    loading: useStore(formLoadingStore),
    assembly: useStore(selectedAssemblyStore),
    disposal: useStore(selectedDisposalStore),
    privacyAgree: useStore(selectedPrivacyStore),
    wantBonuses: useStore(selectedWantBonusesStore),
    promocodeValue: useStore(promocodeValueStore),
  };
};

keepActive(selectedStore);

export default { init, select, updateDelivery, setFormLoading };
