const errorMessages = {
  ru: {
    requiredMessage: 'Необходимо ввести сообщение',
  },
  uz: {
    requiredMessage: 'Siz xabarni kiritishingiz kerak',
  },
  kk: {
    requiredMessage: 'Хабарламаны енгізу қажет',
  },
};

export default (lang: string, params = {}) => {
  return {
    description: 'Сообщение',
    type: 'string',
    errorMessage: {
      minLength: errorMessages[lang].requiredMessage,
    },
    ...params,
  };
};
