import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './LangItem.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface LangItemProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  title: string;
  icon: string;
  selected?: boolean;
}

const LangItem: FC<LangItemProps> = (props) => {
  const { className, title, icon, selected, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.langItem, { [styles.selected]: selected }, className)}>
      <span className={styles.image} style={{ backgroundImage: `url(${icon})` }} />
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default memo(LangItem);
