import { useMutation } from '@tanstack/react-query';

import * as FormErrorsStore from '@Stores/FormErrors';
import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import { useRequest } from '@Contexts/Request';
import useCart from './useCart';
import useRemovePromocode from './useRemovePromocode';

import type { CartData } from '@Types/Cart';

export const useCheckPromocode = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const cart = useCart();
  const { removePromocode } = useRemovePromocode();

  const { mutate, isPending } = useMutation({
    mutationFn: async (paymentTypeId: number) => {
      const couponBody = {
        'CouponForm[code]': cart.data.couponData.code,
        'CouponForm[paymentTypeId]': paymentTypeId,
      };
      const res = await ApiCart.sendCheckerPromocode(couponBody);
      return res;
    },
    onSuccess: (NewCart) => {
      queryClient.setQueriesData({ queryKey: keys }, (prev: CartData) => {
        return {
          ...prev,
          ...NewCart,
        };
      });
    },
    onError: (err: any) => {
      removePromocode(null, {
        onSuccess: () => {
          if (err.response?.errors && err.response?.errors.length > 0) {
            FormErrorsStore.setError({ 'CouponForm[code]': err.response.errors[0]?.message });
          }
        },
      });
    },
  });

  return {
    checkPromocode: mutate,
    isPending,
  };
};

export default useCheckPromocode;
