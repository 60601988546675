import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';

export interface Params {
  enabled?: boolean;
}

function useAllRegions(params?: Params) {
  const { enabled } = params || {};
  const keys = ['allRegions'];
  const result = useQuery({ queryKey: keys, queryFn: ApiMeta.getAllRegions, enabled });

  return result;
}

export default useAllRegions;
