import { memo, useMemo } from 'react';
import { cn, Scroller } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import useMedias from '@Hooks/useMedias';
import ProductList from '../ProductList';
import EmptyResponse from '../EmptyResponse';
import styles from './Result.module.css';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

export interface ResultProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export interface ContainerProps {
  className?: string;
}

const Container: FC<PropsWithChildren<ContainerProps>> = (props) => {
  const { children, ...restProps } = props;
  const { isMobileM } = useMedias();

  return isMobileM ? (
    <div {...restProps}>{children}</div>
  ) : (
    <Scroller {...restProps}>{children}</Scroller>
  );
};

const Result: FC<ResultProps> = (props) => {
  const { className, ...restProps } = props;
  const { result, info } = SearchStore.useSearch();
  const { isDesktopM } = useMedias();
  const { t } = useTranslation();
  const title = result.searchStatus === 'zeroQueries' ? t('ui.similar') : t('ui.hits');

  const bestsellers = useMemo(() => {
    const items = info?.bestsellers?.items.filter((item) => !!item.images[0]?.src) || [];

    if (isDesktopM) {
      return items.slice(0, 4);
    }

    return items.slice(0, 5);
  }, [info?.bestsellers, isDesktopM]);

  const croppedProducts = useMemo(() => {
    if (isDesktopM) {
      return result.products.slice(0, 4);
    }

    return result.products.slice(0, 5);
  }, [result.products, isDesktopM]);

  if (result.searchStatus === 'found') {
    return (
      <div {...restProps} className={cn(styles.productList, className)}>
        <div>
          <ProductList products={croppedProducts} onClickLink={HeaderStore.closeSearch} />
        </div>
      </div>
    );
  }

  if (bestsellers?.length > 0) {
    return (
      <div {...restProps} className={className}>
        {!result.loading && !!result.request && !croppedProducts?.length && (
          <EmptyResponse className={styles.emptyResponse} request={result.request} />
        )}

        <Container className={styles.container}>
          <div className={styles.lastTitle}>{title}</div>
          <ProductList products={bestsellers} onClickLink={HeaderStore.closeSearch} />
        </Container>
      </div>
    );
  }

  return null;
};

export default memo(Result);
