import { Suspense as ReactSuspense } from 'react';

import useRenderType from '@Hooks/useRenderType';

import type { FC, PropsWithChildren, ReactElement } from 'react';

export interface SuspenseProps extends PropsWithChildren {
  fallback?: ReactElement;
}

// Пока что используем данную обёртку, чтобы рендерить Suspense только на клиенте
// Нужно избавиться от этого, когда стратегия рендера на сервере будет полностью поддерживать Suspense
// (вместо renderToString нужно использовать renderToPipeableStream)
const Suspense: FC<SuspenseProps> = (props) => {
  const { isCSR } = useRenderType();

  return isCSR ? <ReactSuspense {...props} /> : null;
};

export default Suspense;
