import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

interface Body {
  rating: number;
  orderId: string | number;
  fingerprint: string;
  additional: {
    email: string;
    phone: string;
    message: string;
  };
}

interface Response {
  ok: boolean;
}

async function sendUserScore(body: Body) {
  const url = '/marketing/form/v1/add-rating';
  const res = await Api.queryProxi<Response>(url, { method: 'POST', body: JSON.stringify(body) });

  if (!res.ok) throw new BackendError(url, res);

  return res;
}

export default sendUserScore;
