import { memo } from 'react';

import * as ModalWindows from '@Stores/ModalWindows';
import UILink from '@UI/Link';

import type { FC } from 'react';
import type { LinkProps } from '@UI/Link';
import type { PublicOffersData } from '@Types/Layout';

export interface FooterPublicOffersProps extends LinkProps {
  clasName?: string;
  publicOffers: PublicOffersData;
}

const FooterPublicOffers: FC<FooterPublicOffersProps> = (props) => {
  const { publicOffers, ...restProps } = props;

  const { text, title, items } = publicOffers;

  const handleClick = () => {
    ModalWindows.open('FilesList', {
      items,
      title,
    });
  };

  return (
    <UILink {...restProps} onClick={handleClick} view='secondary'>
      {text}
    </UILink>
  );
};

export default memo(FooterPublicOffers);
