import {
  prefetchPage,
  prefetchTranslation,
  prefetchModalWindowTranslation,
} from '@Navigation/prefetchers';
import { prefetchInfiniteCategory } from '@Queries/useInfiniteCategory';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request, params } = args;
  const { page = '' } = params;
  const { search } = new URL(request.url);
  const pageNumber = parseInt(page.replace(/\D/g, ''), 10) || 1;

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchInfiniteCategory({ slug: 'promo-vmeste-deshevle', pageNumber, search }, queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchModalWindowTranslation('Filters', queryClient),
  ]);
};
