import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './Header.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  text: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { className, text, ...restProps } = props;

  return (
    <div
      {...restProps}
      className={cn(styles.header, className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default memo(Header);
