import { memo, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import * as Meta from '@Queries/Meta';
import IconMastercard from '@Layouts/LayoutMain/elems/Footer/icons/IconMastercard';
import IconMir from '@Layouts/LayoutMain/elems/Footer/icons/IconMir';
import IconVisa from '@Layouts/LayoutMain/elems/Footer/icons/IconVisa';
import IconPaykeeper from '@Layouts/LayoutMain/elems/Footer/icons/IconPaykeeper';
import IconUzcard from '@Layouts/LayoutMain/elems/Footer/icons/IconUzcard';
import IconHumo from '@Layouts/LayoutMain/elems/Footer/icons/IconHumo';
import PaymentList from '@Layouts/LayoutMain/elems/Footer/common/PaymentList';
import styles from './PaymentSystems.module.css';

import type { FooterPaymentItemData } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface PaymentSystemsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: FooterPaymentItemData[];
}

const PaymentSystems: FC<PaymentSystemsProps> = ({ items, className }) => {
  const meta = Meta.useMeta();
  const icons = useMemo(() => {
    return items.map((item) => {
      switch (item.code) {
        case 'mastercard':
          return { ...item, icon: <IconMastercard className={styles.icon} /> };

        case 'visa':
          return { ...item, icon: <IconVisa className={styles.icon} /> };

        case 'mir':
          return { ...item, icon: <IconMir className={styles.icon} /> };

        case 'uzcard':
          return { ...item, icon: <IconUzcard className={styles.icon} /> };

        case 'humo':
          return { ...item, icon: <IconHumo className={styles.icon} /> };

        case 'paykeeper':
          if (meta.data.region.id === 99) {
            return { ...item, icon: <IconPaykeeper className={styles.icon} /> };
          }

        default:
          return {
            ...item,
          };
      }
    });
  }, [items, meta.data]);

  return <PaymentList className={cn(styles.paymentList, className)} items={icons} />;
};

export default memo(PaymentSystems);
