import { useCallback, useEffect, useRef, useState } from 'react';
import { cn, Touch } from '@divlab/divanui';

import clamp from '@Utils/clamp';
import styles from './BottomSheet.module.css';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import type { TouchEvent } from '@divlab/divanui';

interface BottomSheetProps extends HTMLAttributes<HTMLElement>, PropsWithChildren {
  minHeight?: number;
  maxHeight?: number;
}

type BottomSheetState = 'collapsed' | 'expanded';

const minShiftHeight = 50;
const gap = 10;

const BottomSheet: FC<BottomSheetProps> = (props) => {
  const { className, minHeight, maxHeight, children, ...restProps } = props;
  const [state, setState] = useState<BottomSheetState>('collapsed');
  const [shift, setShift] = useState(0);
  const [shiftOnStart, setShiftOnStart] = useState(0);
  const [animated, setAnimated] = useState(false);
  const isDragging = shift > 0;
  const maxShift = maxHeight - minHeight;
  const bodyRef = useRef<HTMLDivElement>(null);

  const handleStart = useCallback(() => {
    setAnimated(false);
    setShiftOnStart(shift);
  }, [shift]);

  const handleMove = useCallback(
    (event: TouchEvent, isDraggedByHandle?: boolean) => {
      const newShift = clamp(0, shiftOnStart + event.shiftY, maxShift);

      if (bodyRef.current?.scrollTop !== 0 && !isDraggedByHandle) {
        return;
      }
      setShift(newShift);
    },
    [maxShift, shiftOnStart],
  );

  const handleEnd = useCallback(() => {
    setAnimated(true);
    const diffShift = shiftOnStart - shift;

    if (diffShift >= minShiftHeight) {
      setShift(0);
      setState('expanded');
    }

    if (diffShift > 0 && diffShift <= minShiftHeight) {
      setShift(maxShift);
      setState('collapsed');
      if (bodyRef.current) bodyRef.current.scrollTop = 0;
    }

    if (diffShift <= -minShiftHeight) {
      setShift(maxShift);
      setState('collapsed');
      if (bodyRef.current) bodyRef.current.scrollTop = 0;
    }

    if (diffShift < 0 && diffShift > -minShiftHeight) {
      setShift(0);
      setState('expanded');
    }
  }, [maxShift, shift, shiftOnStart]);

  useEffect(() => {
    const newShift = Math.max(0, maxShift);
    setAnimated(false);
    setShift(newShift);
  }, [maxShift]);

  return (
    <>
      <div className={styles.placeholder} style={{ height: `${minHeight - gap}px` }} />
      <div
        className={cn(styles.bottomSheet, className, {
          [styles.expanded]: state === 'expanded',
          [styles.animated]: animated,
        })}
        style={{
          minHeight: `${minHeight}px`,
          maxHeight: `${maxHeight}px`,
          transform: `translateY(${shift}px)`,
        }}
        {...restProps}
      >
        <Touch
          onStartY={handleStart}
          onMoveY={(e) => handleMove(e, true)}
          onEndY={handleEnd}
          className={styles.handle}
        >
          <div className={styles.line} />
        </Touch>

        <Touch
          onStartY={handleStart}
          onMoveY={handleMove}
          onEndY={handleEnd}
          className={styles.draggableContainer}
        >
          <div className={cn(styles.body, { [styles.unscrollable]: isDragging })} ref={bodyRef}>
            {children}
          </div>
        </Touch>
      </div>
    </>
  );
};

export default BottomSheet;
