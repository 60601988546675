import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { LanguageCode } from '@Types/Base';
import type { PriceTagProductData } from '@Types/PriceTag';

interface Params {
  url: string;
  lang: LanguageCode;
}

async function getPriceTagData(params: Params): Promise<PriceTagProductData> {
  const searchParams = new URLSearchParams({
    url: String(params.url).toLowerCase(),
    lang: String(params.lang).toLowerCase(),
  });

  const url = `/backend/site/price-list?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse>(url);

  if (res.ok !== true) {
    if (res.errors[0].message) {
      throw new Error(res.errors[0].message);
    } else throw new BackendError(url, res);
  }
  return res.data;
}

export default getPriceTagData;
