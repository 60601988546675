import AppConfigContext from './AppConfigContext';

import type { FC, PropsWithChildren } from 'react';
import type { AppConfig } from '@Types/AppConfig';

export interface AppConfigProviderProps {
  value: AppConfig;
}

const AppConfigProvider: FC<PropsWithChildren<AppConfigProviderProps>> = (props) => {
  const { value, children } = props;

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};

export default AppConfigProvider;
