import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { MetaData } from '@Types/Meta';
import type { ApiResponse } from '@Types/Api';

async function fetch() {
  const url = '/backend/data/meta';
  const res = await Api.queryProxi<ApiResponse<MetaData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default fetch;
