import * as Api from '@Api/index';

function sendDerelictCart(data: string) {
  const request = Api.getRequest();
  const url = `${request.proxyOrigin}/mindbox/v1/send-derelict-cart`;

  navigator.sendBeacon(url, data);
}

export default sendDerelictCart;
