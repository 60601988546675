import * as fields from '@Forms/validation/fields';

interface Params {
  lang: string;
}

export default ({ lang }: Params) => {
  return {
    title: 'JSON schema',
    $schema: 'http://json-schema.org/draft-07/schema',
    type: 'object',
    properties: {
      email: fields.email(lang, { minLength: 1 }),
    },
    required: ['email'],
  };
};
