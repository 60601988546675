import { update } from '@kundinos/nanostores';

import { welcomePopupStore } from './stores';

import type { WelcomePopupStore } from './typings';

/**
 * Use this method for updating any properties of profile popup
 * @param props — the object with properties, that need to update
 */
export function updateWelcomePopup(props?: Partial<WelcomePopupStore>): void {
  update(welcomePopupStore, (prevValue) => ({ ...prevValue, ...props }));
}
