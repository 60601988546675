import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { OrderCheckSuccessProps } from '@Pages/PageOrderCheckSuccess';

interface Params {
  signal?: AbortSignal;
  orderId?: string;
}

export interface Result {
  result: 'success' | 'error';
  data: OrderCheckSuccessProps;
}

async function success({ signal = undefined, orderId }: Params = {}) {
  //TODO: перевести на единый механизм после переезда Кабинета на php
  const { language, defaultLanguage } = Api.getRequest();
  const lang = language.id === defaultLanguage ? '' : `/${language.slug}`;
  const url = orderId ? `/cabinet${lang}/cart/success?${orderId}` : `/cabinet${lang}/cart/success`;
  const res = await Api.queryProxi<Result>(url, {
    signal,
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default success;
