import { memo } from 'react';

import { updateMedias } from '@Hooks/useMedias';
import { useRequest } from '@Contexts/Request';

import type { Matches } from '@Hooks/useMedias';
import type { FC } from 'react';

const mediasByDeviceType: Record<string, Matches> = {
  smartphone: {
    isDesktopL: false,
    isDesktopM: true,
    isDesktop: true,
    isMobileM: true,
    isMobile: true,
    isMobileS: true,
    isMobileXS: false,
    isMobileXXS: false,
    isOnlyDesktop: false,
    isOnlyMobile: true,
  },
  tablet: {
    isDesktopL: false,
    isDesktopM: true,
    isDesktop: true,
    isMobileM: true,
    isMobile: false,
    isMobileS: false,
    isMobileXS: false,
    isMobileXXS: false,
    isOnlyDesktop: false,
    isOnlyMobile: true,
  },
  default: {
    isDesktopL: true,
    isDesktopM: false,
    isDesktop: false,
    isMobileM: false,
    isMobile: false,
    isMobileS: false,
    isMobileXS: false,
    isMobileXXS: false,
    isOnlyDesktop: true,
    isOnlyMobile: false,
  },
};

const MediasDetector: FC = () => {
  const { device } = useRequest();
  const medias = mediasByDeviceType[device.device?.type] || mediasByDeviceType.default;

  updateMedias(medias);

  return null;
};

export default memo(MediasDetector);
