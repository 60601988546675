const subst = '+$1 ($2) $3-$4-$5';
const regexps = {
  by: /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/g,
  ru: /(\d?)(\d{3})(\d{3})(\d{2})(\d{2})/g,
};

export default (number: string | number): string => {
  const formatedNumber = number.toString().replace(/\D/g, '');
  const country = formatedNumber.slice(0, 1) === '7' ? 'ru' : 'by';
  const regexp = regexps[country];

  return formatedNumber.replace(regexp, subst);
};
