import { useYMaps, Placemark } from '@pbe/react-yandex-maps';

import useTranslation from '@Queries/useTranslation';
import styles from './ClientPlacemark.module.css';
import images from './images';

import type { FC } from 'react';
import type { Point } from '@Types/DeliveryTracking';

interface ClientPlacemarkProps {
  point: Point;
  timeFrom: number;
  timeTo: number;
}

const format = (number: number) => `${number}`.padStart(2, '0');

const ClientPlacemark: FC<ClientPlacemarkProps> = (props) => {
  const { point, timeFrom, timeTo } = props;
  const ymaps = useYMaps(['templateLayoutFactory']);
  const { t } = useTranslation();

  const Layout = ymaps?.templateLayoutFactory.createClass(
    `<div class=${styles.wrapper}>
      <div class='${styles.layout}'>$[[options.contentLayout]]</div>
      <img class='${styles.point}' src=${point.completed ? images.blackPoint : images.grayPoint} />
    </div>`,
  );

  const ContentLayout = ymaps?.templateLayoutFactory.createClass(
    `<div class='${styles.content}'>
      <img class='${styles.icon}' src=${images.client} />
      <div>
        <div>${t('map.client')}</div>
        <div class='${styles.time}'>${t('map.from')} ${format(timeFrom)}:00 ${t('map.to')} ${format(
      timeTo,
    )}:00</div>
      </div>
    </div>`,
  );

  return (
    <Placemark
      defaultGeometry={point.coordinates}
      options={{
        iconLayout: Layout,
        iconContentLayout: ContentLayout,
        zIndex: 10000,
        zIndexHover: 10000,
        cursor: 'default',
      }}
    />
  );
};

export default ClientPlacemark;
