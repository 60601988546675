import Page from '@Components/Page';
import Header from '../Header';
import Footer from '../Footer';
import styles from './PageLoading.module.css';

import type { FC } from 'react';

const PageLoading: FC = () => {
  return (
    <Page>
      <div className={styles.page}>
        <Header />

        <svg
          className={styles.loading}
          width={50}
          height={50}
          viewBox='0 0 50 50'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle className={styles.circle} cx={25} cy={25} r={24}></circle>
        </svg>

        <Footer className={styles.footer} />
      </div>
    </Page>
  );
};

export default PageLoading;
