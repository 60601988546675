import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ActionsData } from '@Types/Actions';

export interface Params {
  slug: string;
}

export interface Response {
  actions: ActionsData[];
  title: string;
  content: string;
}

async function getStaticPage({ slug }: Params) {
  const searchParams = new URLSearchParams({ slug });
  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/get-static-page${qs}`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getStaticPage;
