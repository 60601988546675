import { cn } from '@divlab/divanui';

import Courier from './elems/Courier';
import Delivery from './elems/Delivery';
import Support from './elems/Support';
import styles from './Info.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { DeliveryStatus, OrderState, Points } from '@Types/DeliveryTracking';

interface InfoProps extends HTMLAttributes<HTMLElement> {
  status: DeliveryStatus;
  order: OrderState;
  points: Points;
}

const Info: FC<InfoProps> = (props) => {
  const { className, status, order, points, ...restProps } = props;
  const hasSupportPhone = order?.supportPhone;

  return (
    <div className={cn(styles.info, className)} {...restProps}>
      <Delivery className={styles.delivery} status={status} order={order} points={points} />
      <Courier className={styles.courier} order={order} />
      {hasSupportPhone && <Support order={order} />}
    </div>
  );
};

export default Info;
