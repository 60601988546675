import { createStore, getValue, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

import { DIContainer } from '@Contexts/DI';
import ApiSite from '@Api/Site';
import useNavigation from '@Navigation/useNavigation';

import type { SearchInfoData, SearchResultData, SearchHistoryData } from '@Types/Search';

export interface ResultPathAndSearchValue {
  url: string;
  correction?: string;
}

export interface SearchData {
  term: string;
  opts?: RequestInit;
  click?: boolean;
}

export const searchResultStore = createStore<SearchResultData>(() => {
  update(searchResultStore, (searchResult) => ({
    ...searchResult,
    request: searchResult?.request || '',
    minLengthRequest: 2,
    matches: searchResult?.matches || [],
    products: searchResult?.products || [],
    taps: searchResult?.taps || [],
    offers: searchResult?.offers || [],
    redirectUrl: searchResult?.redirectUrl || '',
    correction: searchResult?.correction || '',
    loading: searchResult?.loading,
    disabled: searchResult?.disabled,
  }));
});

export const searchInfoStore = createStore<SearchInfoData>();

export const caretPositionStore = createStore<number>(() => {
  caretPositionStore.set(0);
});

export const searchHistoryStore = createStore<SearchHistoryData>(() => {
  update(searchHistoryStore, (value) => ({
    request: value?.request || '',
  }));
});

export const setSearchHistory = (request: string) => {
  update(searchHistoryStore, (history) => ({ ...history, request }));
};

export const setLoading = (value: boolean) => {
  update(searchResultStore, (searchResult) => ({
    ...searchResult,
    loading: value,
  }));
};

export const search = async (params: SearchData): Promise<void> => {
  const { term, opts } = params;
  const { logger } = DIContainer.getDeps();

  update(searchResultStore, (prev) => ({ ...prev, request: term, loading: true }));

  try {
    const parameters = { term, perPage: 6 };
    const { categories, products, taps, offers, correction, searchStatus, redirectUrl } =
      await ApiSite.searchProducts(parameters, opts);

    update(searchInfoStore, (prev) => ({
      ...prev,
      bestsellers: { items: products },
      offers,
    }));

    if (!term) {
      //игнорируем поле searchStatus для удаления его из объекта
      // eslint-disable-next-line autofix/no-unused-vars
      update(searchResultStore, ({ searchStatus: status, ...prev }) => ({
        ...prev,
        matches: [],
        products: [],
        taps,
        offers: [],
      }));
    } else {
      update(searchResultStore, (prev) => ({
        ...prev,
        matches: categories,
        products,
        taps,
        offers,
        correction,
        searchStatus,
        redirectUrl,
      }));
    }
  } catch (err) {
    // Запрос был прерван нами, ошибкой не считаем
    if (err === 'abort previous request') return;

    logger.log(err);
  } finally {
    setLoading(false);
  }
};

export const loadSearchInfo = async (): Promise<void> => {
  const { logger } = DIContainer.getDeps();

  try {
    const info = await ApiSite.searchInfo();

    searchInfoStore.set(info);
  } catch (err) {
    logger.log(err);
  }
};

export const setDefaultSearchResult = () => {
  update(searchResultStore, (searchVaribles) => ({
    ...searchVaribles,
    request: '',
    products: [],
    taps: [],
    offers: [],
    correction: '',
    loading: false,
  }));
};

export const setDisabled = (value: boolean) => {
  update(searchResultStore, (searchResult) => ({
    ...searchResult,
    disabled: value,
  }));
};

export const trimRequest = () => {
  update(searchResultStore, (searchResult) => ({
    ...searchResult,
    request: searchResult.request.trim() || '',
  }));
};

export const setCaretPosition = (value: number) => {
  update(caretPositionStore, () => value);
};

export const useSearch = () => {
  return {
    result: useStore(searchResultStore),
    info: useStore(searchInfoStore),
    caretPosition: useStore(caretPositionStore),
  };
};

export const useSearchHistory = () => {
  return useStore(searchHistoryStore);
};

export const useGoToSearchResults = () => {
  const navigation = useNavigation();

  return async (term?: string) => {
    const result = getValue(searchResultStore);

    const name = term || result.request;
    const searchParams = new URLSearchParams({
      'ProductSearch[name]': name,
      no_cache: '1',
    });
    const url = result.redirectUrl ? result.redirectUrl : `/search?${searchParams.toString()}`;

    // Если поискового запроса нет, то ничего не делаем
    if (!name) return;

    setLoading(true);

    await navigation.openPage({ url });

    setLoading(false);
  };
};
