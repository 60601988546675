import * as Api from '@Api/index';

async function queryProxi<T>(url: string, opts?: RequestInit) {
  const encodedUrl = Api.createProxyUrl(url);
  const res = await Api.query<T>(encodedUrl, opts);

  return res;
}

export default queryProxi;
