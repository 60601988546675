import { memo } from 'react';
import { cn } from '@divlab/divanui';

import FooterNavItem from './elems/FooterNavItem';
import styles from './FooterNav.module.css';

import type { FooterMenuItemData } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface FooterNavProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: FooterMenuItemData[];
}

const FooterNav: FC<FooterNavProps> = (props) => {
  const { items, className, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.footerNav, className)}>
      {items.map((item) => (
        <FooterNavItem key={item.text} item={item} className={styles.footerItem} />
      ))}
    </div>
  );
};

export default memo(FooterNav);
