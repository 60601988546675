import { useQuery } from '@tanstack/react-query';

import useSearchParams from '@Hooks/useSearchParams';
import { useDeps } from '@Contexts/DI';
import Api from '@Api/DeliveryTracking';

import type { UseQueryResult } from '@tanstack/react-query';
import type { CourierPositionDTO, CourierPositionParams } from '@Api/DeliveryTracking/typings';
import type { CourierPosition } from '@Types/DeliveryTracking';

const refetchInterval = 30000;

const useCourierPosition = (params: CourierPositionParams): UseQueryResult<CourierPosition> => {
  const { logger } = useDeps();
  const id = useSearchParams().get('id');

  const keys = ['delivery-courier-position', id];

  const getCourierPosition = async () => {
    try {
      if (!params) return;

      const courierPosition = await Api.getCourierPosition(params);

      return courierPosition;
    } catch (err) {
      logger.error(err);
    }
  };

  const result = useQuery<CourierPositionDTO, Error, CourierPosition>({
    queryKey: keys,
    queryFn: getCourierPosition,
    placeholderData: (previousData) => previousData,
    refetchInterval,
    select(data) {
      if (!data || !data.lat || !data.lon) return {} as CourierPosition;

      return { coordinates: [+data.lat, +data.lon] };
    },
  });

  return result;
};

export default useCourierPosition;
