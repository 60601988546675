import { useState, useEffect } from 'react';
import { cn } from '@divlab/divanui';

import { useDevPanel } from '@Stores/DevPanel';
import useTranslation from '@Queries/useTranslation';
import styles from './Timer.module.css';

import type { FC } from 'react';

export interface TimerProps {
  className?: string;
  view?: 'default' | 'inline';
  endDate: Date;
  textBeforeTimer?: string;
  textBeforeTimerMobile?: string;
  textAfterTimer?: string;
  isMobile?: boolean;
}

interface TimeLeft {
  hours: string;
  minutes: string;
  seconds: string;
}

const getSecondsLeft = (endDate: Date, different: number) => {
  const secondsLeft = Math.round((+endDate - Date.now() - different) / 1000);

  return secondsLeft > 0 ? secondsLeft : 0;
};

const format = (number: number) => `${number}`.padStart(2, '0');

const Timer: FC<TimerProps> = (props) => {
  const {
    className,
    endDate,
    view = 'default',
    textBeforeTimer,
    textBeforeTimerMobile,
    textAfterTimer,
    isMobile,
  } = props;
  const { timeDifference } = useDevPanel();
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(null);

  const timerText =
    isMobile && typeof textBeforeTimerMobile === 'string' ? textBeforeTimerMobile : textBeforeTimer;

  useEffect(() => {
    const timer = setTimeout(() => {
      const secondsLeft = getSecondsLeft(endDate, timeDifference);

      const hours = Math.floor(secondsLeft / 3600);
      const minutes = Math.floor((secondsLeft - hours * 3600) / 60);
      const seconds = secondsLeft - minutes * 60 - hours * 3600;

      setTimeLeft({
        hours: format(hours),
        minutes: format(minutes),
        seconds: format(seconds),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeDifference, endDate, timeLeft]);

  if (!timeLeft) return;

  if (view === 'default') {
    return (
      <div className={cn(styles.defaultWrapper, className)}>
        {timerText && (
          <div className={styles.textBeforeTimer} dangerouslySetInnerHTML={{ __html: timerText }} />
        )}

        <div className={styles.timer}>
          <span>{timeLeft.hours}</span>
          <span>:</span>
          <span>{timeLeft.minutes}</span>
          <span>:</span>
          <span>{timeLeft.seconds}</span>

          {textAfterTimer && <span className={styles.textAfterTimer}>{textAfterTimer}</span>}
        </div>
      </div>
    );
  }

  if (view === 'inline') {
    return (
      <div className={cn(styles.inlineWrapper, className)}>
        {textBeforeTimer && <div className={styles.textBeforeTimer}>{textBeforeTimer}</div>}

        <div className={styles.timer}>
          <span>{timeLeft.hours}</span>
          <span>{t('ui.timer.hours')}</span>
          <span>{timeLeft.minutes}</span>
          <span>{t('ui.timer.minutes')}</span>
          <span>{timeLeft.seconds}</span>
          <span>{t('ui.timer.seconds')}</span>
        </div>

        {textAfterTimer && <span className={styles.textAfterTimer}>{textAfterTimer}</span>}
      </div>
    );
  }
};

export default Timer;
