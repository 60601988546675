import { useMemo } from 'react';
import { useMedias } from '@divlab/divanui';

import useOrderState from '@Queries/DeliveryTracking/useOrderState';
import useRouteState from '@Queries/DeliveryTracking/useRouteState';
import useCourierPosition from '@Queries/DeliveryTracking/useCourierPosition';
import formatDate from '@Utils/formatDate';
import PageError from './elems/PageError';
import DeliveryPageMobile from './elems/DeliveryPageMobile';
import DeliveryPageDesktop from './elems/DeliveryPageDesktop';
import PageLoading from './elems/PageLoading';
import Page404 from '@Pages/Page404';

import type { FC } from 'react';
import type { CourierPositionParams } from '@Api/DeliveryTracking/typings';

const DeliveryPage: FC = () => {
  const { data: order, status: pageStatus } = useOrderState();
  const { data: route, status: routeStateStatus } = useRouteState();
  const { isMobile } = useMedias();
  const status = order?.status;
  let isDeliveryToday: boolean = null;
  let params: CourierPositionParams = null;

  if (order) {
    params = {
      courierId: order.courierId,
      deliveryCode: order.deliveryCode,
      deliveryDate: order.deliveryDate,
    };
  }

  const { data: courier, status: courierPositionStatus } = useCourierPosition(params);

  const isLoadingMapError =
    routeStateStatus === 'error' || pageStatus === 'error' || courierPositionStatus === 'error';

  if (order?.deliveryDate) {
    const deliveryDate = formatDate(new Date(order.deliveryDate));
    const currentDate = formatDate(new Date());

    if (deliveryDate === currentDate) isDeliveryToday = true;
    else isDeliveryToday = false;
  }

  const points = useMemo(() => {
    const warehouse = route?.find((point) => point.type === 'warehouse');

    let currentRoute = (route || []).filter(
      (point) => point.type === 'current_client' || !point.completed,
    );

    if (courier?.coordinates) {
      currentRoute.unshift({
        sequence: 0,
        type: 'courier',
        completed: false,
        coordinates: courier.coordinates,
      });
    }

    const currentPointIndex = currentRoute.findIndex((point) => point.type === 'current_client');

    if (currentPointIndex !== -1) currentRoute = currentRoute.slice(0, currentPointIndex + 1);

    return { warehouse, currentRoute };
  }, [courier, route]);

  if (pageStatus === 'pending') return <PageLoading />;
  if (isLoadingMapError && (!order || !route || !courier)) return <PageError />;
  if (!status) return <Page404 />;

  return isMobile ? (
    <DeliveryPageMobile
      order={order}
      points={points}
      status={status}
      isDeliveryToday={isDeliveryToday}
      isLoadingMapError={isLoadingMapError}
    />
  ) : (
    <DeliveryPageDesktop
      order={order}
      points={points}
      status={status}
      isDeliveryToday={isDeliveryToday}
      isLoadingMapError={isLoadingMapError}
    />
  );
};

export default DeliveryPage;
