import * as Api from '@Api/index';

import type { Quizz } from '@Domain/quizz';

interface QuizzSubmitParams {
  pathname: string;
  body?: Quizz;
}

async function getQuizzPage({ pathname, body }: QuizzSubmitParams) {
  const url = `/backend${pathname}`;
  const response = await Api.queryProxi<Quizz>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return response;
}

export default getQuizzPage;
