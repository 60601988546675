import { memo, useMemo, useCallback } from 'react';
import { Boldik, useMedias } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import useTranslation from '@Queries/useTranslation';
import { useDeps } from '@Contexts/DI';
import Link from '@Navigation/Link';
import List from '../List/List';
import TipList from '@Components/TipList/TipList';
import SearchHistory from '../SearchHistory';
import styles from './Offer.module.css';

import type { SearchItemType, TipData } from '@Types/Search';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface SearchContentProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Offer: FC<SearchContentProps> = (props) => {
  const { result, info } = SearchStore.useSearch();
  const goToSearchResults = SearchStore.useGoToSearchResults();
  const { isOnlyMobile } = useMedias();
  const { t } = useTranslation();
  const { analytics } = useDeps();

  const taps = useMemo(() => {
    return (result.taps || []).filter((elem) => elem.tap !== result.request);
  }, [result.taps, result.request]);

  const matches = useMemo(() => result.matches?.slice(0, 3) || [], [result.matches]);

  const offers = useMemo(() => {
    let arrayOffers = [];

    if (result.offers?.length > 0) {
      arrayOffers = result.offers;
    } else if (info?.offers?.length > 0) {
      arrayOffers = info.offers;
    }

    return arrayOffers.slice(0, 5);
  }, [result?.offers, info?.offers]);

  const needPopular = useMemo(() => {
    return !result.request || result.products.length > 0;
  }, [result.request, result.products]);

  const dispatchDigineticaEvent = useCallback(
    (itemType: SearchItemType, item: string) => {
      analytics.dispatchEvent('search.item.click', {
        searchResult: result,
        item,
        itemType,
      });
    },
    [result, analytics],
  );

  const handleClose = useCallback(() => {
    HeaderStore.closeSearch();
    HeaderStore.closeSideBar();
  }, []);

  const handleMore = useCallback(
    async (value: string) => {
      await SearchStore.search({ term: value });

      SearchStore.setSearchHistory(value);

      await goToSearchResults(value);

      handleClose();
    },
    [goToSearchResults, handleClose],
  );

  const handleSearchHistory = useCallback(
    (e: MouseEvent, item: string) => {
      dispatchDigineticaEvent('history', item);
      handleMore(item);
    },
    [dispatchDigineticaEvent, handleMore],
  );

  const handleSearchQuery = useCallback(
    (e: MouseEvent, item: string) => {
      dispatchDigineticaEvent('query', item);
      handleMore(item);
      handleClose();
    },
    [dispatchDigineticaEvent, handleMore, handleClose],
  );

  const handleClickCategory = useCallback(
    (e: MouseEvent, categoryName: string) => {
      dispatchDigineticaEvent('category', categoryName);
      handleClose();
    },
    [dispatchDigineticaEvent, handleClose],
  );

  const handleTapClick = useCallback(
    (e: MouseEvent, item: TipData) => {
      analytics.dispatchEvent('search.item.click', {
        searchResult: result,
        item: item.tap,
        itemType: 'tap',
      });
    },
    [result, analytics],
  );

  return (
    <div {...props}>
      {!!taps.length && isOnlyMobile && (
        <TipList className={styles.tips} tips={taps} onTapClick={handleTapClick} />
      )}
      <SearchHistory className={styles.history} onSearch={handleSearchHistory} />
      {needPopular && !!offers.length && (
        <div className={styles.lists}>
          <List
            className={styles.oftenSearch}
            title={t('ui.frequent-requests')}
            list={offers}
            onSearch={handleSearchQuery}
          />
        </div>
      )}
      {matches.length > 0 && !!result.request.trim() && (
        <div className={styles.lists}>
          <h2 className={styles.categoryFound}>{t('ui.find-in-categories')}</h2>
          {matches.map(({ name, link }) => {
            return (
              <div className={styles.found} key={name}>
                <Link to={link} onClick={(e) => handleClickCategory(e, name)}>
                  <Boldik className={styles.requestTite}>
                    {result.correction || result.request}
                  </Boldik>
                  <p className={styles.requestSubtitle}>{`${t('ui.in-category')} “${name}”`}</p>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default memo(Offer);
