import { cn, useMedias } from '@divlab/divanui';

import CardInView from '@Components/CardInView';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import Video from '@Components/Video';
import Logo from '@Promo/elems/Logo';
import Text from '@Promo/elems/Text';
import play from './play.svg';
import styles from './NoveltyTemplate.module.css';

import type { FC } from 'react';
import type { NoveltyTemplateProps } from '../../typings';

const NoveltyTemplate: FC<NoveltyTemplateProps> = (props) => {
  const { cnTemplate, promotion, size, index, onClickLink, ...restProps } = props;
  const { isDesktop } = useMedias();
  const banner = promotion.materials[0];
  const text = banner.text || {};

  const resources = banner.resources.default;
  const video = !isDesktop && size === 'full' ? resources.desktopVideo : null;
  let image = video || size === 'full' ? resources.fullDesktopImage : resources.halfDesktopImage;
  if (isDesktop) image = resources.mobileImage;
  const logo = resources.logo;

  const alignmentX = size === 'half' ? 'left' : banner.alignmentX || 'left';
  const alignmentY = banner.alignmentY || 'center';
  const bannerColor = banner.color ? banner.color : '';

  const inversed = banner.inversed && !isDesktop && (!banner.separateText || size === 'half');
  const separateText = banner.separateText && !isDesktop && size === 'full';

  return (
    <CardInView
      {...restProps}
      id={banner.id}
      listTitle='menu_novelty'
      position={index}
      cardType='promo'
      card={banner}
      targetPathname={banner.link}
    >
      <Link to={banner.link} className={cnTemplate} onClick={onClickLink}>
        <div
          style={{ backgroundColor: bannerColor }}
          className={cn(styles.container, {
            [styles.leftSeparateText]: separateText && alignmentX === 'left',
            [styles.rightSeparateText]: separateText && alignmentX === 'right',
          })}
        >
          {video ? (
            <>
              <Video
                className={cn(styles.asset, styles.video)}
                src={video}
                poster={image}
                loop
                playOnHover
              />
              <Img className={styles.playIcon} src={play} />
            </>
          ) : (
            <Img className={styles.asset} cnImage={styles.image} src={image} />
          )}

          <div
            className={cn(styles.text, styles[`${alignmentX}-${alignmentY}`], {
              [styles.inversed]: inversed,
              [styles.full]: size === 'full',
              [styles.half]: size === 'half',
              [styles.leftTop]: alignmentX === 'left' && alignmentY === 'top',
              [styles.leftCenter]: alignmentX === 'left' && alignmentY === 'center',
              [styles.centerTop]: alignmentX === 'center' && alignmentY === 'top',
              [styles.centerCenter]: alignmentX === 'center' && alignmentY === 'center',
              [styles.rightTop]: alignmentX === 'center' && alignmentY === 'top',
              [styles.rightCenter]: alignmentX === 'center' && alignmentY === 'center',
              [styles.sameAlignment]: isDesktop,
            })}
          >
            {logo && <Logo className={styles.logo} src={logo} size='S' />}

            {text.title && (
              <Text
                className={styles.title}
                desktop={text.title}
                mobile={text.titleMobile}
                isMobile={isDesktop}
              />
            )}

            {text.subtitle && (
              <Text
                className={styles.subtitle}
                desktop={text.subtitle}
                mobile={text.subtitleMobile}
                isMobile={isDesktop}
              />
            )}

            {text.link && <Text className={styles.link} desktop={text.link} />}
          </div>
        </div>
      </Link>
    </CardInView>
  );
};

export default NoveltyTemplate;
