import * as Api from '@Api/index';

import type { ApiResponse } from './types/Api';
interface Params {
  promoCode: string;
}

interface Result {
  isExists: boolean;
}

async function promocodeExists(body: Params) {
  const url = '/backend/designer/promocode-exists';
  const response = await Api.query<ApiResponse<Result>>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return response;
}

export default promocodeExists;
