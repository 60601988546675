import * as Api from '@Api/index';

import type { ApiResponse } from '@Types/Api';
import type { SearchProductsResultData } from '@Types/Search';
// import { BackendError } from '@Common/errors';

export interface Params {
  term: string;
  page?: number;
  search?: string;
  perPage?: number;
}

async function searchProducts(params: Params, opts?: RequestInit) {
  const { term, page, search = '', perPage } = params;
  const searchParams = new URLSearchParams(search);
  searchParams.set('term', term);
  searchParams.set('page', page ? page.toString() : '1');

  if (perPage) {
    searchParams.set('perPage', perPage.toString());
  }

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/search/get-products${qs}`;
  const res = await Api.queryProxi<ApiResponse<SearchProductsResultData>>(url, opts);

  // TODO: backend Если в качестве term передать пустую строку, то бэкенд вернет ошибку, а должен вернуть успешный ответ с пустыми результатами
  // После этого можно раскомментировать строку ниже и убрать заглушку в качестве ответа
  // if (!res.ok) throw new BackendError(url, res);
  if (!res.ok)
    return {
      products: [],
      categories: [],
      taps: [],
      offers: [],
      page: 1,
      productsCountLeft: 0,
    } as SearchProductsResultData;

  return res.data;
}

export default searchProducts;
