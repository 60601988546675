import { cn } from '@divlab/divanui';

import styles from './LogotypeRedesign.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CountryCode } from '@Types/Base';

export interface LogotypeRedesignProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  cnLogo?: string;
  country: CountryCode;
}

const LogotypeRedesign: FC<LogotypeRedesignProps> = (props) => {
  const { className, cnLogo, country, ...restProps } = props;

  const isBlr = country === 'BLR';
  const isKaz = country === 'KAZ';
  const isUzb = country === 'UZB';

  return (
    <div {...restProps} className={cn(styles.logotypeWrapper, className)}>
      <div
        className={cn(styles.logo, cnLogo, {
          [styles.logoBy]: isBlr,
          [styles.logoKz]: isKaz,
          [styles.logoUz]: isUzb,
        })}
      />
    </div>
  );
};

export default LogotypeRedesign;
