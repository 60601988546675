import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';
import transformCart from './helpers/transformCart';
import useCartKeys from './useCartKeys';

import type { CartData, CartPositionData } from '@Types/Cart';

export function useShowPosition() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const { analytics } = useDeps();

  return useMutation<CartData, Error, CartPositionData['id']>({
    mutationFn: async (positionId) => {
      const res = await ApiCart.unhide({ cartPositionId: positionId });

      return transformCart({ cart: res });
    },
    onMutate: async (positionId) => {
      await queryClient.cancelQueries({ queryKey: keys });

      // Снимок предыдущего значения
      const prevCart: CartData = queryClient.getQueryData(keys);

      const position = prevCart.removedPositions.find((pos) => positionId === pos.id);

      const removedPositions = prevCart.removedPositions.filter((pos) => pos.id !== position.id);

      const newCart = {
        ...prevCart,
        positions: [...prevCart.positions, position],
        removedPositions,
      };

      // Оптимистическое обновление
      queryClient.setQueryData(keys, () => {
        return newCart;
      });

      // Возвращаем объект контекста с зафиксированным значением
      return { prevCart };
    },
    onError: (err, data, context: { prevCart: CartData }) => {
      queryClient.setQueryData(keys, context.prevCart);
    },
    onSuccess: (cart, positionId) => {
      const newCart = queryClient.setQueryData<CartData>(keys, (prev: CartData) => ({
        ...prev,
        ...cart,
        positions: prev.positions,
        removedPositions: prev.removedPositions,
      }));

      const actualPosition = newCart.positions.find((position) => position.id === positionId);
      queryClient.invalidateQueries({ queryKey: ['cartShortInfo'], refetchType: 'all' });

      analytics.dispatchEvent('cart.add', {
        positions: [actualPosition],
      });
      analytics.dispatchEvent('cart.update', { cart, addLabel: 'Восстановление в корзине' });
    },
  });
}

export default useShowPosition;
