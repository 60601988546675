import { Button, Link, cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import formatPhone from '@Utils/formatPhone';
import styles from './Courier.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { OrderState } from '@Types/DeliveryTracking';

interface CourierProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
}

const Courier: FC<CourierProps> = (props) => {
  const { className, order, ...restProps } = props;
  const { t } = useTranslation();
  const phone = order?.courierPhone || '';
  const formattedPhone = formatPhone(phone);

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <div className={styles.image} />
      <div className={styles.text}>
        <div className={styles.title}>{t('info.courier.title')}</div>
        <div className={styles.value}>{formattedPhone}</div>
      </div>
      <Link className={styles.button} to={`tel:${phone}`}>
        <Button wide>{t('call')}</Button>
      </Link>
    </div>
  );
};

export default Courier;
