import * as Api from '@Api/index';

import type { PaymentTypeData } from '@Types/Cart';

export interface DeliveryBodyItem {
  productTypeGroupName: string;
  fullServiceName: string;
  price: string[];
}

export interface DeliveryHeader {
  name: string;
  rowspan?: number;
  colspan?: number;
  subheader?: DeliveryHeader[];
}

export interface WithoutLayoutData {
  header: DeliveryHeader[];
  body: DeliveryBodyItem[];
  teaser?: string;
  expandedText?: string;
}

interface AssemblingInfo {
  title: string;
  text: string[] | string;
}

export interface Response {
  teaser: string;
  assembling?: AssemblingInfo;
  assemblingOutside?: AssemblingInfo;
  table: {
    delivery: string;
    assembly: string;
    suburb: string;
    upliftFirstFloor: string;
    deliveryWithoutLayout: WithoutLayoutData;
    assemblyWithoutLayout: WithoutLayoutData;
    disposal?: WithoutLayoutData;
  };
  paymentTypes?: PaymentTypeData[];
}

async function getDeliveryInfo() {
  const res = await Api.queryProxi<Response>('/backend/order/get-delivery-info');

  return res;
}

export default getDeliveryInfo;
