import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';
interface Params {
  signal?: AbortSignal;
}

async function cart({ signal = undefined }: Params = {}) {
  const url = '/cabinet/cart/cart';
  const res = await Api.queryProxi<{ data: { positions: string[]; count: number } }>(url, {
    signal,
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default cart;
