import type { Query } from '@Types/Base';
import type { LoaderCountries } from './loader';

export const lazy = async (query: Query, countries: LoaderCountries) => {
  const { country } = query;

  let LazyComponent;
  if (countries.landingCountries.includes(country))
    LazyComponent = await import('@Pages/PageB2bHoreca/PageB2bLanding');
  if (countries.detailCountries.includes(country))
    LazyComponent = await import('@Pages/PageB2bHoreca/PageB2bDetail');

  return LazyComponent.default;
};

export default lazy;
