import {
  prefetchPage,
  prefetchTranslation,
  prefetchCategoryPromotion,
} from '@Navigation/prefetchers';

import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request } = args;
  const slug = 'promo-yandex-split';

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchCategoryPromotion({ slug }, queryClient),
  ]);
};
