import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './FooterRequisites.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface FooterRequisitesProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  requisites: string[];
}

const FooterRequisites: FC<FooterRequisitesProps> = ({ requisites, className }) => {
  return (
    <div className={cn(styles.requisites, className)}>
      {requisites.map((paragraph, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </div>
  );
};

export default memo(FooterRequisites);
