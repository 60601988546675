import { useYMaps, Placemark } from '@pbe/react-yandex-maps';

import styles from './CourierPlacemark.module.css';
import images from './images';

import type { FC } from 'react';
import type { Point } from '@Types/DeliveryTracking';

interface CourierPlacemarkProps {
  point: Point;
}

const CourierPlacemark: FC<CourierPlacemarkProps> = (props) => {
  const { point } = props;
  const ymaps = useYMaps(['templateLayoutFactory']);

  const Layout = ymaps?.templateLayoutFactory.createClass(
    `<img class='${styles.placemark}' src=${images.courier} />`,
  );

  return (
    <Placemark
      geometry={point.coordinates}
      options={{
        iconLayout: Layout,
        zIndex: 100000,
        zIndexHover: 100000,
        cursor: 'default',
      }}
    />
  );
};

export default CourierPlacemark;
