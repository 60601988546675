import React from 'react';

import type { AppConfig } from '@Types/AppConfig';

const symbolName = '__APP_CONFIG_CONTEXT__';
const hasSymbol = typeof Symbol === 'function' && Symbol.for;
const contextSymbol = hasSymbol ? Symbol.for(symbolName) : symbolName;
const initialState = {};

export function resetContext(): void {
  Object.defineProperty(React, contextSymbol, {
    value: React.createContext(initialState),
    enumerable: false,
    configurable: true,
    writable: false,
  });
}

export function getContext(): React.Context<AppConfig> {
  if (!(React as any)[contextSymbol]) {
    resetContext();
  }

  return (React as any)[contextSymbol];
}

export default getContext();
