import { useQuery } from '@tanstack/react-query';

import useNavigation from '@Navigation/useNavigation';
import * as ApiCategory from '@Api/Category';
import { useDeps } from '@Contexts/DI';
import { getRequest } from '@Api/settings';
import { filterSearchParams } from '@Queries/helpers/generateQueryKeys';

import type { InlineBannersResponse } from '@Api/Category/getInlineBanners';

export function getInlineBannersKeys(input: { slug: string; search: string }) {
  const { region, country } = getRequest();
  const { slug, search } = input;
  const filteredSearch = filterSearchParams(search);

  const keys = ['category-banners', { slug, country, region, filteredSearch }].filter(Boolean);

  return keys;
}

export const useInlineBanners = (slug: string) => {
  const { logger } = useDeps();
  const { search } = useNavigation();
  const keys = getInlineBannersKeys({ slug, search });

  const result = useQuery<InlineBannersResponse>({
    queryKey: keys,
    queryFn: async () => {
      try {
        const inputParams = {
          slug,
          search,
        };
        const promotion = await ApiCategory.getInlineBanners(inputParams);

        return promotion;
      } catch (err) {
        // Этот запрос мы считаем не критичным, если произошла ошибка,
        // то допустимо отдать пустой массив данных, чтобы сохранить доступность страницы
        logger.log(err);
        return Promise.resolve(null);
      }
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  return result;
};

export default useInlineBanners;
