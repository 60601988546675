import * as Api from '@Api/index';

import type { SectionData } from '@Types/Order';

export interface Response {
  ok: boolean;
  data: SectionData[];
}

async function getSections() {
  const res = await Api.queryProxi<Response>('/backend/order/get-sections');

  return res?.data;
}

export default getSections;
