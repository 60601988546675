const errorMessages = {
  ru: {
    requiredName: 'Укажите имя',
    longName: 'Имя не может быть длиннее 85 символов',
  },
  uz: {
    requiredName: "Ismni ko'rsating",
    longName: 'Ism 85 belgidan oshmasligi kerak',
  },
  kk: {
    requiredName: 'Атын көрсетіңіз',
    longName: 'Атау 85 таңбадан аспауы керек',
  },
};

export default (lang: string, params = {}) => {
  return {
    description: 'Имя',
    type: 'string',
    maxLength: 85,
    errorMessage: {
      minLength: errorMessages[lang].requiredName,
      required: errorMessages[lang].requiredName,
      maxLength: errorMessages[lang].longName,
    },
    ...params,
  };
};
