import * as Api from '@Api/index';

interface Params {
  name: any;
  body: any;
}

async function sendFabrics({ name, body }: Params) {
  const url = `/marketing/voting/v1/${name}  `;
  const res = await Api.query<any>(url, {
    method: 'POST',
    body,
  });

  return res;
}

export default sendFabrics;
