import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import * as Profile from '@Queries/Profile';
import { useRequest } from '@Contexts/Request';

import type { Layout } from '@Types/Layout';
import type { UseQueryResult } from '@tanstack/react-query';

export const useLayout = (): UseQueryResult<Layout> => {
  const { region, language, queryClient } = useRequest();
  const keys = ['layout', { region, lang: language.id }];
  const profile = Profile.useProfile();
  const isClientDesigner = profile.data?.clientType === 'designer';
  const placeholder: Layout = {
    header: [],
    special: [],
    footer: {},
    sideMenu: {
      bottom: [],
      top: [],
      useful: [],
    },
    topLine: [],
    onlineConsultation: [],
  };

  const result = useQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData<Layout>(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getLayout();
    },
    enabled: true,
    placeholderData: (prev) => prev || placeholder,
    select(data) {
      const toCustomers = isClientDesigner
        ? data.footer.toCustomers.items?.filter((item) => item.text !== 'Divan.Club')
        : data.footer.toCustomers.items;

      const footer = {
        ...data.footer,
        toCustomers: { ...data.footer.toCustomers, items: toCustomers },
      };

      const topLine = isClientDesigner
        ? data.topLine?.filter((item) => item.id !== '3039')
        : data.topLine;

      return { ...data, topLine, footer };
    },
  });

  return result as UseQueryResult<Layout>;
};
