import type { UpdatedCartInfo, CartData, BankOffer } from '@Types/Cart';
import type { OfferData } from '../useToggleIsCredit';

export interface Params {
  cart: Partial<UpdatedCartInfo>;
  options?: { isRelated: boolean };
  offer?: OfferData;
}

export const transformCart = (params: Params): CartData => {
  const { cart, options, offer } = params;

  // Создаём объект корзины без лишней вложенности
  let result = { ...cart, ...cart.cart };
  delete result.cart;

  // Обновляем новые позиции только если обычная покупка товара,
  // а не покупка сопутствующих товаров
  if (!options?.isRelated) {
    result = { ...result, newPositions: cart.cart.newPositions };
  }
  if (offer) {
    const flagName = Object.keys(offer).filter((key) => offer[key])[0] as BankOffer;

    result.bankOffer = flagName;
  } else if (!!result.preselectedPaymentType) {
    result.bankOffer = result.preselectedPaymentType === 'halvaPartial' ? 'halvaPartial' : 'credit';
  }
  return result;
};

export default transformCart;
