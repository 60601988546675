import { getValue } from '@kundinos/nanostores';

import { modalsStore } from './stores';

import type { ModalId } from './typings';

/**
 * Closes only specified modal window
 * @param id - identifier of modal window, that need close
 */
async function close(id: ModalId): Promise<void> {
  const modals = getValue(modalsStore);

  modalsStore.set({
    ...modals,
    stack: modals.stack.map((modal) => ({
      ...modal,
      visible: modal.id === id ? false : modal.visible,
    })),
  });

  return new Promise((resolve) => {
    setTimeout(() => {
      modalsStore.set({
        ...modals,
        stack: modals.stack.filter((modal) => modal.id !== id),
      });

      resolve();
    }, modals.timeout);
  });
}

export default close;
