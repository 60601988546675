import { useRef, forwardRef } from 'react';
import { cn } from '@divlab/divanui';

import styles from './Img.module.css';

import type { ImgHTMLAttributes } from 'react';

export interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  cnImage?: string;
}

const Img = forwardRef<HTMLDivElement, ImgProps>(function Img(props, forwardedRef) {
  const { className, cnImage, src, loading = 'lazy', ...restProps } = props;
  const refImg = useRef<HTMLImageElement>();

  return (
    <div ref={forwardedRef} className={cn(styles.imageWrapper, className)}>
      <img
        loading={loading}
        decoding={loading === 'lazy' ? 'async' : 'auto'}
        src={src}
        className={cn(styles.image, cnImage)}
        ref={refImg}
        {...restProps}
      />
    </div>
  );
});

export default Img;
