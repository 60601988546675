import { createStore, getValue, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

import * as BrowserFeatures from '@Stores/BrowserFeatures';
import formatDateNum from '@Utils/formatDateNum';
import formatNumWithZero from '@Utils/formatNumWithZero';

import type { CountryCode } from '@Types/Base';

export interface CookiesStoreData {
  accepted: boolean;
  date?: number;
  available: boolean;
}

export interface CookiesInfoStoreData {
  info?: string;
}

export const cookiesPolicyStore = createStore<CookiesStoreData>(() => {
  cookiesPolicyStore.set({
    accepted: null,
    available: false,
  });
});

export const cookiesInfoStore = createStore<CookiesInfoStoreData>(() => {
  cookiesInfoStore.set({
    info: null,
  });
});

const browserFeatures = getValue(BrowserFeatures.featuresStore);

export const useCookiesPolicy = () => {
  return useStore(cookiesPolicyStore);
};

export const useCookiesInfo = () => {
  return useStore(cookiesInfoStore);
};

export const setInfo = () => {
  const cookiesPolicy = getValue(cookiesPolicyStore);
  if (cookiesPolicy.date === null || cookiesPolicy.accepted === null) return;

  const formatedDate = new Date(cookiesPolicy.date);
  const fullDate = formatDateNum(formatedDate);
  const time = `${formatNumWithZero(formatedDate.getHours())}:${formatNumWithZero(
    formatedDate.getMinutes(),
  )}`;

  if (cookiesPolicy.accepted) {
    return `Вы согласились с Политикой ${fullDate} в ${time}`;
  }

  return `Вы отклонили Политику ${fullDate} в ${time}`;
};

export const init = (country: CountryCode): void => {
  if (country === 'BLR') {
    update(cookiesPolicyStore, (prev) => {
      if (browserFeatures.isLocalStorageAvailable) {
        const value = JSON.parse(window.localStorage.getItem('cookies_accepted'));

        if (value) return { ...prev, available: true, ...value };

        return { ...prev, available: true };
      }
      return { ...prev, available: false };
    });
  }
};

export const accept = (): void => {
  update(cookiesPolicyStore, (prev) => {
    return { ...prev, accepted: true, date: Date.now() };
  });
};

export const reject = (): void => {
  update(cookiesPolicyStore, (prev) => {
    return { ...prev, accepted: false, date: Date.now() };
  });
};

export const clear = (): void => {
  update(cookiesPolicyStore, (prev) => {
    return { ...prev, accepted: null, date: null };
  });
};

cookiesPolicyStore.listen((val: CookiesStoreData) => {
  update(cookiesInfoStore, () => {
    return { info: setInfo() };
  });
  if (browserFeatures.isLocalStorageAvailable) {
    localStorage.setItem(
      'cookies_accepted',
      JSON.stringify({ accepted: val.accepted, date: val.date }),
    );
  }
});
