import { memo } from 'react';

import FooterNav from '@Layouts/LayoutMain/elems/Footer/common/FooterNav';
import FooterInfoBlock from '@Layouts/LayoutMain/elems/Footer/common/FooterInfoBlock';
import Accordion from '@Layouts/LayoutMain/elems/Footer/common/Accordion';
import FooterRequisites from '../FooterRequisites';
import PaymentSystems from '../PaymentSystems';
import styles from './FooterMobile.module.css';

import type { FooterData } from '@Types/Layout';
import type { FC } from 'react';

export interface FooterMobileProps {
  footer: FooterData;
}

const FooterMobile: FC<FooterMobileProps> = (props) => {
  const { footer } = props;

  return (
    <div className={styles.grid}>
      <div className={styles.col}>
        {footer.contacts?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <Accordion header={footer.contacts.title}>
              <FooterNav items={footer.contacts.items} />
            </Accordion>
          </div>
        )}
        <div className={styles.wrapperBig}>
          {footer.callSchedule?.items.length > 0 && (
            <div className={styles.wrapperMedium}>
              <FooterInfoBlock title={footer.callSchedule.title}>
                <FooterNav items={footer.callSchedule.items} />
              </FooterInfoBlock>
            </div>
          )}

          {footer.deliverySchedule?.items.length > 0 && (
            <FooterInfoBlock title={footer.deliverySchedule.title}>
              <FooterNav items={footer.deliverySchedule.items} />
            </FooterInfoBlock>
          )}
        </div>
        {footer.catalog?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <Accordion defaultCollapsed header={footer.catalog.title}>
              <FooterNav items={footer.catalog.items} />
            </Accordion>
          </div>
        )}
      </div>

      <div className={styles.col}>
        {footer.toCustomers?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <Accordion header={footer.toCustomers.title} defaultCollapsed>
              <FooterNav items={footer.toCustomers.items} />
            </Accordion>
          </div>
        )}
        {footer.payments && (
          <div className={styles.wrapperBig}>
            <FooterInfoBlock title={footer.payments.title}>
              <PaymentSystems items={footer.payments.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.requisites?.length > 0 && <FooterRequisites requisites={footer.requisites} />}
      </div>
    </div>
  );
};

export default memo(FooterMobile);
