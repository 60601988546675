import { useCallback, useEffect, useRef, useState } from 'react';
import { Map, YMaps } from '@pbe/react-yandex-maps';
import { cn } from '@divlab/divanui';

import { useAppConfig } from '@Contexts/AppConfig';
import useTranslation from '@Queries/useTranslation';
import CustomPlacemark from './elems/CustomPlacemark';
import Controls from './elems/Controls';
import styles from './Map.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { OrderState, Points } from '@Types/DeliveryTracking';

interface YandexMapProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
  points: Points;
  isLoadingMapError: boolean;
}

const YandexMap: FC<YandexMapProps> = (props) => {
  const { className, order, points, isLoadingMapError, ...restProps } = props;
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const ymapsRef = useRef(null);
  const routeRef = useRef(null);
  const referencePoints = points.currentRoute?.map((point) => point.coordinates);

  const handleInit = useCallback(() => {
    routeRef.current = new ymapsRef.current.multiRouter.MultiRoute(
      {
        referencePoints,
        params: {
          results: 1,
        },
      },
      {
        boundsAutoApply: referencePoints.length > 1,
        routeActiveStrokeWidth: 4,
        routeActiveStrokeColor: '000000',
        wayPointVisible: false,
        pinVisible: false,
      },
    );

    map.geoObjects.add(routeRef.current);
  }, [map, referencePoints]);

  const handleIncrement = useCallback(() => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + 1, { duration: 200 });
    }
  }, [map]);

  const handleDecrement = useCallback(() => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom - 1, { duration: 200 });
    }
  }, [map]);

  const handleGetGeoocation = (coords: [number, number]) => {
    if (map) map.panTo(coords);
  };

  useEffect(() => {
    if (!routeRef.current || !ymapsRef.current || !map) return;

    routeRef.current.model.setReferencePoints(referencePoints);
  }, [map, referencePoints]);

  useEffect(() => {
    if (!map || !loaded || initialized) return;

    handleInit();
    setInitialized(true);
  }, [map, loaded, initialized, handleInit]);

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      <Map
        className={styles.map}
        defaultState={{
          center: points.currentRoute[0]?.coordinates || [55.755864, 37.617698],
          zoom: 12,
        }}
        modules={['Placemark', 'multiRouter.MultiRoute']}
        instanceRef={(instance) => setMap(instance)}
        onLoad={(instance) => {
          ymapsRef.current = instance;
          setLoaded(true);
        }}
      >
        {points.currentRoute.map((point) => {
          if (point.type === 'courier')
            return <CustomPlacemark type='courier' point={point} key={point.sequence} />;

          if (point.type === 'client')
            return <CustomPlacemark type='default' point={point} key={point.sequence} />;

          if (point.type === 'current_client')
            return (
              <CustomPlacemark
                type='client'
                point={point}
                timeFrom={order?.deliveryTimeFrom}
                timeTo={order?.deliveryTimeTo}
                key={point.sequence}
              />
            );
        })}

        {points.warehouse && <CustomPlacemark type='warehouse' point={points.warehouse} />}

        <Controls
          className={styles.controls}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onGetGeolocation={handleGetGeoocation}
        />
        {isLoadingMapError && (
          <div className={styles.error}>
            <span>{t('error.map-not-update')}</span>
            <span>{t('error.we-are-working')}</span>
          </div>
        )}
      </Map>
    </div>
  );
};

const YandexMapProvider: FC<YandexMapProps> = (props) => {
  const { yandexApiKey } = useAppConfig();

  return (
    <YMaps query={{ lang: 'ru_RU', apikey: yandexApiKey }}>
      <YandexMap {...props} />
    </YMaps>
  );
};

export default YandexMapProvider;
