import { modalsStore } from './stores';

/**
 * Clear all stores for modal windows
 */
function clear() {
  modalsStore.set({ timeout: 400, stack: [] });
}

export default clear;
