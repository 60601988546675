import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CatalogData } from '@Types/Catalog';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  slug: string;
  page: number | string;
  search?: string;
  isInit?: boolean;
}

async function getProducts({ slug, page, search = '', isInit = true }: Params) {
  const searchParams = new URLSearchParams(search);
  const pageWithDefault = Number(page || 1);

  searchParams.set('slug', slug);
  searchParams.set('page', pageWithDefault.toString());
  if (isInit) searchParams.set('isInit', 'true');

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/category/get-products${qs}`;
  const res = await Api.queryProxi<ApiResponse<CatalogData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getProducts;
