import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { FiltersData, FiltersObjectData } from '@Types/Filters';

export interface Params {
  slug: string;
  categories?: number[];
  filters: FiltersObjectData;
}

export interface Response {
  productsTotalCount: number;
  filters: FiltersData;
}

async function selectFilter(params: Params) {
  const { slug, filters, categories = [] } = params;
  const searchParams = new URLSearchParams({ page: '1' });

  if (categories.length > 0) {
    searchParams.set('categories[]', categories.join());
  }

  const url = `/backend/category/${slug}/get-products-count?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(filters),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default selectFilter;
