import { update } from '@kundinos/nanostores';

import { store } from '@Stores/PageLock/stores';

import type { Id } from '@Stores/PageLock/typings';

/**
 * Use this method for adding the variant, that lock page scroll
 * If we have at least one opened variant – page will lock
 * @param id — identifier of variant
 */
function add(id: Id) {
  update(store, (value) => {
    value.variants[id] = { id, opened: true };

    return value;
  });
}

export default add;
