import { useQuery } from '@tanstack/react-query';

import { getDirectCreditStatus } from '@Api/Order';
import { useRequest } from '@Contexts/Request';

import type { CountryCode } from '@Types/Base';

export interface DirectCreditKeysData {
  region: string;
  uuid: string;
  country: CountryCode;
}

export const getDirectCreditKeys = ({ region, uuid, country }: DirectCreditKeysData) => {
  const keys = ['directCreditStatus', { region, uuid, country }].filter(Boolean);
  return keys;
};

const useDirectCreditStatus = (uuid: string) => {
  const { region, country } = useRequest();
  const keys = getDirectCreditKeys({ region, country, uuid });

  const query = useQuery({
    queryKey: keys,
    queryFn: () => {
      return getDirectCreditStatus(uuid);
    },
  });

  return query;
};

export default useDirectCreditStatus;
