/* eslint-disable no-restricted-imports */
import { useCallback } from 'react';
import {
  useNavigate,
  useLocation,
  useNavigationType,
  useParams,
  useNavigation as useRouterNavigation,
} from 'react-router-dom';

import * as ModalWindows from '@Stores/ModalWindows';
import { useDeps } from '@Contexts/DI';
import findPathPattern from '@Navigation/findPathPattern';
import transformUrl from '@Navigation/transformUrl';
import setCookie from '@Utils/setCookie';
import { useRequest } from '@Contexts/Request';
import { filterSearchParams } from '@Queries/helpers/generateQueryKeys';

export interface Region {
  parent_id: string | number;
  id: string | number;
  url: string;
}

interface Params {
  url: string;
  region?: Region;
  language?: string;
}

function useNavigation() {
  const navigate = useNavigate();
  const routerParams = useParams();
  const navigation = useRouterNavigation();
  const location = useLocation();
  const navigationType = useNavigationType();
  const request = useRequest();
  const { queryClient, updateRequest, mainRegion } = request;
  const { logger } = useDeps();

  const handleError = (error: Error) => {
    ModalWindows.open('Info', { view: 'error', variant: 'unknown-error' });
    logger.log(error);
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const replaceUrl = useCallback(
    (path: string) => {
      navigate(path, { replace: true });
    },
    [navigate],
  );

  const getUrl = useCallback((params: Params) => {
    const { url, region, language } = params;

    return transformUrl({
      url,
      targetRegion: region?.url.replace('/', ''),
      targetLanguage: language,
    });
  }, []);

  const openPage = async (params: Params) => {
    try {
      const { url, region, language } = params;
      const finalUrl = getUrl({ url, region, language });
      const pattern = findPathPattern(finalUrl);
      const isPrivatePage =
        pattern.startsWith('/cabinet') || pattern.startsWith('/:region/cabinet');

      if (language) {
        const opts = { 'max-age': 60 * 60 * 24 * 30 };

        setCookie('lang', language, opts);
        updateRequest((prev) => ({ ...prev, language: { id: language, slug: language } }));
      }

      if (region) {
        const opts = { 'max-age': 60 * 60 * 24 * 30 };

        setCookie('rgn', String(region.parent_id), opts);
        setCookie('secondary_rgn', String(region.id), opts);
        const regionTaeget = region.url.replace('/', '') || mainRegion;

        updateRequest((prev) => ({
          ...prev,
          region: regionTaeget,
          secondaryRegion: String(region.id),
        }));
      }

      // Если пользователь пытается попасть в приватную часть сайта, то открываем окно с авторизацией
      if (isPrivatePage) {
        const profileKeys = ['profile'];
        const profile = queryClient.getQueryData(profileKeys);

        if (!profile) {
          ModalWindows.open('Authorization', {
            onSuccess: () => {
              openPage({ url: finalUrl, region, language });
            },
          });
          return;
        }
      }

      // принудительно закрываем все модальные окна, которые могли быть открыты
      await ModalWindows.closeAll();

      navigate(finalUrl, { replace: !!region });
    } catch (err) {
      handleError(err);
    }
  };

  return {
    ...location,
    search: filterSearchParams(location.search),
    navigationType,
    openPage,
    goBack,
    replaceUrl,
    navigate,
    params: routerParams,
    navigation,
  };
}

export default useNavigation;
