import { cn } from '@divlab/divanui';

import { images } from './images';
import useTranslation from '@Queries/useTranslation';
import Link from '@Navigation/Link';
import styles from './Footer.module.css';

import type { FC, HTMLAttributes } from 'react';

const Footer: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const { className, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <footer className={cn(styles.wrapper, className)} {...restProps}>
      <div className={styles.container}>
        <div className={styles.text}>
          <div className={styles.links}>
            <Link to='/site/delivery' underlined view='primary' target='_blank'>
              {t('footer.delivery-terms')}
            </Link>
            <Link
              to='/site/delivery#pravila-priema-mebeli'
              underlined
              view='primary'
              target='_blank'
            >
              {t('footer.rules-for-accepting-furniture')}
            </Link>
          </div>
          <div className={styles.title}>{t('footer.title')}</div>
          <div className={styles.description}>{t('footer.description')}</div>
          <Link className={styles.link} to='/site/delivery' view='primary' underlined>
            {t('more')}
          </Link>
        </div>
        <img className={styles.image} src={images.delivery} />
      </div>
    </footer>
  );
};

export default Footer;
