import { createDerived, createStore } from '@kundinos/nanostores';

import type { ModalsStoreValue } from './typings';

export const modalsStore = createStore<ModalsStoreValue>(() => {
  modalsStore.set({ timeout: 400, stack: [] });
});

export const visibleModalsStore = createDerived(modalsStore, ({ stack }) => {
  return stack.filter((modal) => modal.visible);
});

export const currentModalStore = createDerived(visibleModalsStore, (visibleModals) => {
  return visibleModals[visibleModals.length - 1];
});
