import { memo } from 'react';

import FooterOfferDoc from '../FooterOfferDoc';
import styles from './Recommendation.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { RecommendationTechnologiesData } from '@Types/Layout';

export interface RecommendationProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  data: RecommendationTechnologiesData;
}

const Recommendation: FC<RecommendationProps> = (props) => {
  const { data, ...restProps } = props;
  const { address, recommendation } = data;

  return (
    <div {...restProps}>
      <div className={styles.block}>{address.text}</div>
      <div className={styles.block}>
        {recommendation.text}
        {` `}
        <FooterOfferDoc document={recommendation.url} className={styles.offerDoc} />
      </div>
    </div>
  );
};

export default memo(Recommendation);
