import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

interface Params {
  pathname: string;
  lang: string;
  country: string;
}

async function getPageTranslation(params: Params) {
  const url = '/localizer/get-page';
  const res = await Api.queryProxi<ApiResponse<any>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getPageTranslation;
