import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import { useRequest } from '@Contexts/Request';
import { isBrowser } from '@Utils/checkRuntime';

import type { RegionQuestionData } from '@Types/Region';

function useRegionQuestion() {
  const { queryClient, device } = useRequest();
  const keys = ['region-question'];

  const result = useQuery({
    queryKey: keys,
    queryFn: () => {
      if (device.bot) return Promise.resolve(null);

      const cache = queryClient.getQueryData<RegionQuestionData>(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getRegionQuestion();
    },
    enabled: isBrowser,
  });

  return result;
}

export default useRegionQuestion;
