import FeatureFlagsContext from './FeatureFlagsContext';

import type { FC, PropsWithChildren } from 'react';
import type { FeatureFlags } from '@Domain/feature-flags';

export interface RequestProviderProps {
  value: FeatureFlags;
}

const RequestProvider: FC<PropsWithChildren<RequestProviderProps>> = (props) => {
  const { value, children } = props;

  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

export default RequestProvider;
