import { loader as pageB2bDetailLoader } from '@Pages/PageB2bHoreca/PageB2bDetail/loader';
import { loader as pageB2bLandingLoader } from '@Pages/PageB2bHoreca/PageB2bLanding/loader';

import type { CountryCode } from '@Types/Base';

export interface LoaderCountries {
  landingCountries: CountryCode[];
  detailCountries: CountryCode[];
}
export const loader = (params, countries: LoaderCountries) => {
  if (countries.landingCountries.includes(params.country)) return pageB2bLandingLoader(params);
  if (countries.detailCountries.includes(params.country)) return pageB2bDetailLoader(params);
};

export default loader;
