import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './IconMir.module.css';
import icon from './mir.svg';

import type { FC, HTMLAttributes } from 'react';

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const IconMir: FC<IconProps> = ({ className }) => {
  return (
    <Img
      src={icon}
      className={cn(styles.icon, className)}
      alt='Принимаем оплату банковскими картами МИР'
    />
  );
};

export default memo(IconMir);
