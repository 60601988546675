import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CourierPositionDTO, CourierPositionParams, CourierPositionResponse } from './typings';

async function getCourierPosition(params: CourierPositionParams): Promise<CourierPositionDTO> {
  const searchParams = new URLSearchParams({
    courier_id: params.courierId.toString(),
    delivery_task_id: params.deliveryCode.toString(),
    delivery_task_date: params.deliveryDate.toString(),
  });
  const searchString = searchParams.toString() ? `?${searchParams.toString()}` : '';

  const url = `/delivery/delivery_tracking/get_courier_position${searchString}`;
  const result = await Api.queryProxi<CourierPositionResponse>(url);

  if (!result.result) throw new BackendError(url, result);

  return result.data;
}

export default getCourierPosition;
