import { memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import { useDeps } from '@Contexts/DI';
import styles from './SecondLevelItem.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, MouseEvent, HTMLAttributes } from 'react';

export interface MainNavProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  menuItem: MainMenuItem;
  onClick?: (e: MouseEvent) => void;
}

const SecondLevelItem: FC<MainNavProps> = (props) => {
  const { menuItem, className, onClick, ...restProps } = props;
  const { analytics } = useDeps();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (onClick) onClick(e);

      analytics.dispatchEvent('header.clickSpecialMenu', { title: menuItem.name });
    },
    [analytics, menuItem.name, onClick],
  );

  return (
    <div
      {...restProps}
      className={cn(
        styles.item,
        {
          [styles.promo]: menuItem.parameters?.promo,
          [styles.fatty]: menuItem.parameters?.fatty,
          [styles.viewHybrid]: menuItem.parameters?.view === 'hybrid',
          [styles.viewImage]: menuItem.parameters?.view === 'image',
        },
        className,
      )}
    >
      <Link
        className={cn(styles.title, menuItem.url && 'MenuLink')}
        to={menuItem.url}
        ssr={menuItem.render}
        onClick={handleClick}
        target={menuItem.isNewTab ? '_blank' : '_self'}
      >
        {!!menuItem.icon && (
          <img
            src={menuItem.icon.url}
            className={styles.icon}
            style={{
              width: `${menuItem.parameters?.iconSize?.width}px`,
              height: `${menuItem.parameters?.iconSize?.height}px`,
            }}
          />
        )}

        <span className={styles.name}>{menuItem.name}</span>
      </Link>
    </div>
  );
};

export default memo(SecondLevelItem);
