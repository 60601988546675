import { useRef, memo, useCallback, useEffect, useState } from 'react';
import { cn, Input } from '@divlab/divanui';
import { IconSearch, IconReset } from '@divlab/divanui/icons';
import { useDebouncedCallback } from 'use-debounce';

import * as SearchStore from '@Stores/Search';
import useTranslation from '@Queries/useTranslation';
import { useDeps } from '@Contexts/DI';
import Button from '@UI/Button/Button';
import useMedias from '@Hooks/useMedias';
import styles from './InputSearch.module.css';

import type { InputProps } from '@divlab/divanui';
import type { FC, ChangeEvent } from 'react';

export interface InputSearchData extends InputProps {
  className?: string;
  active: boolean;
}

const InputSearch: FC<InputSearchData> = (props) => {
  const { active, ...restProps } = props;
  const { isMobileM } = useMedias();
  const { logger } = useDeps();
  const { result, caretPosition } = SearchStore.useSearch();
  const inputRef = useRef<HTMLInputElement>();
  const abortController = useRef<AbortController>(null);
  const { t } = useTranslation();
  const [hasValue, setHasValue] = useState(false);

  const debouceChangeValue = useDebouncedCallback(async (term: string) => {
    try {
      // Отменяем предыдущий запрос
      if (abortController.current) {
        abortController.current.abort('abort previous request');
      }

      abortController.current = new AbortController();

      await SearchStore.search({ term, opts: { signal: abortController.current.signal } });
    } catch (err) {
      // Запрос был прерван нами, ошибкой не считаем
      if (err.name === 'AbortError') return;

      logger.log(err);
    }
  }, 500);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const term = e.target.value;
      setHasValue(!!term.length);

      if (term.length >= result.minLengthRequest) {
        debouceChangeValue(term);
        SearchStore.setDisabled(false);
      } else {
        debouceChangeValue.cancel();
        SearchStore.setDisabled(true);
      }
    },
    [debouceChangeValue, result.minLengthRequest],
  );

  const handleReset = useCallback(() => {
    debouceChangeValue('');
    setHasValue(false);
  }, [debouceChangeValue]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (!isMobileM) {
      inputRef.current.setSelectionRange(caretPosition, caretPosition);
    }
  }, [caretPosition, isMobileM]);

  return (
    <Input
      {...restProps}
      itemProp='query-input'
      className={cn(styles.input, {
        [styles.active]: active,
        [styles.hasValue]: hasValue,
        [styles.overrider]: true,
      })}
      type='input'
      name='search'
      defaultValue={result.request}
      placeholder={t('ui.enter-request')}
      autoComplete='off'
      ref={inputRef}
      clear={false}
      after={
        <div className={styles.inputActions}>
          <div className={styles.closed}>
            <div className={styles.icon}>
              <IconSearch className={styles.searchIcon} />
            </div>
          </div>
          <div className={styles.opened}>
            <div className={styles.icon}>
              <IconReset className={styles.closeIcon} onClick={handleReset} />
            </div>

            <Button
              className={cn(styles.searchButton, { [styles.disabled]: result.disabled })}
              view='rounded'
              type='submit'
              disabled={result.disabled}
            >
              {t('ui.find')}
            </Button>
          </div>
        </div>
      }
      onChange={handleChange}
    />
  );
};

export default memo(InputSearch);
