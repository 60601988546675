import { prefetchPage, prefetchTranslation } from '@Navigation/prefetchers';
import { prefetchInfiniteCategory } from '@Queries/useInfiniteCategory';

import type { LoaderFn } from '@Types/Router';

type LoaderFnWithSlug = (slug: string) => LoaderFn;

export const loader: LoaderFnWithSlug = (slug) => (query) => async (args) => {
  const { queryClient } = query;
  const { params, request } = args;
  const { search } = new URL(request.url);
  const { page = '' } = params;
  const pageNumber = parseInt(page.replace(/\D/g, ''), 10) || 1;

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchInfiniteCategory({ slug, pageNumber, search }, queryClient),
  ]);
};
