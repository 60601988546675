import * as Api from '@Api/index';

import type { ApiResponse } from './types/Api';
import type { ValidationMarkers } from '@Pages/PageB2bRegistratsiyu/elems/DesignersForm/elems/Forms/RewardDataForm';
interface Params {
  promoCode: string;
}

interface Result {
  validation: ValidationMarkers;
}

async function procomodeValidation(body: Params) {
  const url = '/backend/designer/promocode-validation';
  const response = await Api.query<ApiResponse<Result>>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return response;
}

export default procomodeValidation;
