import { memo, useMemo } from 'react';

import Section from '../Section';
import styles from './Rows.module.css';

import type { FC, MouseEvent } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface RowsProps {
  items: MainMenuItem[];
  onClickLink?: (e: MouseEvent, title: string) => void;
}

const Rows: FC<RowsProps> = (props) => {
  const { items, onClickLink } = props;

  const rows = useMemo(() => {
    function convertTo2DArray(array: MainMenuItem[], columns: number) {
      const result: MainMenuItem[][] = [];

      for (let i = 0; i < array.length; i += columns) {
        result.push(array.slice(i, i + columns));
      }

      return result;
    }

    return convertTo2DArray(items, 2);
  }, [items]);

  return (
    <>
      {rows.map((row, index) => {
        let isMainColumnId = row[0].id;

        row.forEach((item) => {
          const prevMainColumn = row.find((prevMain) => prevMain.id === isMainColumnId);

          if (item.children?.length > prevMainColumn.children?.length) {
            isMainColumnId = item.id;
          }
        });

        return (
          <div key={index} className={styles.grid}>
            {row.map((section) => {
              const isMain = section.id === isMainColumnId;

              return (
                <Section
                  key={section.id}
                  isMain={isMain}
                  section={section}
                  onClickLink={onClickLink}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default memo(Rows);
