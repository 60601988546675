import * as patterns from './patterns';

import type { CountryCode } from '@Types/Base';

interface Params {
  country: CountryCode;
  lang: string;
}

const errorMessages = {
  ru: {
    requiredNumber: 'Укажите номер телефона',
    wrongNumber: 'Номер телефона указан неверно',
  },
  uz: {
    requiredNumber: "Telefon raqamini ko'rsating",
    wrongNumber: "Telefon raqami noto'g'ri ko'rsatilgan",
  },
  kk: {
    requiredNumber: 'Телефон нөмірін көрсетіңіз',
    wrongNumber: 'Телефон нөмірі дұрыс көрсетілмеген',
  },
};

export default ({ country, lang }: Params) => {
  return {
    description: 'Телефон',
    type: 'string',
    pattern: patterns.phone({ country }),
    minLength: 18,
    errorMessage: {
      minLength: errorMessages[lang].requiredNumber,
      required: errorMessages[lang].requiredNumber,
      pattern: errorMessages[lang].wrongNumber,
    },
  };
};
