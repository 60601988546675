import { memo, lazy } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import * as Meta from '@Queries/Meta';
import * as ModalWindows from '@Stores/ModalWindows';
import Suspense from '@Components/Suspense';
import FeedbackPhone from '@Components/FeedbackPhone';
import Logotype from '@Components/Logotype';
import Link from '@Navigation/Link';
import UILink from '@UI/Link';
import { useRequest } from '@Contexts/Request';
import Back from './Back';
import styles from './HeaderDesktop.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

const RegionQuestionPopup = lazy(() => import('@Layouts/elems/RegionQuestionPopup'));

const HeaderDesktop: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const { className, ...restProps } = props;
  const { country } = useRequest();
  const { isMobileM, isOnlyDesktop } = useMedias();
  const meta = Meta.useMeta();
  const countryWithSingleRegion = country === 'UZB';
  const regionQuestion = Meta.useRegionQuestion();

  const handleClickLocation = () => {
    if (!countryWithSingleRegion) {
      ModalWindows.open('RegionSelector');
    }
  };

  const handleClickPhone = (event: MouseEvent) => {
    event.preventDefault();
    ModalWindows.open('Feedback');
  };

  return (
    <header className={cn(styles.header, className)} {...restProps}>
      <div className={styles.container}>
        <div className={styles.headerIn}>
          <Back
            data-testid='go-back-to-main'
            className={cn(styles.side, { [styles.left]: true })}
          />
          <div data-testid='logo' className={styles.wrapperLogotype}>
            <Link to='/'>
              <Logotype country={country} />
            </Link>
          </div>

          <div className={cn(styles.side, { [styles.right]: true })}>
            <div className={styles.regionContainer}>
              <UILink
                className={cn(styles.location, { [styles.disabled]: countryWithSingleRegion })}
                underlined={!countryWithSingleRegion}
                view='primary'
                data-testid='region-name-link'
                onClick={handleClickLocation}
              >
                {meta.data.region.name}
              </UILink>

              {isOnlyDesktop && regionQuestion.data && (
                <Suspense fallback={null}>
                  <RegionQuestionPopup data={regionQuestion.data} />
                </Suspense>
              )}
            </div>

            <FeedbackPhone size={isMobileM ? 's' : 'm'} view='primary' onClick={handleClickPhone} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default memo(HeaderDesktop);
