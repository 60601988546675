import { memo } from 'react';

import useKeyboardEvents from '@Hooks/useKeyboardEvents';
import * as HeaderStore from '@Stores/Header';
import Offer from './elems/Offer/Offer';
import Result from './elems/Result/Result';
import SearchDropDownWrapper from './elems/SearchDropDownWrapper';
import styles from './SearchDropDown.module.css';

import type { HTMLAttributes, FC } from 'react';
export interface SearchDropDownProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  opened?: boolean;
}

const SearchDropDown: FC<SearchDropDownProps> = (props) => {
  const { opened, ...restProps } = props;

  // Закрываем сайдбар по нажатию на Escape
  useKeyboardEvents({
    onEscape: HeaderStore.closeSearch,
  });

  return (
    <SearchDropDownWrapper opened={opened}>
      <div {...restProps} className={styles.searchDropDown}>
        <div className={styles.container}>
          <Offer className={styles.searchHistory} />
          <Result className={styles.searchContent} />
        </div>
      </div>
    </SearchDropDownWrapper>
  );
};

export default memo(SearchDropDown);
