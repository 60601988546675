import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';
import transformCart from './helpers/transformCart';
import useCartKeys from './useCartKeys';

import type { CartData } from '@Types/Cart';

export const useRemoveListFromCart = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const { analytics } = useDeps();

  return useMutation<CartData, Error, string[]>({
    mutationFn: async (positionsIds) => {
      const formatedData = positionsIds.map((positionId) => ({ cartPositionId: positionId }));
      const res = await ApiCart.remove(formatedData);

      return transformCart({ cart: res });
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
      queryClient.invalidateQueries({ queryKey: ['cartShortInfo'], refetchType: 'all' });
      analytics.dispatchEvent('cart.update', { cart, removeLabel: 'Удаление выбранного' });
    },
  });
};

export default useRemoveListFromCart;
