import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { FiltersData, FiltersObjectData } from '@Types/Filters';

export interface Params {
  filters: FiltersObjectData;
  search: URLSearchParams;
}

export interface Response {
  productsTotalCount: number;
  filters: FiltersData;
}

async function getSearchProductsCount(params: Params) {
  const { filters, search } = params;
  const searchParams = new URLSearchParams(search);
  const term = searchParams.get('ProductSearch[name]');
  const categories = searchParams.get('categories[]');
  const possibleParamsKeys: string[] = ['page', 'perPage', 'tab', 'no_cache'];
  const mainSearchParams = new URLSearchParams();
  mainSearchParams.set('term', term);
  if (categories) mainSearchParams.set('categories[]', categories);

  possibleParamsKeys.forEach((key) => {
    const parameter = searchParams.get(key);
    if (parameter) {
      mainSearchParams.set(key, parameter);
    }
  });

  const url = `/backend/search/get-products-count?${mainSearchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(filters),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getSearchProductsCount;
