import generateHash from './generateHash';

export interface ProductHashParams {
  shopProductId: string | number;
  isModifiedParameters?: boolean;
  relatedProducts?: boolean;
  parameterValues?: any[];
  shopProducts?: Array<string | number>;
  fabricValues?: any[];
}

const generateProductHash = (product: ProductHashParams) => {
  const { shopProductId, isModifiedParameters, parameterValues, shopProducts, fabricValues } =
    product;
  const hash = generateHash({
    shopProductId,
    parameterValues: isModifiedParameters ? parameterValues : [],
    quantity: null,
    shopProducts: shopProducts?.length > 0 ? shopProducts : [],
    fabricValues: fabricValues || null,
  });
  return hash;
};

export default generateProductHash;
