import { update } from '@kundinos/nanostores';

import { modalsStore } from './stores';

import type { ModalId, Open } from './typings';

export interface Params {
  /**
   * New window modal should hide the previous one? True by default
   */
  needToReplace?: boolean;

  /**
   * New modal should not open if any other modals open already
   */
  lowPriority?: boolean;
}

/**
 * Opens new modal window by id with specified data
 * @param id - identifier of modal window, that need open
 * @param data - the data, that need for specified modal window
 * @param params - additional behavior for opening
 */
const open: Open = function (id: ModalId, data?: unknown, params?: Params): void {
  const { needToReplace = true, lowPriority = false } = params || {};

  update(modalsStore, (prevValue) => {
    let newStack = [...prevValue.stack];
    const selectedModalIndex = prevValue.stack.findIndex((modal) => modal.id === id);
    const selectedModal = prevValue.stack[selectedModalIndex];

    if (lowPriority && (newStack || []).filter((modal) => modal.visible).length > 0) {
      return prevValue;
    }

    // Если модальное окно уже есть в стэке, то его нужно вырезать и гарантированно перенести в конец стэка
    // Так как во время рендера на сервере стэк мог быть собран в произвольном порядке, но в момент открытия
    // порядок в стэке очень важен
    if (selectedModal) {
      newStack.splice(selectedModalIndex, 1);
    }

    if (needToReplace) {
      newStack = newStack.map((modal) => {
        return {
          ...modal,
          visible: false,
        };
      });
    }

    newStack.push({ id, data, visible: true, hidden: false });

    return { ...prevValue, stack: newStack };
  });
};

export default open;
