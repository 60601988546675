import { Button, Link, cn } from '@divlab/divanui';

import { socials } from './images';
import useTranslation from '@Queries/useTranslation';
import formatPhone from '@Utils/formatPhone';
import styles from './Support.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { OrderState } from '@Types/DeliveryTracking';

interface SupportProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
}

const Support: FC<SupportProps> = (props) => {
  const { className, order, ...restProps } = props;
  const { t } = useTranslation();
  const phone = order?.supportPhone || '';
  const formattedPhone = formatPhone(phone);

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <div className={styles.image} />
      <div className={styles.text}>
        <div className={styles.title}>{t('info.support.title')}</div>
        {formattedPhone && <div className={styles.value}>{formattedPhone}</div>}
      </div>

      {phone && (
        <Link className={styles.button} to={`tel:${phone}`}>
          <Button wide theme='transparent'>
            {t('call')}
          </Button>
        </Link>
      )}
      <div className={styles.alternative}>{t('info.support.alternative')}</div>
      <div className={styles.socials}>
        <Link className={styles.social} to='https://t.me/officialdivanru' target='_blank'>
          <img src={socials.telegram} />
        </Link>
        <Link className={styles.social} to='https://vk.com/ilovedivan' target='_blank'>
          <img src={socials.vk} />
        </Link>
        <Link
          className={styles.social}
          to='https://api.whatsapp.com/send?phone=79209468177'
          target='_blank'
        >
          <img src={socials.whatsapp} />
        </Link>
        <Link className={styles.social} to='https://viber.click/79209468177' target='_blank'>
          <img src={socials.viber} />
        </Link>
      </div>
    </div>
  );
};

export default Support;
