import { EventListener } from '@Events/EventListener';
import * as ApiCompare from '@Api/Compare';
import * as Api from '@Api/index';

import type { ComparePayload } from '@Queries/Compare/useCompare';
import type { ResultData } from '@Api/Compare/addProduct';
import type { QueryClient } from '@tanstack/react-query';
import type { CompareData } from '@Types/Compare';
import type { AnalyticsEventDispatcher } from '@Infrastructure/details/analytics/AnalyticsEventDispatcher';

export type CompareEventData = {
  (eventName: 'compare.add', data: AddEventData): void;
  (eventName: 'compare.remove', data: RemoveEventData): void;
};
export type RemoveEventData = {
  productId: number;
  payload?: ComparePayload;
  onSuccess?: (data: ResultData, variables?: unknown, context?: unknown) => void;
};

export type AddEventData = {
  productId: number | number[];
  payload?: ComparePayload;
  onSuccess?: (data: ResultData, variables?: unknown, context?: unknown) => void;
};

export class CompareListener extends EventListener {
  private queryClient: QueryClient;

  private analytics: AnalyticsEventDispatcher;

  constructor(deps: { queryClient: QueryClient; analytics: AnalyticsEventDispatcher }) {
    super();
    this.queryClient = deps.queryClient;
    this.analytics = deps.analytics;
    this.on('compare.add', this.add.bind(this));
    this.on('compare.remove', this.remove.bind(this));
  }

  add(eventData: AddEventData) {
    const analyticsData = eventData?.payload?.analytics;
    const hash = eventData?.payload?.hash;
    const parameterValues = eventData?.payload?.parameterValues;
    const { region, language } = Api.getRequest();

    this.queryClient
      .getMutationCache()
      .build(this.queryClient, {
        mutationFn: () => ApiCompare.addProduct(eventData.productId, hash, parameterValues),
        onSuccess: (data) => {
          // По успеху обновляем данные
          this.queryClient.setQueryData(['compare', region, language], data);
          this.queryClient.invalidateQueries({ queryKey: ['infiniteFavorites'] });

          if (analyticsData) {
            this.analytics.dispatchEvent('favorite.add', analyticsData);
          }

          if (eventData.onSuccess) eventData.onSuccess(data);
        },
      })
      .execute(null);
  }

  remove(eventData: RemoveEventData) {
    const hash = eventData?.payload?.hash;
    const analyticsData = eventData?.payload?.analytics;
    const { region, language } = Api.getRequest();
    this.queryClient
      .getMutationCache()
      .build(this.queryClient, {
        mutationFn: () => ApiCompare.deleteProduct(eventData.productId, hash),
        onSuccess: (data) => {
          this.queryClient.setQueryData(['compare', region, language], data);
          this.queryClient.invalidateQueries({ queryKey: ['infiniteFavorites'] });
          // Удаляем товари из списка избранного если оно совпадает по id с текущим
          // что бы не делать повторный запрос за актуальными данными
          const currentCompareIds = data?.products?.map((product) => product.id);
          const previousCompareProductList = this.queryClient.getQueryData<CompareData>([
            'compare-products',
            region,
            language,
          ]);
          const products = previousCompareProductList?.products || [];
          const newCompereProductList = products?.filter((product) =>
            currentCompareIds?.includes(product.id),
          );
          this.queryClient.setQueryData(['compare-products', region, language], {
            products: [...newCompereProductList],
          });

          if (analyticsData) {
            this.analytics.dispatchEvent('favorite.remove', analyticsData);
          }

          if (eventData.onSuccess) eventData.onSuccess(data);
        },
      })
      .execute(null);
  }
}
