import CommonError from './common-error';

export class BackendError extends CommonError {
  name = 'BackendError';

  message = '';

  url = '';

  response = {};

  constructor(url: string, response: any) {
    const message = 'Error on backend';

    super(`${message}, url: ${url}, ${JSON.stringify(response)}`);
    this.message = message;
    this.url = url;
    this.response = response;
  }
}
