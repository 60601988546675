import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { Promotion, CategoryBannerData } from '@Promo/typings';

interface Params {
  country: string;
  lang: string;
  region: string;
  slug: string;
  currentDate?: number;
}

export async function getByCategory(params: Params): Promise<Promotion<CategoryBannerData>> {
  const { mainRegion } = Api.getRequest();

  const searchParams = new URLSearchParams({
    country: String(params.country).toLowerCase(),
    lang: String(params.lang).toLowerCase(),
    region: String(params.region) === mainRegion ? '' : String(params.region),
    slug: String(params.slug),
    currentDate: String(params.currentDate || Date.now()),
  });
  const url = `/promo/get-by-category?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<Promotion<CategoryBannerData>>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}
