import {
  prefetchInfiniteWikiArticles,
  prefetchPage,
  prefetchTranslation,
} from '@Navigation/prefetchers';
import ApiSite from '@Api/Site';

import type { PageWikiRubricData } from '@Pages/PageWikiRubric/typings';
import type { LoaderFn } from '@Types/Router';

export const loader: LoaderFn = (query) => async (args) => {
  const { queryClient } = query;
  const { request } = args;
  const { pathname, search } = new URL(request.url);

  // Запрашиваем данные страницы отдельно от остальных, т.к. они необходимы для запроса статей
  const { fetchUrl } = await ApiSite.fetchPage<PageWikiRubricData>({ url: `${pathname}${search}` });
  const params = new URLSearchParams(fetchUrl.split('?')[1]);
  const categoryId = params.get('categoryId');

  return Promise.all([
    prefetchPage(request.url, queryClient),
    prefetchTranslation(request.url, queryClient),
    prefetchInfiniteWikiArticles({ categoryId }, queryClient),
  ]);
};
