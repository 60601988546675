import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './Logo.module.css';

import type { FC, HTMLAttributes } from 'react';

interface LogoProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  src: string;
  size?: 'S' | 'M' | 'L';
}

const Logo: FC<LogoProps> = (props) => {
  const { className, size = 'S', src, ...restProps } = props;

  return (
    <Img
      className={cn(styles.logo, className, {
        [styles.sizeS]: size === 'S',
        [styles.sizeM]: size === 'M',
        [styles.sizeL]: size === 'L',
      })}
      cnImage={styles.logoImage}
      src={src}
      {...restProps}
    />
  );
};

export default Logo;
