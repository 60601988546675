import { useMatch } from 'react-router-dom';

import type { PathPattern } from 'react-router-dom';

function useRouteMatch(pattern: PathPattern) {
  const matchWithoutRegion = useMatch(pattern);
  const matchWithRegion = useMatch({ ...pattern, path: `/:region${pattern.path}` });

  return [matchWithoutRegion, matchWithRegion].some(Boolean);
}

export default useRouteMatch;
