import { memo } from 'react';

import { useRequest } from '@Contexts/Request';

import type { FC, ReactElement } from 'react';
import type { HttpStatusCode } from '@Contexts/Request/RequestContext';

export interface HttpStatusProps {
  code: HttpStatusCode;
  children: ReactElement | ReactElement[];
}

const HttpStatus: FC<HttpStatusProps> = (props) => {
  const { code, children } = props;
  const request = useRequest();

  if (code !== request.status.code) {
    request.status.code = code;
  }

  return <>{children}</>;
};

export default memo(HttpStatus);
