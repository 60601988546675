import { createStore, getValue, keepActive, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';
import equal from 'fast-deep-equal';

import useMedias from '@Hooks/useMedias';
import useNavigation from '@Navigation/useNavigation';

import type { MainMenuItem } from '@Types/Layout';

export interface HeaderStoreValue {
  inited: boolean;
  items: MainMenuItem[];

  /** Время анимации сайдбара  */
  sidebarAnimationTime?: number;

  /** Шапка зафиксирована? */
  fixed?: boolean;

  /** Шапка закреплена? (для мобильных разрешений) */
  pinned?: boolean;

  /** Сайдбар открыт? */
  isOpenedSidebar?: boolean;

  /** Поиск открыт? */
  isOpenedSearch?: boolean;

  /** Меню открыто? */
  isOpenedMenu?: boolean;

  pathname: string;

  isPreselected?: boolean;
}

export interface SelectedItemsStoreValue {
  firstLevel?: MainMenuItem;
  initialFirstLevel?: MainMenuItem;
  secondLevel?: MainMenuItem;
  isOpenNewTab?: boolean;
}

export interface SizesStoreValue {
  toplineHeight?: number;
  contentHeight?: number;
  totalHeight?: number;
}

export const headerStore = createStore<HeaderStoreValue>(() => {
  update(headerStore, (menu) => ({
    inited: false,
    items: menu?.items || [],
    fixed: menu?.fixed || false,
    pinned: menu?.pinned || false,
    sidebarAnimationTime: 400,
    isOpenedSearch: menu?.isOpenedSearch || false,
    pathname: '',
  }));
});

export const selectedItemsStore = createStore<SelectedItemsStoreValue>(() => {
  selectedItemsStore.set({ firstLevel: null });
});

export const sizesStore = createStore<SizesStoreValue>(() => {
  sizesStore.set({ toplineHeight: 0, contentHeight: 0 });
});

export const setSelectedLevels = (variants: Partial<SelectedItemsStoreValue>) => {
  update(selectedItemsStore, (selectedItems) => ({ ...selectedItems, ...variants }));
};

// данная функция срабатывает при обновлении страницы и при клике на пункт меню второго или третьего уровней.
// После каждого перехода по меню или обновлении страницы значение сбрасываем по дефолту на пункт меню товаров.
export const setDefaultFirstLevel = (items: MainMenuItem[]): void => {
  setSelectedLevels({
    firstLevel: items[0],
    initialFirstLevel: items[0],
  });
};

export const init = (initialValue: Partial<HeaderStoreValue>): void => {
  const header = getValue(headerStore);

  if (!header.inited || header.pathname !== initialValue.pathname) {
    setDefaultFirstLevel(initialValue.items);
  }
  if (initialValue && !equal(initialValue, header)) {
    update(headerStore, (menu) => ({
      ...menu,
      ...initialValue,
      inited: true,
    }));
  }
};

export const setSizes = (value: SizesStoreValue) => {
  update(sizesStore, (sizes) => ({ ...sizes, ...value }));
};

export const openSideBar = (): void => {
  update(headerStore, (header) => ({ ...header, isOpenedSidebar: true }));
  setSelectedLevels({ secondLevel: null });
};

export const closeSideBar = (): void => {
  update(headerStore, (header) => ({ ...header, isOpenedSidebar: false, isPreselected: false }));
};

export const preselectSideBarItem = (): void => {
  update(headerStore, (header) => ({ ...header, isPreselected: true }));
};

export const openSearch = (): void => {
  update(headerStore, (header) => ({ ...header, isOpenedSearch: true, isOpenedMenu: false }));
};

export const closeSearch = (): void => {
  update(headerStore, (header) => ({ ...header, isOpenedSearch: false }));
};

export const openMenu = (): void => {
  const store = getValue(selectedItemsStore);
  update(headerStore, (header) => ({ ...header, isOpenedMenu: true, isOpenedSearch: false }));
  setSelectedLevels({ secondLevel: store.firstLevel.children[0].children[0] });
};

export const closeMenu = (): void => {
  update(headerStore, (header) => ({ ...header, isOpenedMenu: false }));
};

export const fix = (flag?: boolean): void => {
  update(headerStore, (header) => ({ ...header, fixed: flag === undefined ? true : flag }));
};

export const unfix = (): void => {
  update(headerStore, (header) => ({ ...header, fixed: false }));
};

export const pin = (flag?: boolean): void => {
  update(headerStore, (header) => ({ ...header, pinned: flag === undefined ? true : flag }));
};

export const unpin = (): void => {
  update(headerStore, (header) => ({ ...header, pinned: false }));
};

export const clear = (): void => {
  headerStore.set({
    inited: false,
    items: [],
    fixed: false,
    sidebarAnimationTime: 400,
    isOpenedSearch: false,
    pathname: '',
  });
};

export const useMenu = () => {
  return useStore(headerStore);
};

export const useSelectedLevels = () => {
  const { isDesktop } = useMedias();
  const { pathname } = useNavigation();
  const store = useStore(selectedItemsStore);

  if (!isDesktop && pathname.includes('/order/check')) {
    return {
      ...store,
      secondLevel: null,
      isOpenNewTab: true,
    };
  }

  /** check if device is mobile and we are in mobile menu about section to hide repeated titles */
  if (isDesktop && (store.firstLevel?.url || '').includes('/site/about')) {
    store.firstLevel.children.forEach((firstLevelchild) => {
      firstLevelchild.children.forEach((secondLevelChild) => {
        secondLevelChild.name = '';
      });
    });
  }

  return store;
};

export const useSizes = () => {
  const sizes = useStore(sizesStore);
  const { isDesktop } = useMedias();
  const { fixed, pinned } = useMenu();
  const mobileHeight = pinned ? sizes.contentHeight + sizes.toplineHeight : 0;
  const desktopHeight = fixed ? sizes.contentHeight : sizes.contentHeight + sizes.toplineHeight;

  return {
    ...sizes,
    totalHeight: isDesktop ? mobileHeight : desktopHeight,
  };
};

keepActive(selectedItemsStore);
