import type { FC, SVGAttributes } from 'react';

export interface LineProps extends SVGAttributes<SVGElement> {
  className?: string;
}

const Line: FC<LineProps> = (props) => {
  const { stroke = 'white', className } = props;
  return (
    <svg
      className={className}
      width='18'
      height='2'
      viewBox='0 0 18 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M18 1H0' stroke={stroke} strokeWidth='1.5' strokeMiterlimit='10' />
    </svg>
  );
};

export default Line;
