import { cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import styles from './Title.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { DeliveryStatus } from '@Types/DeliveryTracking';

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  status: DeliveryStatus;
}

const Title: FC<TitleProps> = (props) => {
  const { className, status, ...restProps } = props;
  const { t } = useTranslation();

  const titles: Record<DeliveryStatus, string> = {
    pending: t('titles.pending'),
    delivering: t('titles.delivering'),
    canceled: t('titles.canceled'),
    completed: t('titles.completed'),
  };

  return (
    <div className={cn(styles.title, className)} {...restProps}>
      {titles[status]}
    </div>
  );
};

export default Title;
