import { useQuery } from '@tanstack/react-query';

import { useDeps } from '@Contexts/DI';
import { useProfile } from '@Queries/Profile';

import type { UseQueryResult } from '@tanstack/react-query';
import type { VisitData } from '@Types/Visits';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */

export function useVisits(): UseQueryResult<VisitData[]> {
  const { cabinetApi } = useDeps();
  const { data: profile } = useProfile();
  const phone = profile.phone;
  const keys = ['page', 'visits', phone];

  return useQuery({
    queryKey: keys,
    queryFn: () => cabinetApi.fetchCRMVisits(phone),
    placeholderData: (previousData) => previousData,
  });
}
