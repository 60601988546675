import * as Api from '@Api/index';

import type { ApiResponse } from './types/Api';
interface Params {
  phone: string;
}

export type PLStatus = 'b2c' | 'designer' | 'otherPartnership' | 'unknown';

interface Result {
  isRegistered: boolean;
  clientType: PLStatus;
}

async function customerCheck(body: Params) {
  const url = `/cabinet/check-customer?country=RUS`;

  const res = await Api.query<ApiResponse<Result>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify(body),
  });

  return res;
}

export default customerCheck;
