import DIContext from './DIContext';

import type { FC, PropsWithChildren } from 'react';
import type { Deps } from '@Contexts/DI';

export interface DIProviderProps {
  value: Deps;
}

const DIProvider: FC<PropsWithChildren<DIProviderProps>> = (props) => {
  const { value, children } = props;

  return <DIContext.Provider value={value}>{children}</DIContext.Provider>;
};

export default DIProvider;
