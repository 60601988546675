import * as Api from '@Api/index';

import type { Quizz } from '@Domain/quizz';

interface QuizzSubmitParams {
  pathname: string;
  body: Quizz;
}

interface Response {
  sampleId: number;
}

async function sendQuizzLead({ pathname, body }: QuizzSubmitParams) {
  const url = `/backend${pathname}`;
  const response = await Api.query<Response>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return response;
}

export default sendQuizzLead;
