import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { MenuPromotionsData } from '@Promo/typings';

interface Params {
  country: string;
  lang: string;
  region: string;
  currentDate: number;
}

export async function getPromotionsForMenu(params: Params): Promise<MenuPromotionsData> {
  const { mainRegion } = Api.getRequest();

  const searchParams = new URLSearchParams({
    country: String(params.country).toLowerCase(),
    lang: String(params.lang).toLowerCase(),
    region: String(params.region) === mainRegion ? '' : String(params.region),
    currentDate: String(params.currentDate || Date.now()),
  });

  const url = `/promo/get-promotions-for-menu?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<MenuPromotionsData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}
