import { memo } from 'react';
import { cn } from '@divlab/divanui';
import { IconCircleArrowLeft } from '@divlab/divanui/icons';

import useNavigation from '@Navigation/useNavigation';
import styles from './Back.module.css';

import type { FC, SVGAttributes } from 'react';

export interface BackProps extends SVGAttributes<SVGSVGElement> {
  className?: string;
}

const Back: FC<BackProps> = (props) => {
  const { className, ...restProps } = props;
  const { goBack } = useNavigation();

  return (
    <IconCircleArrowLeft {...restProps} className={cn(styles.back, className)} onClick={goBack} />
  );
};

export default memo(Back);
