import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { SendFeedbackBody } from './typings';

async function sendFeedback(body: SendFeedbackBody) {
  const url = '/marketing/form/v1/add-delivery-feedback';
  const res = await Api.queryProxi<Response>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res;
}

export default sendFeedback;
