import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import divanRu from './images/DivanRu.svg';
import divanBy from './images/DivanBy.svg';
import divanUZ from './images/DivanUz.svg';
import divanKZ from './images/DivanKz.svg';
import styles from './Logotype.module.css';

import type { CountryBasedData, CountryCode } from '@Types/Base';
import type { HTMLAttributes, FC } from 'react';

export interface LogotypeDivanProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  country: CountryCode;
}

const Logotype: FC<LogotypeDivanProps> = (props) => {
  const { className, country, ...restProps } = props;

  const logos: CountryBasedData<string> = {
    RUS: divanRu,
    BLR: divanBy,
    UZB: divanUZ,
    KAZ: divanKZ,
  };

  return (
    <div {...restProps} className={cn(styles.logotype, className)}>
      <Img
        className={cn(styles.main, {
          [styles.mainRus]: country === 'RUS',
          [styles.mainBlr]: country === 'BLR',
          [styles.mainUzb]: country === 'UZB',
          [styles.mainKaz]: country === 'KAZ',
        })}
        src={logos[country]}
        cnImage={styles.mainImg}
        loading='eager'
      />
    </div>
  );
};

export default memo(Logotype);
