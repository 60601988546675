const errorMessages = {
  ru: {
    required: 'Укажите адрес доставки',
  },
  uz: {
    required: "Yetkazib berish manzilini ko'rsating",
  },
  kk: {
    required: 'Жеткізу мекенжайын көрсетіңіз',
  },
};

export default (lang: string) => {
  return {
    description: 'Адрес',
    type: 'string',
    errorMessage: {
      minLength: errorMessages[lang].required,
      required: errorMessages[lang].required,
    },
  };
};
